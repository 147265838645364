import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Learnstyles from './Learn.module.css';
import Header from './Header'; // Import the Header component
// Importing components for different sections
import MonosyllablePractice from './Syllable';
import Rhyme from './Rhyme';
import BuildUnit from './BuildUnit';
import BuildLine from './BuildLine';
import BuildCouplet from './BuildCouplet';
import BuildPoem from './BuildPoem';
import Theme from './Couplet2';
import Tongsheng from './Tongsheng';
import Emotion from './Emotion';
import Introduction from './Introduction';
import XiucaiIntro from './XiucaiIntro';
import Grammar from './Grammar';
import SemanticResonance from './Semantic';
import PingZe from './PingZe';
import JurenIntro from './JurenIntro';
import Juren from './Juren';
import PingZePoem from './PingZePoem';
import Gongshi from './Gongshi';
import GongshiIntro from './GongshiIntro'; // Import new page
import JinshiIntro from './JinshiIntro';

const sections = [
  'introduction',
  'monosyllables',
  'buildUnit',
  'buildLine',
  'rhyming',
  'buildCouplet',
  'tongsheng',
  'xiucaiIntro',
  'theme',
  'emotion',
  'buildPoem',
  'jurenIntro',
  'grammar',
  'semanticResonance',
  'pingze',
  'juren',
  'gongshiIntro', // Add new section
  'pingzePoem',
  'gongshi',
  'jinshiIntro',
];

const unregulatedSections = [
  'introduction',
  'monosyllables',
  'buildUnit',
  'buildLine',
  'rhyming',
  'buildCouplet',
  'buildPoem',
  'tongsheng',
  'xiucaiIntro',
  'theme',
  'emotion',
];

const regulatedSections = [
  'jurenIntro',
  'grammar',
  'semanticResonance',
  'pingze',
  'juren',
  'gongshiIntro', // Add new section
  'pingzePoem',
  'gongshi',
  'jinshiIntro',
];

const Learn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialSection = location.hash ? location.hash.substring(1) : 'introduction';
  const [activeSection, setActiveSection] = useState(initialSection);
  const [mode, setMode] = useState('unregulated');
  const [levelUpdated, setLevelUpdated] = useState(false);
  const [badgeLevel, setBadgeLevel] = useState('guest');

  const level2Ref = useRef(null);
  const level3Ref = useRef(null);
  const level5Ref = useRef(null);
  const switchRef = useRef(null);
  const sidebarRef = useRef(null);

  useEffect(() => {
    if (location.hash) {
      setActiveSection(location.hash.substring(1));
    }
  }, [location.hash]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserLevel(token);
    }
  }, []);

  // Watch for section changes and update mode accordingly
  useEffect(() => {
    if (unregulatedSections.includes(activeSection)) {
      setMode('unregulated');
    } else if (regulatedSections.includes(activeSection)) {
      setMode('regulated');
    }
  }, [activeSection]);

  const fetchUserLevel = async (token) => {
    try {
      const response = await fetch('http://127.0.0.1:8000/user/current-level', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setBadgeLevel(data.level || 'guest');
      setLevelUpdated(false);
    } catch (error) {
      console.error('Error fetching user level:', error);
    }
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
    navigate(`#${section}`);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const handleBack = () => {
    const currentIndex = sections.indexOf(activeSection);
    if (currentIndex === 0) {
      navigate('/');
    } else if (currentIndex > 0) {
      handleSectionChange(sections[currentIndex - 1]);
    }
    window.scrollTo(0, 0); // Scroll to the top after navigating back
  };

  const handleNext = () => {
    const currentIndex = sections.indexOf(activeSection);
    if (currentIndex < sections.length - 1) {
      handleSectionChange(sections[currentIndex + 1]);
    }
    window.scrollTo(0, 0); // Scroll to the top after navigating next
  };

  const handleModeChange = (e) => {
    const selectedMode = e.target.value;
    setMode(selectedMode);
    if (selectedMode === 'regulated') {
      // Scroll to the bottom and set Juren Intro as the active section
      sidebarRef.current.scrollTop = sidebarRef.current.scrollHeight; // Scroll to the bottom
      setActiveSection('jurenIntro');
      setTimeout(() => {
        level3Ref.current.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    } else {
      // Scroll to the top and set Introduction as the active section
      sidebarRef.current.scrollTop = 0; // Scroll to the top
      setActiveSection('introduction');
      window.scrollTo(0, 0);
    }
  };

  const renderSection = () => {
    switch (activeSection) {
      case 'introduction':
        return <Introduction />;
      case 'monosyllables':
        return <MonosyllablePractice />;
      case 'rhyming':
        return <Rhyme />;
      case 'tongsheng':
        return <Tongsheng onLevelChange={() => setLevelUpdated(true)} />;
      case 'buildUnit':
        return <BuildUnit />;
      case 'buildLine':
        return <BuildLine />;
      case 'buildCouplet':
        return <BuildCouplet />;
      case 'xiucaiIntro':
        return <XiucaiIntro />;
      case 'theme':
        return <Theme />;
      case 'emotion':
        return <Emotion />;
      case 'buildPoem':
        return <BuildPoem onLevelChange={() => setLevelUpdated(true)} />;
      case 'jurenIntro':
        return <JurenIntro />;
      case 'grammar':
        return <Grammar />;
      case 'semanticResonance':
        return <SemanticResonance />;
      case 'pingze':
        return <PingZe />;
      case 'juren':
        return <Juren onLevelChange={() => setLevelUpdated(true)} />;
      case 'gongshiIntro': // Render Gongshi Intro
        return <GongshiIntro />;
      case 'pingzePoem':
        return <PingZePoem />;
      case 'gongshi':
        return <Gongshi />;
      case 'jinshiIntro': // New Jinshi section rendering
        return <JinshiIntro />;
      default:
        return <p>Select a section from the menu</p>;
    }
  };


  return (
    <div className={Learnstyles.learnContainer}>
      <Header /> {/* Use Header component here */}
      <div className={Learnstyles.content}>
        <aside className={Learnstyles.sidebar} ref={sidebarRef}>
          <div className={Learnstyles.stickySwitchContainer}>
            <select
              className={`${Learnstyles.dropdown} ${Learnstyles.stickySwitch}`}
              value={mode}
              onChange={handleModeChange}
              ref={switchRef}
            >
              <option value="unregulated">Unregulated</option>
              <option value="regulated">Regulated</option>
            </select>
          </div>
          <div className={Learnstyles.levelSection} ref={level2Ref}>
            <h3>Level 1: Tongsheng</h3>
            <button
              className={activeSection === 'introduction' ? Learnstyles.activeButton : ''}
              onClick={() => handleSectionChange('introduction')}
            >
              Introduction
            </button>
            <button
              className={activeSection === 'monosyllables' ? Learnstyles.activeButton : ''}
              onClick={() => handleSectionChange('monosyllables')}
            >
              Single-Syllables
            </button>
            <button
              className={activeSection === 'buildUnit' ? Learnstyles.activeButton : ''}
              onClick={() => handleSectionChange('buildUnit')}
            >
              Build a Unit
            </button>
            <button
              className={activeSection === 'buildLine' ? Learnstyles.activeButton : ''}
              onClick={() => handleSectionChange('buildLine')}
            >
              Build a Line
            </button>
            <button
              className={activeSection === 'rhyming' ? Learnstyles.activeButton : ''}
              onClick={() => handleSectionChange('rhyming')}
            >
              End-Rhyming
            </button>
            <button
              className={activeSection === 'buildCouplet' ? Learnstyles.activeButton : ''}
              onClick={() => handleSectionChange('buildCouplet')}
            >
              Build the First Couplet
            </button>
            <button
              className={activeSection === 'tongsheng' ? Learnstyles.activeButton : ''}
              onClick={() => handleSectionChange('tongsheng')}
            >
              Tongsheng Exam
            </button>
          </div>
          <hr className={Learnstyles.divider} />
          <div className={Learnstyles.levelSection} ref={level3Ref}>
          <h3>Level 2: Xiucai</h3>
            <button
              className={activeSection === 'xiucaiIntro' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
              onClick={() => handleSectionChange('xiucaiIntro')}
              disabled={badgeLevel !== 'Tongsheng' && badgeLevel !== 'Xicuai' && badgeLevel !== 'guest'}
            >
              Xiucai Introduction
            </button>
            <button
              className={activeSection === 'theme' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
              onClick={() => handleSectionChange('theme')}
              disabled={badgeLevel !== 'Tongsheng' && badgeLevel !== 'Xicuai' && badgeLevel !== 'guest'}
            >
              Theme
            </button>
            <button
              className={activeSection === 'emotion' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
              onClick={() => handleSectionChange('emotion')}
              disabled={badgeLevel !== 'Tongsheng' && badgeLevel !== 'Xicuai' && badgeLevel !== 'guest'}
            >
              Emotion
            </button>
            <button
              className={activeSection === 'buildPoem' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
              onClick={() => handleSectionChange('buildPoem')}
              disabled={badgeLevel !== 'Tongsheng' && badgeLevel !== 'Xicuai' && badgeLevel !== 'guest'}
            >
              Build a Poem
            </button>

            <hr className={Learnstyles.divider} />
            <h3>Level 3: Juren</h3>
            <button
              className={activeSection === 'jurenIntro' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
              onClick={() => handleSectionChange('jurenIntro')}
              disabled={badgeLevel !== 'Xicuai' && badgeLevel !== 'guest'}
            >
              Juren Intro
            </button>
            <button
              className={activeSection === 'grammar' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
              onClick={() => handleSectionChange('grammar')}
              disabled={badgeLevel !== 'Xicuai' && badgeLevel !== 'guest'}
            >
              Grammar
            </button>
            <button
              className={activeSection === 'semanticResonance' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
              onClick={() => handleSectionChange('semanticResonance')}
              disabled={badgeLevel !== 'Xicuai' && badgeLevel !== 'guest'}
            >
              Semantic Resonance
            </button>
            <button
              className={activeSection === 'pingze' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
              onClick={() => handleSectionChange('pingze')}
              disabled={badgeLevel !== 'Xicuai' && badgeLevel !== 'guest'}
            >
              Ping/Ze
            </button>
            <button
              className={activeSection === 'juren' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
              onClick={() => handleSectionChange('juren')}
              disabled={badgeLevel !== 'Xicuai' && badgeLevel !== 'guest'}
            >
              Juren
            </button>

            <hr className={Learnstyles.divider} />
            <div className={Learnstyles.levelSection}></div>
              <h3>Level 4: Gongshi</h3>
              <button
              className={activeSection === 'gongshiIntro' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
              onClick={() => handleSectionChange('gongshiIntro')}
              >
                Gongshi Intro
              </button>
              <button
                className={activeSection === 'pingzePoem' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
                onClick={() => handleSectionChange('pingzePoem')}
              >
                Ping/Ze for Poem
              </button>
              <button
                className={activeSection === 'gongshi' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
                onClick={() => handleSectionChange('gongshi')}
              >
                Gongshi
              </button>
            <hr className={Learnstyles.divider} />
                <h3>Level 5: Jinshi</h3>
                <button
                  className={activeSection === 'jinshiIntro' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
                  onClick={() => handleSectionChange('jinshiIntro')}
                  disabled={badgeLevel !== 'Juren' && badgeLevel !== 'guest'}
                >
                  Jinshi Intro
                </button>
          </div>
        </aside>

        <main className={Learnstyles.mainContent}>
          {renderSection()}
        </main>
      </div>

      <div className={Learnstyles.navigationBar}>
        <button onClick={handleBack}>Back</button>
        <button onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default Learn;

