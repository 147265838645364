import React, { useState } from 'react';
import axios from 'axios';
import Assessstyles from './Assess.module.css';
import Header from './Header'; 

function Assess() {
  const [poem, setPoem] = useState('');
  const [lines, setLines] = useState({ line1: '', line2: '', line3: '', line4: '' });
  const [feedback, setFeedback] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setPoem(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const poemLines = poem.split('\n').map(line => line.trim());
      if (poemLines.length !== 4) {
        throw new Error('Please enter exactly 4 lines separated by new lines.');
      }
      const response = await axios.post('http://localhost:8000/api/assess', {
        line1: poemLines[0],
        line2: poemLines[1],
        line3: poemLines[2],
        line4: poemLines[3]
      }, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      setLines({ line1: poemLines[0], line2: poemLines[1], line3: poemLines[2], line4: poemLines[3] });
      setFeedback(response.data);
      setError(null);
      window.scrollTo(0, document.body.scrollHeight); // Scroll to the bottom where feedback is displayed
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'An unexpected error occurred.';
      setError(errorMessage);
      setFeedback(null);
    }
    setLoading(false);
  };

  const handleReset = () => {
    setPoem('');
    setLines({ line1: '', line2: '', line3: '', line4: '' });
    setFeedback(null);
    setError(null);
    setLoading(false);
  };

  return (
    <div>
      <Header />
    <div className={Assessstyles.assessContainer}>
      <h1>Jueju Poetry Assessment Tool</h1>
      <textarea
        name="poem"
        value={poem}
        onChange={handleChange}
        placeholder="Enter the poem with each line separated by a newline"
        rows="5"
        className={Assessstyles.poemInput}
      />
      <div className={Assessstyles.buttonContainer}>
        <div onClick={handleSubmit} className={`${Assessstyles.button}`} disabled={loading}>Verify</div>
        <div onClick={handleReset} className={`${Assessstyles.button} ${Assessstyles.resetButton}`}>Clear</div>
      </div>
      {loading && <div className={Assessstyles.loader}></div>}
      {error && <div className={Assessstyles.error}>{error}</div>}
      {feedback && (
        <div className={Assessstyles.feedback}>
          <h2>Feedback</h2>
          <div>
            <h3>Poem:</h3>
            <p>{lines.line1}</p>
            <p>{lines.line2}</p>
            <p>{lines.line3}</p>
            <p>{lines.line4}</p>
          </div>
          <div>
            <h3>Monosyllable_rule:</h3>
            <p>Monosyllable Rule: {feedback.monosyllable_rule ? 'Satisfied' : 'Not Satisfied'}</p>
            <details>
              <summary>Details</summary>
              {feedback.details && (
                <div>
                  {Object.keys(feedback.details).map((couplet, coupletIndex) => (
                    <div key={couplet}>
                      <h3>{`Couplet ${coupletIndex + 1}`}</h3>
                      {Object.keys(feedback.details[couplet]).map((line, lineIndex) => (
                        <div key={line}>
                          <h4>{`Line ${lineIndex + 1}:`}</h4>
                          <p>Monosyllable Words: {feedback.details[couplet][line].monosyllable_words.join(', ')}</p>
                          {feedback.details[couplet][line].multisyllable_words.length > 0 && (
                            <p>Multisyllable Words: {feedback.details[couplet][line].multisyllable_words.join(', ')}</p>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </details>
          </div>
          <div>
            <h3>Ping/Ze Classification:</h3>
            {feedback.classifications && (
              <>
                {Object.keys(feedback.classifications).map((couplet, coupletIndex) => (
                  <div key={couplet}>
                    <h4>{`Couplet ${coupletIndex + 1}`}</h4>
                    {Object.keys(feedback.classifications[couplet]).map((line, lineIndex) => (
                      <p key={line}>{`Line ${lineIndex + 1}: ${feedback.classifications[couplet][line].join(' / ')}`}</p>
                    ))}
                  </div>
                ))}
              </>
            )}
          </div>
          <div>
            <h3>Parsed Units:</h3>
            {feedback.parsed_units && (
              <>
                {Object.keys(feedback.parsed_units).map((couplet, coupletIndex) => (
                  <div key={couplet}>
                    <h4>{`Couplet ${coupletIndex + 1}`}</h4>
                    {Object.keys(feedback.parsed_units[couplet]).map((line, lineIndex) => (
                      <p key={line}>{`Line ${lineIndex + 1}: ${feedback.parsed_units[couplet][line].join(' / ')}`}</p>
                    ))}
                  </div>
                ))}
              </>
            )}
          </div>
          <div>
            <h3>Unit's Grammar Check (First Couplet Only):</h3>
            <p>{feedback.all_grammar_expected ? 'All the units grammar is as expected' : 'Grammar pattern not as expected'}</p>
            <details>
              <summary>Details</summary>
              {feedback.grammar_units_summary.map((unit, index) => (
                <p key={index}>{unit}</p>
              ))}
            </details>
          </div>
          <div>
            <h3>Parallelism Check (First Couplet Only):</h3>
            {feedback.parallelism_check.map((result, index) => (
              <p key={index}>{result}</p>
            ))}
          </div>
          <div>
            <h3>End Rhyme Check:</h3>
            <p>{feedback.internal_rhyme_message}</p>
          </div>
          {feedback.internal_rhyming_pairs && Object.keys(feedback.internal_rhyming_pairs).length > 0 && (
            <div>
              <h3>Internal Rhyme Check:</h3>
              <p>Internal rhyming words found. Try to avoid internal rhyming.</p>
              {Object.keys(feedback.internal_rhyming_pairs).map((word, index) => (
                <p key={index}>{word} rhymes with {feedback.internal_rhyming_pairs[word].join(', ')}</p>
              ))}
            </div>
          )}
          <div>
            <h3>Ping-Ze Pattern Check:</h3>
            <p>Ping/ze parallelism {feedback.ping_ze_pattern_check.pattern_satisfied ? 'satisfied' : 'not satisfied'}</p>
            {feedback.ping_ze_pattern_check && (
              <details>
                <summary>Details</summary>
                <div className={Assessstyles.patternDetails}>
                  <p>Pattern Name: {feedback.ping_ze_pattern_check.pattern_name}</p>
                </div>
                <div className={Assessstyles.patternDetails}>
                  <p>Expected pattern is:</p>
                  {Object.keys(feedback.ping_ze_pattern_check.expected_pattern).map((line, index) => (
                    <div key={line}>Line {index + 1}: {feedback.ping_ze_pattern_check.expected_pattern[line]}</div>
                  ))}
                </div>
                <div className={Assessstyles.patternDetails}>
                  <p>Following Pattern:</p>
                  {Object.keys(feedback.ping_ze_pattern_check.line_patterns).map((line, index) => (
                    <div key={line}>Line {index + 1}: {feedback.ping_ze_pattern_check.line_patterns[line]}</div>
                  ))}
                </div>
                <div className={Assessstyles.patternDetails}>
                  {Object.keys(feedback.ping_ze_pattern_check.line_checks).map((line, index) => (
                    <div key={line}>{feedback.ping_ze_pattern_check.line_checks[line] ? `Line ${index + 1} is following the pattern` : `Line ${index + 1} is not following the pattern`}</div>
                  ))}
                </div>
              </details>
            )}
          </div>
        </div>
    )}
    </div>
    </div>
  );
}

export default Assess;

