import React, { useEffect, useRef, useState } from 'react';
import PingZeexpstyles from './PingZeexp.module.css';

// Circle component for Ping and Ze representation with tooltips
const PingZeCircle = ({ type }) => {
  const tooltipText = type === 'ping' ? 'Ping (Even tone)' : 'Ze (Oblique tone)';
  return (
    <span
      className={type === 'ping' ? PingZeexpstyles.emptyCircle : PingZeexpstyles.filledCircle}
      data-tooltip={tooltipText}
    ></span>
  );
};

// Hook for adding scroll animations
const useScrollAnimation = (ref) => {
  useEffect(() => {
    const handleScroll = () => {
      const elementTop = ref.current?.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (elementTop < windowHeight) {
        ref.current.classList.add(PingZeexpstyles.inView);
      } else {
        ref.current.classList.remove(PingZeexpstyles.inView);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [ref]);
};

// GroupedExample component for displaying examples under headings
const GroupedExample = ({ ideal, acceptable, unacceptable }) => (
  <div className={PingZeexpstyles.exampleGroup}>
    <div className={PingZeexpstyles.exampleColumn}>
      <h5>Ideal</h5>
      {ideal.map(({ circles, text }, index) => (
        <div key={index} className={PingZeexpstyles.exampleRow}>
          <div className={PingZeexpstyles.circleContainer}>
            {circles.map((circleType, idx) => (
              <PingZeCircle key={idx} type={circleType} />
            ))}
          </div>
          <p>{text}</p>
        </div>
      ))}
    </div>
    <div className={PingZeexpstyles.exampleColumn}>
      <h5>Acceptable</h5>
      {acceptable.map(({ circles, text }, index) => (
        <div key={index} className={PingZeexpstyles.exampleRow}>
          <div className={PingZeexpstyles.circleContainer}>
            {circles.map((circleType, idx) => (
              <PingZeCircle key={idx} type={circleType} />
            ))}
          </div>
          <p>{text}</p>
        </div>
      ))}
    </div>
    <div className={PingZeexpstyles.exampleColumn}>
      <h5>Unacceptable</h5>
      {unacceptable.map(({ circles, text }, index) => (
        <div key={index} className={PingZeexpstyles.exampleRow}>
          <div className={PingZeexpstyles.circleContainer}>
            {circles.map((circleType, idx) => (
              <PingZeCircle key={idx} type={circleType} />
            ))}
          </div>
          <p>{text}</p>
        </div>
      ))}
    </div>
  </div>
);

// Quiz component for each quiz card
const QuizCard = ({ question, options, correctAnswer }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showResult, setShowResult] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setShowResult(true);
  };

  return (
    <div className={PingZeexpstyles.quizCard}>
      <p>{question}</p>
      <ul>
        {options.map((option, index) => (
          <li key={index}>
            <button
              className={`${selectedOption === option ? (option === correctAnswer ? PingZeexpstyles.correctAnimation : PingZeexpstyles.incorrectAnimation) : ''}`}
              onClick={() => handleOptionSelect(option)}
            >
              {option}
            </button>
          </li>
        ))}
      </ul>
      {showResult && (
        <p className={selectedOption === correctAnswer ? PingZeexpstyles.correctText : PingZeexpstyles.incorrectText}>
          {selectedOption === correctAnswer ? 'Correct!' : 'Incorrect'}
        </p>
      )}
    </div>
  );
};

// Explanation Section for Ping and Ze patterns
const PingZeExplanation = () => {
  const explanationRef = useRef(null);
  useScrollAnimation(explanationRef);
  
  const twoWordPingPingExamples = {
    ideal: [{ circles: ['ping', 'ping'], text: 'Gold sand' }],
    acceptable: [{ circles: ['ze', 'ping'], text: 'Hot sand' }],
    unacceptable: [
      { circles: ['ping', 'ze'], text: 'Gold beach' },
      { circles: ['ze', 'ze'], text: 'Hot beach' },
    ],
  };

  const twoWordZeZeExamples = {
    ideal: [{ circles: ['ze', 'ze'], text: 'Thick ice' }],
    acceptable: [{ circles: ['ping', 'ze'], text: 'Cold ice' }],
    unacceptable: [
      { circles: ['ze', 'ping'], text: 'Thick snow' },
      { circles: ['ping', 'ping'], text: 'Cold snow' },
    ],
  };

  const threeWordZeZePingExamples = {
    ideal: [{ circles: ['ze', 'ze', 'ping'], text: 'Slight fence holds' }],
    acceptable: [{ circles: ['ping', 'ze', 'ping'], text: 'Old fence holds' }],
    unacceptable: [
      { circles: ['ze', 'ping', 'ping'], text: 'Slight hedge holds' },
      { circles: ['ping', 'ping', 'ping'], text: 'Old hedge holds' },
      { circles: ['ze', 'ping', 'ze'], text: 'Slight hedge keeps' },
    ],
  };

  const threeWordZePingPingExamples = {
    ideal: [{ circles: ['ze', 'ping', 'ping'], text: 'Spent old barn' }],
    acceptable: [{ circles: ['ping', 'ping', 'ping'], text: 'Old red barn' }],
    unacceptable: [
      { circles: ['ping', 'ze', 'ping'], text: 'Red spent barn' },
      { circles: ['ping', 'ze', 'ze'], text: 'Old spent shack' },
      { circles: ['ze', 'ze', 'ze'], text: 'Faint spent shack' },
    ],
  };

  
  // States for toggling interactivity in the Two/Three-word units section
  return (
    <section ref={explanationRef} className={PingZeexpstyles.explanationSection}>
      <h3>Flexibility in Ping/Ze Patterns</h3>
      <p>
        There's some flexibility in the rule that makes it easier for poets! In the tonal patterns below, you'll notice that some columns are strict, while others allow flexibility. This gives poets room to adjust word choice while maintaining the poem's structure.
      </p>

      {/* Table for the four tonal patterns */}
      <div className={PingZeexpstyles.patternContainer}>
        
        {/* Five-Word Ze Start */}
        <div className={PingZeexpstyles.patternBox}>
          <h4>Five-Word Ze Start</h4>
          <table className={PingZeexpstyles.patternTable}>
            <tbody>
              <tr>
                <td><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ze" /></td>
                <td><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
              </tr>
              <tr>
                <td><PingZeCircle type="ping" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
                <td><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
              </tr>
            </tbody>
          </table>
          <p>In a five-word jujeu, columns 2, 4, and 5 are strict. But columns 1 and 3 are not </p>
        </div>

        {/* Five-Word Ping Start */}
        <div className={PingZeexpstyles.patternBox}>
          <h4>Five-Word Ping Start</h4>
          <table className={PingZeexpstyles.patternTable}>
            <tbody>
              <tr>
                <td><PingZeCircle type="ping" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
                <td><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
              </tr>
              <tr>
                <td><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ze" /></td>
                <td><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
              </tr>
            </tbody>
          </table>
          <p>In a five-word jujeu, columns 2, 4, and 5 are strict. But columns 1 and 3 are not.</p>
        </div>

        {/* Seven-Word Ze Start */}
        <div className={PingZeexpstyles.patternBox}>
          <h4>Seven-Word Ze Start</h4>
          <table className={PingZeexpstyles.patternTable}>
            <tbody>
              <tr>
                <td><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ze" /></td>
                <td><PingZeCircle type="ping" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
                <td><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
              </tr>
              <tr>
                <td><PingZeCircle type="ping" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
                <td><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ze" /></td>
                <td><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
              </tr>
            </tbody>
          </table>
          <p>In a seven-word jueju columns 2, 4, 6, and 7 are strict, but 1,3,5 are not.</p>
        </div>

        {/* Seven-Word Ping Start */}
        <div className={PingZeexpstyles.patternBox}>
          <h4>Seven-Word Ping Start</h4>
          <table className={PingZeexpstyles.patternTable}>
            <tbody>
              <tr>
                <td><PingZeCircle type="ping" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
                <td><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ze" /></td>
                <td><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
              </tr>
              <tr>
                <td><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ze" /></td>
                <td><PingZeCircle type="ping" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
                <td><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ze" /></td>
                <td className={PingZeexpstyles.strictColumn}><PingZeCircle type="ping" /></td>
              </tr>
            </tbody>
          </table>
          <p>In a seven-word jueju columns 2, 4, 6, and 7 are strict, but 1,3,5 are not.</p>
        </div>
      </div>

      <div className={PingZeexpstyles.flexibilitySection}>
        <h4>Understanding Ping/Ze Flexibility in Two- and Three-Word Units</h4>
        <p>
          At the unit level, you need four kinds of units to master the Ping/Ze patterns. Below are examples for two- and three-word units, with ideal, acceptable, and unacceptable forms.
        </p>
        <br></br>
        {/* Two-Word Units and Three-Word Units Header */}
        <div className={PingZeexpstyles.unitsHeader}>
        </div>

        {/* Two-Word Units and Three-Word Units Sections */}
        <div className={PingZeexpstyles.unitSection}>
          <div className={PingZeexpstyles.unitBox}>
          <h4>Two-Word Units:</h4>
            <p>If you need a Ping Ping unit, both Ping Ping (ideal) and Ze Ping (acceptable) are valid.</p>
            <br></br>
            <GroupedExample
              ideal={twoWordPingPingExamples.ideal}
              acceptable={twoWordPingPingExamples.acceptable}
              unacceptable={twoWordPingPingExamples.unacceptable}
            />
          </div>

          <div className={PingZeexpstyles.unitBox}>
            <p>If you need a Ze Ze unit, both Ze Ze (ideal) and Ping Ze (acceptable) are valid.</p>
            <br></br>
            <GroupedExample
              ideal={twoWordZeZeExamples.ideal}
              acceptable={twoWordZeZeExamples.acceptable}
              unacceptable={twoWordZeZeExamples.unacceptable}
            />
          </div>
        </div>

        <div className={PingZeexpstyles.unitSection}>
          <div className={PingZeexpstyles.unitBox}>
          <h4>Three-Word Units:</h4>
            <p>If you need a Ze Ze Ping unit, both Ze Ze Ping (ideal) and Ping Ze Ping (acceptable) are valid.</p>
            <br></br>
            <GroupedExample
              ideal={threeWordZeZePingExamples.ideal}
              acceptable={threeWordZeZePingExamples.acceptable}
              unacceptable={threeWordZeZePingExamples.unacceptable}
            />
          </div>

          <div className={PingZeexpstyles.unitBox}>
            <p>If you need a Ze Ping Ping unit, both Ze Ping Ping (ideal) and Ping Ping Ping (acceptable) are valid.</p>
            <br></br>
            <GroupedExample
              ideal={threeWordZePingPingExamples.ideal}
              acceptable={threeWordZePingPingExamples.acceptable}
              unacceptable={threeWordZePingPingExamples.unacceptable}
            />
          </div>
        
        </div>  
      </div>

      {/* Quiz Section */}
      <div className={PingZeexpstyles.quizSection}>
        <h4>Test Your Understanding: Quiz</h4>
        <div className={PingZeexpstyles.quizContainer}>
          {/* Quiz Card 1 */}
          <QuizCard
            question="which of these units could be an acceptable replacement for wide stream (○ ○)"
            options={['Swift stream', 'Swift brook']}
            correctAnswer="Swift stream"
          />

          {/* Quiz Card 2 */}
          <QuizCard
            question="which of these units could be an acceptable replacement for thick grass (● ●)"
            options={['Green grass', 'Thick reeds']}
            correctAnswer="Green grass"
          />

          {/* Quiz Card 3 */}
          <QuizCard
            question="which of these units could be an acceptable replacement for quick stream flows (● ○ ○)"
            options={['Cool stream flows', 'Cool brook flows']}
            correctAnswer="Cool stream flows"
          />

          <QuizCard
            question="which of these units could be an acceptable replacement for deep salt brine (● ● ○)"
            options={['Dark sea brine', 'Sea salt brine']}
            correctAnswer="Sea salt brine"
          />
        </div>
      </div>
      
    </section>
  );
};

export default PingZeExplanation;

