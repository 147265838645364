import React, { useState } from 'react';
import PingZePoemstyles from './PingZePoem.module.css';

const PingZePoem = () => {
  const patterns = {
    // Patterns for the five-word and seven-word Jueju poems
    "five_ze_start": {
      "line1": ["ze", "ze", "ze", "ping", "ping"],
      "line2": ["ping", "ping", "ze", "ze", "ping"],
      "line3": ["ping", "ping", "ze", "ze", "ping"],
      "line4": ["ze", "ze", "ze", "ping", "ping"],
    },
    "five_ping_start": {
      "line1": ["ping", "ping", "ze", "ze", "ping"],
      "line2": ["ze", "ze", "ze", "ping", "ping"],
      "line3": ["ze", "ze", "ping", "ping", "ze"],
      "line4": ["ping", "ping", "ze", "ze", "ping"],
    },
    "seven_ze_start": {
      "line1": ["ze", "ze", "ping", "ping", "ze", "ze", "ping"],
      "line2": ["ping", "ping", "ze", "ze", "ze", "ping", "ping"],
      "line3": ["ping", "ping", "ze", "ze", "ping", "ping", "ze"],
      "line4": ["ze", "ze", "ping", "ping", "ze", "ze", "ping"],
    },
    "seven_ping_start": {
      "line1": ["ping", "ping", "ze", "ze", "ze", "ping", "ping"],
      "line2": ["ze", "ze", "ping", "ping", "ze", "ze", "ping"],
      "line3": ["ze", "ze", "ping", "ping", "ping", "ze", "ze"],
      "line4": ["ping", "ping", "ze", "ze", "ze", "ping", "ping"],
    },
};


const fiveWordHighlightColumns = [2, 4, 5];
const sevenWordHighlightColumns = [2, 4, 6, 7];

const renderLineWithSpacing = (line, hideWords = false) => {
  const length = line.length;

  const wordClass = hideWords ? PingZePoemstyles.hideWords : '';

  if (length === 5) {
    // For 5-word lines: 2+3 format
    return (
      <div className={PingZePoemstyles.tonalPatternUnitContainer}>
        <div className={PingZePoemstyles.unitRow}>
          <div className={PingZePoemstyles.twoWordUnit}>
            {line.slice(0, 2).map((type, index) => (
              <div className={PingZePoemstyles.unitGroup} key={index}>
                <span
                  className={`${PingZePoemstyles.circle} ${
                    type === "ze"
                      ? PingZePoemstyles.filledCircle
                      : PingZePoemstyles.emptyCircle
                  }`}
                ></span>
                <span className={`${PingZePoemstyles[type]} ${wordClass}`}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </span>
              </div>
            ))}
          </div>
          <div className={PingZePoemstyles.threeWordUnit}>
            {line.slice(2).map((type, index) => (
              <div className={PingZePoemstyles.unitGroup} key={index}>
                <span
                  className={`${PingZePoemstyles.circle} ${
                    type === "ze"
                      ? PingZePoemstyles.filledCircle
                      : PingZePoemstyles.emptyCircle
                  }`}
                ></span>
                <span className={`${PingZePoemstyles[type]} ${wordClass}`}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } else if (length === 7) {
    // For 7-word lines: 2+2+3 format
    return (
      <div className={PingZePoemstyles.tonalPatternUnitContainer}>
        <div className={PingZePoemstyles.unitRow}>
          <div className={PingZePoemstyles.twoWordUnit}>
            {line.slice(0, 2).map((type, index) => (
              <div className={PingZePoemstyles.unitGroup} key={index}>
                <span
                  className={`${PingZePoemstyles.circle} ${
                    type === "ze"
                      ? PingZePoemstyles.filledCircle
                      : PingZePoemstyles.emptyCircle
                  }`}
                ></span>
                <span className={`${PingZePoemstyles[type]} ${wordClass}`}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </span>
              </div>
            ))}
          </div>
          <div className={PingZePoemstyles.twoWordUnit}>
            {line.slice(2, 4).map((type, index) => (
              <div className={PingZePoemstyles.unitGroup} key={index}>
                <span
                  className={`${PingZePoemstyles.circle} ${
                    type === "ze"
                      ? PingZePoemstyles.filledCircle
                      : PingZePoemstyles.emptyCircle
                  }`}
                ></span>
                <span className={`${PingZePoemstyles[type]} ${wordClass}`}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </span>
              </div>
            ))}
          </div>
          <div className={PingZePoemstyles.threeWordUnit}>
            {line.slice(4).map((type, index) => (
              <div className={PingZePoemstyles.unitGroup} key={index}>
                <span
                  className={`${PingZePoemstyles.circle} ${
                    type === "ze"
                      ? PingZePoemstyles.filledCircle
                      : PingZePoemstyles.emptyCircle
                  }`}
                ></span>
                <span className={`${PingZePoemstyles[type]} ${wordClass}`}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
};


const renderPattern = (pattern) => {
    return (
      <div className={PingZePoemstyles.tonalPatternsContainer}>
        {Object.values(pattern).map((line, index) => (
          <div key={index} className={PingZePoemstyles.tonalPatternUnitContainer}>
            {renderLineWithSpacing(line)}
          </div>
        ))}
      </div>
    );
};
const renderFlexibilityPattern = (pattern, highlightColumns) => (
  <table className={PingZePoemstyles.patternTable}>
    <tbody>
      {Object.values(pattern).map((line, lineIndex) => (
        <tr key={lineIndex}>
          {line.map((type, colIndex) => (
            <td
              key={colIndex}
              className={
                highlightColumns.includes(colIndex + 1)
                  ? PingZePoemstyles.strictColumn
                  : ''
              }
            >
              <span
                className={`${PingZePoemstyles.circle} ${type === "ze" ? PingZePoemstyles.filledCircle : PingZePoemstyles.emptyCircle}`}
              >
                {/* Ensure circles are rendered correctly here */}
              </span>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);



// Quiz Section
const patternForLines = {
  "line1": ["ping", "ping", "ze", "ze", "ze", "ping", "ping"],
  "line2": ["ze", "ze", "ping", "ping", "ze", "ze", "ping"],
  "line3": ["ze", "ze", "ping", "ping", "ping", "ze", "ze"],
  "line4": ["ping", "ping", "ze", "ze", "ze", "ping", "ping"],
};


// Options and correct answers
const options = ['gust sweeps', 'wind blows', 'to find', 'this world weaves', 'come with books'];
const correctAnswers = ['gust sweeps', 'wind blows', 'come with books', 'to find', 'this world weaves'];
const [draggedOption, setDraggedOption] = useState(null);
const [selectedAnswers, setSelectedAnswers] = useState(Array(6).fill(''));
const [result, setResult] = useState(null);

// Handle drag start
const handleDragStart = (option) => {
  setDraggedOption(option);
};

// Handle drop to specific index
const handleDrop = (index) => {
  if (draggedOption) {
    const newAnswers = [...selectedAnswers];
    if (!newAnswers[index]) { // Only update if the dropbox is empty
      newAnswers[index] = draggedOption;
      setSelectedAnswers(newAnswers);
    }
    setDraggedOption(null);
  }
};

// Updated handleCheck function with debugging
// Updated handleCheck function with correct index mapping
const handleCheck = () => {
  // Clean the selected answers to remove any empty strings or undefined values
  const cleanedSelectedAnswers = selectedAnswers.filter(answer => answer !== '' && answer !== undefined);

  console.log('Cleaned Selected Answers:', cleanedSelectedAnswers);
  console.log('Correct Answers:', correctAnswers);

  // Ensure the number of cleaned answers matches the number of correct answers
  if (cleanedSelectedAnswers.length !== correctAnswers.length) {
    setResult(false); // If the numbers don't match, it's incorrect
    return;
  }

  // Compare the cleaned selected answers with the correct answers
  const isCorrect = cleanedSelectedAnswers.every((answer, index) => {
    return answer.trim() === correctAnswers[index].trim();
  });

  setResult(isCorrect);
};



const handleRetry = () => {
  setSelectedAnswers(Array(6).fill(''));
  setResult(null);
};

// Render the lines with dropboxes
const renderLineWithDropboxes = (line = [], lineIndex, pattern = []) => {
  const groupings = [2, 2, 3]; // Grouping for each line, 2+2+3
  let patternGroups = [];
  let wordGroups = [];
  let patternIndex = 0;

  groupings.forEach((groupSize) => {
    patternGroups.push(
      <div key={`pattern-group-${lineIndex}-${patternIndex}`} className={PingZePoemstyles.patternGroup}>
        {pattern.slice(patternIndex, patternIndex + groupSize).map((type, idx) => (
          <span key={`circle-${lineIndex}-${idx}`} className={`${PingZePoemstyles.circle} ${type === 'ze' ? PingZePoemstyles.filledCircle : PingZePoemstyles.emptyCircle}`}></span>
        ))}
      </div>
    );

    wordGroups.push(
      <div key={`word-group-${lineIndex}-${patternIndex}`} className={PingZePoemstyles.wordGroup}>
        {line.slice(patternIndex, patternIndex + groupSize).map((word, wordIndex) => {
          const totalIndex = lineIndex * 3 + wordIndex + patternIndex;

          return word === 'dropbox' ? (
            <div key={wordIndex} className={PingZePoemstyles.dropbox} onDrop={() => handleDrop(totalIndex)} onDragOver={(e) => e.preventDefault()}>
              <div className={PingZePoemstyles.droppedWord}>{selectedAnswers[totalIndex]}</div>
            </div>
          ) : (
            <span key={wordIndex}>{word}</span>
          );
        })}
      </div>
    );

    patternIndex += groupSize;
  });

  return (
    <div key={lineIndex} className={PingZePoemstyles.coupletLine}>
      <div className={PingZePoemstyles.patternRow}>{patternGroups}</div>
      <div className={PingZePoemstyles.wordRow}>{wordGroups}</div>
    </div>
  );
};

const handleClear = () => {
  setSelectedAnswers(Array(6).fill('')); // Reset all answers to an empty state
  setResult(null); // Reset the result message
};

  return (
    <div className={PingZePoemstyles.pingZePoemContainer}>
      {/* Existing Sections */}
      <section className={PingZePoemstyles.explanationSection}>
        <h3>Ping/Ze Patterns in Regulated Jueju Poetry</h3>
        <p>
          In the regulated verse you've learned so far for the Juren level, you've mastered grammatical, semantic, and Ping/Ze rhyming/parallelism in the first couplet. In this level, you will only need to focus on extending the Ping/Ze patterns you learned earlier, as the second couplet does not require grammatical or semantic rhyming/parallelism.
        </p>
      </section>

      <section className={PingZePoemstyles.explanationSection}>
        <p>
          In regulated Jueju poetry, the terms "Ping Start" and "Ze Start" refer to the pattern of the first unit in the first line of the poem. According to the flexibility we've learned in the couplet's Ping/Ze pattern, you need to understand that the <strong>second word</strong> in the first unit is the deciding factor for determining whether the poem is a "Ping Start" or a "Ze Start," as the first word is flexible.
        </p>
      </section>

      {/* Patterns Section */}
      <section className={PingZePoemstyles.explanationSection}>
        <h3>Five-Word Ping/Ze Patterns</h3>
        <div className={PingZePoemstyles.patternContainer}>
          <div className={PingZePoemstyles.patternBox}>
            <h4>Five-Word Ze Start:</h4>
            {renderPattern(patterns["five_ze_start"])}
          </div>
          <div className={PingZePoemstyles.patternBox}>
            <h4>Five-Word Ping Start:</h4>
            {renderPattern(patterns["five_ping_start"])}
          </div>
        </div>
      </section>

      <section className={PingZePoemstyles.explanationSection}>
        <h3>Seven-Word Ping/Ze Patterns</h3>
        <div className={PingZePoemstyles.patternContainer}>
          <div className={PingZePoemstyles.patternBox}>
            <h4>Seven-Word Ze Start:</h4>
            {renderPattern(patterns["seven_ze_start"])}
          </div>
          <div className={PingZePoemstyles.patternBox}>
            <h4>Seven-Word Ping Start:</h4>
            {renderPattern(patterns["seven_ping_start"])}
          </div>
        </div>
      </section>

      {/* New Side-by-Side Explanation Section */}
      <section className={PingZePoemstyles.explanationSection}>
        <h3>Building the Second Couplet with Ping/Ze Patterns</h3>
        <p>
          Maintaining the Ping/Ze patterns in the second couplet of a Jueju is a bit different from the first couplet because these lines function more like sentences. Let’s take an example to demonstrate how to modify a line to follow Ping/Ze patterns.
        </p>
        <p>Consider the line:</p>
        <div className={PingZePoemstyles.exampleLine}>
          "Who will meet me on the road?"
        </div>
        <p>
          The Ping/Ze pattern of this turning line is:
        </p>
        <div className={PingZePoemstyles.patternDisplay}>
          {renderLineWithSpacing(["ping", "ping", "ze", "ping", "ping", "ping", "ping"])}
        </div>
        <p>
          This is not a valid Ping/Ze pattern for either a Ze start or Ping start poem. To make it follow Ping/Ze patterns, let’s consider how we can modify the line.
        </p>

        <div className={PingZePoemstyles.sideBySideContainer}>
          {/* Ze Start Case */}
          <div className={PingZePoemstyles.patternBoxSide}>
            <h4>Ze Start Poem:</h4>
            <p>
              We can modify the line to:
            </p>
            <div className={PingZePoemstyles.exampleLine}>
              "Who will meet us on the path?"
            </div>
            <div className={PingZePoemstyles.patternDisplay}>
              {renderLineWithSpacing(["ping", "ping", "ze", "ze", "ping", "ping", "ze"])}
            </div>
          </div>

          {/* Ping Start Case */}
          <div className={PingZePoemstyles.patternBoxSide}>
            <h4>Ping Start Poem:</h4>
            <p>
              We can modify the line to:
            </p>
            <div className={PingZePoemstyles.exampleLine}>
              "What meets those who roam this path?"
            </div>
            <div className={PingZePoemstyles.patternDisplay}>
              {renderLineWithSpacing(["ze", "ze", "ping", "ping", "ping", "ze", "ze"])}
            </div>
          </div>
        </div>

        <p>
          As you can see, the word choice must often be modified to match the required Ping/Ze patterns.
        </p>
      </section>
      {/* Flexibility Section */}
      <section className={PingZePoemstyles.explanationSection}>
        <h3>Flexibility in Ping/Ze Patterns</h3>
        <p>In five-word Jueju the 2, 4, and 5 columns are strict and In seven-word jueju the 2, 4, 6, and 7 columns are strict, while the other columns allow for flexibility.</p>

        <div className={PingZePoemstyles.flexibilityContainer}>
          <div className={PingZePoemstyles.rowContainer}>
            <div className={PingZePoemstyles.flexibilityBox}>
              <h4>Five-Word Ze Start</h4>
              {renderFlexibilityPattern(patterns["five_ze_start"], fiveWordHighlightColumns)}
            </div>

            <div className={PingZePoemstyles.flexibilityBox}>
              <h4>Five-Word Ping Start</h4>
              {renderFlexibilityPattern(patterns["five_ping_start"], fiveWordHighlightColumns)}
            </div>
          </div>

          <div className={PingZePoemstyles.rowContainer}>
            <div className={PingZePoemstyles.flexibilityBox}>
              <h4>Seven-Word Ze Start</h4>
              {renderFlexibilityPattern(patterns["seven_ze_start"], sevenWordHighlightColumns)}
            </div>

            <div className={PingZePoemstyles.flexibilityBox}>
              <h4>Seven-Word Ping Start</h4>
              {renderFlexibilityPattern(patterns["seven_ping_start"], sevenWordHighlightColumns)}
            </div>
          </div>
        </div>
      </section>
      {/* Quiz Section */}
      <section className={PingZePoemstyles.quizSection}>
        <h3>Fill in the blanks to finish the poem.</h3>

        <div className={PingZePoemstyles.coupletContainer}>
          {/* Line 1 */}
          {renderLineWithDropboxes(['cool fall', 'dropbox', 'up gold leaves'], 0, patternForLines.line1)}
          {/* Line 2 */}
          {renderLineWithDropboxes(['brisk dusk', 'dropbox', 'past wet eaves'], 1, patternForLines.line2)}
          {/* Line 3 */}
          {renderLineWithDropboxes(['like us', 'these youth\'s', 'dropbox'], 2, patternForLines.line3)}
          {/* Line 4 */}
          {renderLineWithDropboxes(['dropbox', 'out what', 'dropbox'], 3, patternForLines.line4)}
        </div>

        {/* Draggable Options */}
        <div className={PingZePoemstyles.optionContainer}>
          {options.map((option, index) => (
            <div
              key={index}
              className={PingZePoemstyles.draggableOption}
              draggable
              onDragStart={() => handleDragStart(option)}
            >
              {option}
            </div>
          ))}
        </div>

        {/* Check, Clear, and Retry Buttons */}
      <div className={PingZePoemstyles.buttonContainer}>
        <button className={PingZePoemstyles.checkButton} onClick={handleCheck}>
          Check
        </button>
        <button className={PingZePoemstyles.clearButton} onClick={handleClear}>
          Clear
        </button>
        {result !== null && (
          <button className={PingZePoemstyles.retryButton} onClick={handleRetry}>
            Retry
          </button>
        )}
      </div>

        {/* Results */}
        {result !== null && (
          <div className={PingZePoemstyles.resultContainer}>
            {result ? <p className={PingZePoemstyles.correct}>Correct!</p> : <p className={PingZePoemstyles.incorrect}>Try again!</p>}
          </div>
        )}
      </section>
      
    </div>
  );

};

export default PingZePoem;
