import React, { useState } from 'react';
import Coupletstyles from './BuildCouplet.module.css';
import cimg1 from './cimg1.webp'; // Evening image
import cimg2 from './cimg2.webp'; // Morning image

const themes = ["Evening", "Morning"];
const images = { Evening: cimg1, Morning: cimg2 };

const quizzes = {
  Evening: {
    image: cimg1,
    firstLine: "late sun slips through trees",
    options: [
      "dawn brakes new bird sings",
      "great peaks pierce blue sky",
      "faint stars cool light breeze"
    ],
    validSecondLine: "faint stars cool light breeze",
    feedback: {
      "dawn brakes new bird sings": "Try again, dawn and evening do not go together.",
      "blue seas turn in deep": "Try again, this line does not match the geographical settings of the first line.",
      "how can we find sleep": "Try again, this line does not belong to the first couplet of Jueju.",
      "faint stars cool light breeze": "Correct! You have built a valid couplet related to the evening theme."
    }
  },
  Morning: {
    image: cimg2,
    firstLine: "dawn light pours through reeds",
    options: [
      "stars shine like gold beads",
      "bird song flung like seeds",
      "late dusk crisp brown weeds"
    ],
    validSecondLine: "bird song flung like seeds",
    feedback: {
      "stars shine like gold beads": "Try again, stars and morning do not go together.",
      "late dusk crisp brown weeds": "Try again, dusk and dawn are contradicting.",
      "bird song flung like seeds": "Correct! You have formed a valid couplet related to the morning theme."
    }
  }
};

const themeColors = {
  Evening: '#6ab7ff',
  Morning: '#ffab40'
};

const BuildCouplet = () => {
  const [selectedTheme, setSelectedTheme] = useState('');
  const [selectedSecondLine, setSelectedSecondLine] = useState('');
  const [feedback, setFeedback] = useState('');
  const [completedThemes, setCompletedThemes] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [draggedLine, setDraggedLine] = useState(null);
  const [isFinished, setIsFinished] = useState(false); // Track completion of all quizzes

  const handleThemeSelect = (theme) => {
    if (completedThemes.includes(theme)) {
      return;
    }
    setSelectedTheme(theme);
    setSelectedSecondLine('');
    setFeedback('');
    setShowImage(true);
  };

  const handleDragStart = (line) => {
    setDraggedLine(line);
  };

  const handleDrop = () => {
    setSelectedSecondLine(draggedLine);
    setDraggedLine(null);
  };
  const renderFinalCard = () => {
    return (
        <div className={Coupletstyles.finalCard}>
            <h3>You're done with this quiz set. Go ahead and start your Tongsheng exam!</h3>
        </div>
    );
};

  const handleClear = () => {
    setSelectedSecondLine('');
    setFeedback('');
  };

  const handleCheck = () => {
    const quiz = quizzes[selectedTheme];
    const isValid = quiz.validSecondLine === selectedSecondLine;
  
    if (isValid) {
      setFeedback(quiz.feedback[selectedSecondLine]);
  
      const newCompletedThemes = [...completedThemes, selectedTheme];
      setCompletedThemes(newCompletedThemes);
  
      const cardElement = document.querySelector(`.${Coupletstyles.quizCard}`);
      if (cardElement) {
        cardElement.classList.add(Coupletstyles.correct);
        cardElement.classList.add(Coupletstyles.correctAnimation);
      }
  
      // Show the correct answer feedback for 2 seconds, then proceed to next step
      setTimeout(() => {
        if (newCompletedThemes.length === themes.length) {
          // After showing the feedback, go to the final card
          setIsFinished(true);
        } else {
          // Automatically open the next theme if available
          const nextTheme = themes.find(theme => !newCompletedThemes.includes(theme));
          handleThemeSelect(nextTheme);
        }
        // Clear the feedback and reset the card animation
        handleClear();
        if (cardElement) {
          cardElement.classList.remove(Coupletstyles.correctAnimation);
          cardElement.classList.remove(Coupletstyles.correct);
        }
      }, 2000);
    } else {
      setFeedback(quiz.feedback[selectedSecondLine]);
  
      const cardElement = document.querySelector(`.${Coupletstyles.quizCard}`);
      if (cardElement) {
        cardElement.classList.add(Coupletstyles.incorrectAnimation);
        setTimeout(() => {
          cardElement.classList.remove(Coupletstyles.incorrectAnimation);
        }, 1000);
      }
    }
  };
  


  return (
    <div className={Coupletstyles.unitContainer}>
      <h2 className={Coupletstyles.title}>Build the First Couplet</h2>
      <section className={Coupletstyles.introduction}>
        <h3>What Makes a Good First Couplet?</h3>
        <br />
        <p>
        In Jueju poetry, the first couplet sets up a natural scene (境-jing) with the first line (Qi 起）offering a broad or detailed view, and the second line (承 -cheng) either zooms in or zooms out to  describe other elements of the same scene, allowing for different levels of description between the two lines. Both lines should maintain a consistent portrayal of the scene, ensuring that the imagery complements rather than contradicts each other. 
        </p>
        <p>
        For example, if the first line introduces a forest using words like "trees," "wind," or "leaves," the second line should continue to describe elements of the forest, such as "birds" or "streams," to keep the scene coherent.
        </p>
        <br />
        <ul>
          <li>
            <em>"Brick streets"&nbsp;&nbsp;&nbsp;&nbsp;  "old walls"&nbsp;&nbsp;&nbsp;&nbsp;  "thin wire vines"</em> <br />
            <em>"Bread shops"&nbsp;&nbsp;&nbsp;&nbsp;  "tea cakes"&nbsp;&nbsp;&nbsp;&nbsp;  "long roots twine"</em>
          </li>
          <br />
          <li>
            <em>"Soft moss"&nbsp;&nbsp;&nbsp;&nbsp;"cool grey stones"</em> <br />
            <em>"Rough grass"&nbsp;&nbsp;&nbsp;&nbsp;"cold pine cones"</em>
          </li>
          <br />
          <li>
            <em>"clear stream"&nbsp;&nbsp;&nbsp;&nbsp; "faint mist" &nbsp;&nbsp;&nbsp;&nbsp; "late sun wanes"</em> <br />
            <em>"fresh creek" &nbsp;&nbsp;&nbsp;&nbsp; "thin fog"&nbsp;&nbsp;&nbsp;&nbsp; "gray sky rains"</em>
          </li>
        </ul>
      </section>

      <section className={Coupletstyles.quizzes}>
        <div className={`${Coupletstyles.quizContainer} ${Coupletstyles.quizCard}`}>
        {!selectedTheme && !isFinished && (
              <>
                  <h3>Quiz: Select a Theme</h3>
                  <div className={Coupletstyles.options}>
                      {themes.map((theme) => (
                          <button
                              key={theme}
                              onClick={() => handleThemeSelect(theme)}
                              className={completedThemes.includes(theme) ? Coupletstyles.completedOption : ''}
                              disabled={completedThemes.includes(theme)}
                              style={{ backgroundColor: themeColors[theme], color: '#fff' }}
                          >
                              {theme}
                          </button>
                      ))}
                  </div>
              </>
          )}
          {selectedTheme && !isFinished && (
            <>
              {selectedTheme === 'Morning' && <h3>Quiz: Build a Couplet in the Morning Theme</h3>}
              {selectedTheme === 'Evening' && <h3>Quiz: Build a Couplet in the Evening Theme</h3>}

              {showImage && <img src={images[selectedTheme]} alt={selectedTheme} className={Coupletstyles.image} />}
              <div className={Coupletstyles.firstLine}>{quizzes[selectedTheme].firstLine}</div>
              <div className={Coupletstyles.dropArea}>
                <div className={Coupletstyles.dropBox} onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
                  {selectedSecondLine && <span className={Coupletstyles.selectedLine}>{selectedSecondLine}</span>}
                </div>
              </div>
              <div className={Coupletstyles.unitSection}>
                <div className={Coupletstyles.unitPool}>
                  {quizzes[selectedTheme].options.map((line, index) => (
                    <div key={index} className={Coupletstyles.unit} draggable onDragStart={() => handleDragStart(line)}>
                      {line}
                    </div>
                  ))}
                </div>
              </div>
              <div className={Coupletstyles.buttons}>
                <button onClick={handleCheck} className={Coupletstyles.submitButton}>Check</button>
                <button onClick={handleClear} className={Coupletstyles.clearButton}>Clear</button>
              </div>
              <p className={Coupletstyles.feedback}>{feedback}</p>
            </>
          )}
          {isFinished && renderFinalCard()}
        </div>
        <div className={Coupletstyles.indicator}>
          {Array.from({ length: themes.length }).map((_, index) => (
            <span
              key={index}
              className={`${Coupletstyles.dot} ${index < completedThemes.length ? Coupletstyles.active : ''}`}
            ></span>
          ))}
        </div>
      </section>
    </div>
  );
};

export default BuildCouplet;
