import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

const AdminInterface = () => {
  const [activeTab, setActiveTab] = useState('viewUsers');
  const [users, setUsers] = useState([]);
  const [manualSubmissions, setManualSubmissions] = useState([]);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [feedbackText, setFeedbackText] = useState('');
  const [error, setError] = useState('');

  const [unviewedCount, setUnviewedCount] = useState(0);
  const [feedbackCompletedCount, setFeedbackCompletedCount] = useState(0);

  const getToken = () => {
    return localStorage.getItem('adminToken');
  };

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const response = await axios.get(`${API_URL}/admin/users/`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });
        setUsers(response.data);
      } catch (error) {
        setError('Failed to fetch users: ' + error);
      }
    };

    const fetchManualSubmissions = async () => {
      try {
        const response = await axios.get(`${API_URL}/admin/manual-submissions/`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });
        setManualSubmissions(response.data);
        setUnviewedCount(response.data.filter((submission) => submission.status === 'unviewed').length);
        setFeedbackCompletedCount(response.data.filter((submission) => submission.status === 'feedback_done').length);
      } catch (error) {
        setError('Failed to fetch manual submissions: ' + error);
      }
    };

    fetchUsersData();
    fetchManualSubmissions();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSelectedSubmission(null);
    setFeedbackText('');
  };

  const handleProvideFeedback = async (submissionId) => {
    try {
      await axios.post(`${API_URL}/admin/manual-submissions/feedback/`, {
        submission_id: submissionId,
        feedback: feedbackText,
      }, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      alert('Feedback submitted successfully');
      setSelectedSubmission(null);
      setFeedbackText('');
    } catch (error) {
      setError('Failed to submit feedback: ' + error);
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Admin Dashboard</h1>
      <header style={styles.header}>
        <div style={styles.stats}>
          <div style={styles.statCard}>
            <h3 style={styles.statTitle}>Unviewed Submissions</h3>
            <p style={styles.statNumber}>{unviewedCount}</p>
          </div>
          <div style={styles.statCard}>
            <h3 style={styles.statTitle}>Feedback Completed</h3>
            <p style={styles.statNumber}>{feedbackCompletedCount}</p>
          </div>
          <div style={styles.statCard}>
            <h3 style={styles.statTitle}>Total Users</h3>
            <p style={styles.statNumber}>{users.length}</p>
          </div>
        </div>
      </header>

      {error && <p style={styles.error}>{error}</p>}

      <div style={styles.tabs}>
        <button
          style={{ ...styles.tabButton, ...(activeTab === 'viewUsers' && styles.activeTab) }}
          onClick={() => handleTabChange('viewUsers')}
        >
          View Users
        </button>
        <button
          style={{ ...styles.tabButton, ...(activeTab === 'manualSubmissions' && styles.activeTab) }}
          onClick={() => handleTabChange('manualSubmissions')}
        >
          Manual Submissions
        </button>
      </div>

      {activeTab === 'viewUsers' && (
        <div style={styles.contentContainer}>
          <h3>Users</h3>
          <div style={styles.cardGrid}>
            {users.map((user) => (
              <div key={user.id} style={styles.userCard}>
                <h4>{user.name}</h4>
                <p><strong>Email:</strong> {user.email}</p>
                <p><strong>Type:</strong> {user.userType}</p>
                <p><strong>Rank:</strong> {user.rank}</p>
                <p><strong>Submissions:</strong> {user.submissionsCount}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {activeTab === 'manualSubmissions' && (
        <div style={styles.contentContainer}>
          <h3>Manual Submissions</h3>
          {manualSubmissions.length === 0 ? (
            <p>No manual submissions pending.</p>
          ) : (
            <div style={styles.cardGrid}>
              {manualSubmissions.map((submission) => (
                <div key={submission._id} style={styles.submissionCard}>
                  <p>
                    <strong>{submission.couplet ? 'Couplet' : 'Poem'}:</strong> {submission.couplet || submission.poem}
                  </p>
                  <p><strong>User Level:</strong> {submission.user_level}</p>
                  <p><strong>Status:</strong> {submission.status}</p>
                  <button
                    style={styles.button}
                    onClick={() => setSelectedSubmission(submission)}
                  >
                    Provide Feedback
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {selectedSubmission && (
        <div style={styles.feedbackModal}>
          <div style={styles.feedbackContent}>
            <h3>Provide Feedback</h3>
            <p><strong>{selectedSubmission.couplet ? 'Couplet' : 'Poem'}:</strong> {selectedSubmission.couplet || selectedSubmission.poem}</p>
            <textarea
              style={styles.feedbackTextArea}
              placeholder="Enter your feedback here..."
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
            />
            <button style={styles.button} onClick={() => handleProvideFeedback(selectedSubmission._id)}>Submit Feedback</button>
            <button style={styles.button} onClick={() => setSelectedSubmission(null)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    backgroundColor: '#f0f2f5',
    minHeight: '100vh',
    padding: '20px',
  },
  title: {
    fontSize: '2em',
    marginBottom: '10px',
    color: '#3d405b',
  },
  header: {
    width: '100%',
    backgroundColor: '#3d405b',
    color: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '30px',
    textAlign: 'center',
  },
  stats: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '10px',
  },
  statCard: {
    backgroundColor: '#6a7592',
    color: '#ffffff',
    padding: '15px 25px',
    borderRadius: '8px',
    width: '180px',
    textAlign: 'center',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s',
  },
  statTitle: {
    fontSize: '1.1em',
    fontWeight: '600',
    color: '#ffffff',
  },
  statNumber: {
    fontSize: '1.6em',
    fontWeight: '700',
    color: '#c9d1da',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '20px',
  },
  tabButton: {
    backgroundColor: '#4a4e69',
    color: 'white',
    border: 'none',
    padding: '12px 25px',
    margin: '0 10px',
    cursor: 'pointer',
    borderRadius: '6px',
    transition: 'background 0.3s',
  },
  activeTab: {
    backgroundColor: '#9a8c98',
  },
  contentContainer: {
    width: '100%',
    maxWidth: '800px',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '20px',
  },
  userCard: {
    backgroundColor: '#f7f5f2',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s',
    textAlign: 'left',
  },
  submissionCard: {
    backgroundColor: '#f3f3f3',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s',
    textAlign: 'left',
  },
  button: {
    backgroundColor: '#9a8c98',
    color: 'white',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    margin: '5px 0',
  },
  feedbackModal: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  feedbackContent: {
    background: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '90%',
    maxWidth: '500px',
    textAlign: 'center',
  },
  feedbackTextArea: {
    width: '100%',
    height: '100px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    marginTop: '10px',
    resize: 'vertical',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
};

export default AdminInterface;

