import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import HeaderStyles from './Header.module.css';
import AccountModal from './AccountModal';
import BadgesModal from './BadgesModal';
import SubmissionsModal from './SubmissionsModal';

const Header = () => {
  const navigate = useNavigate();
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showBadgesModal, setShowBadgesModal] = useState(false);
  const [showSubmissionsModal, setShowSubmissionsModal] = useState(false);
  const [isGuest, setIsGuest] = useState(!localStorage.getItem('token'));
  const [username, setUsername] = useState('');
  const dropdownRef = useRef(null);
  const location = useLocation(); // Get the current location
  const [isTeacher, setIsTeacher] = useState(false);

  useEffect(() => {
    const role = localStorage.getItem('role'); // Check the user's role stored in local storage
    if (role === 'teacher') {
      setIsTeacher(true); // Set isTeacher to true if the role is 'teacher'
    }
  }, []);
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    if (token && storedUsername) {
      setIsGuest(false);
      setUsername(storedUsername);
    } else {
      setIsGuest(true);
      setUsername('');
    }
  }, []);

  const handleProfileClick = () => {
    setProfileDropdown(!profileDropdown);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('role');  // Clear role when logging out
    sessionStorage.setItem('hasMadeSelection', 'guest'); 
    setIsGuest(true);
    setProfileDropdown(false);
    setUsername('');
    navigate('/');
    window.location.reload(); 
};
  const handleLoginRedirect = () => {
    navigate('/');
  };

  const handleAccountClick = () => {
    setShowAccountModal(true);
    setProfileDropdown(false);
  };

  const handleBadgesClick = () => {
    setShowBadgesModal(true);
    setProfileDropdown(false);
  };

  const handleSubmissionsClick = () => {
    setShowSubmissionsModal(true);
    setProfileDropdown(false);
  };

  const closeModals = () => {
    setShowAccountModal(false);
    setShowBadgesModal(false);
    setShowSubmissionsModal(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setProfileDropdown(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className={HeaderStyles.header}>
        <nav className={HeaderStyles.navCenter}>
          <Link to="/" className={location.pathname === '/' ? HeaderStyles.active : ''}>Home</Link>
          <div className={HeaderStyles.dropdown}>
            <Link to="#about" className={location.pathname.startsWith('/about') ? HeaderStyles.active : ''}>About</Link>
            <div className={HeaderStyles.dropdownContent}>
              <Link to="/about/website" className={location.pathname === '/about/website' ? HeaderStyles.active : ''}>About Website</Link>
              <Link to="/about/jueju" className={location.pathname === '/about/jueju' ? HeaderStyles.active : ''}>About Jueju</Link>
              <Link to="/about/english-jueju" className={location.pathname === '/about/english-jueju' ? HeaderStyles.active : ''}>About English Jueju</Link>
            </div>
          </div>
          <Link to="/learn/examination-path" className={location.pathname === '/learn/examination-path' ? HeaderStyles.active : ''}>Learn</Link>
          <Link to="/assess" className={location.pathname === '/assess' ? HeaderStyles.active : ''}>Assess</Link>
          <a
            href="https://www.ou.edu/cis/research/institute-for-us-china-issues/us-china-cultural-issues/newman-prize-for-english-jueju"
            target="_blank"
            rel="noopener noreferrer"
            className={HeaderStyles.link}
          >
            Newman Prize
          </a>
          {/* Show Dashboard link only for teachers */}
          {isTeacher && <Link to="/teacher/dashboard" className={location.pathname === '/teacher/dashboard' ? HeaderStyles.active : ''}>Dashboard</Link>}
        </nav>
        <div className={HeaderStyles.profileSection} onClick={handleProfileClick} ref={dropdownRef}>
          <span className={HeaderStyles.profileIcon}>👤</span>
          <span className={HeaderStyles.profileStatus}>
            {isGuest ? 'Guest Mode' : `${username}`}
          </span>
          <span className={HeaderStyles.dropdownArrow}>▼</span>
          {profileDropdown && (
            <div className={HeaderStyles.profileDropdown}>
              {isGuest ? (
                <button onClick={handleLoginRedirect}>Login</button>
              ) : (
                <>
                  <button onClick={handleAccountClick}>Account</button>
                  <button onClick={handleBadgesClick}>Badges</button>
                  <button onClick={handleSubmissionsClick}>Submissions</button>
                  <button onClick={handleLogout}>Logout</button>
                </>
              )}
            </div>
          )}
        </div>
      </header>

      {/* Modals */}
      {showAccountModal && <AccountModal closeModal={closeModals} />}
      {showBadgesModal && <BadgesModal closeModal={closeModals} />}
      {showSubmissionsModal && <SubmissionsModal closeModal={closeModals} />}
    </>
  );
};

export default Header;

