import React, { useState, useEffect } from 'react';
import Emotionstyles from './Emotion.module.css';
import referenceImage from './bamboo_forest.webp';

const quizSet1 = [
  {
    type: 'dragAndDrop',
    question: 'Based on the emotion of the given first line, fill the blanks to complete the second line with sympathetic resonance',
    couplets: [
      {
        firstLine: 'Warm sun              fresh grass            white clouds drift',
        secondLine: ['____', 'sky',       '____', 'trees',       '____', 'fogs lift'],
        thirdLine: 'What does             life  leave             when its gone',
        fourthLine: 'Each day             is its                  own small  gift',
        answers: ['blue', 'tall', 'gray'],
      },
      {
        firstLine: 'Dim sun               dry grass             pale cloud drifts',
        secondLine: ['____', 'sky',       '____', 'trees',      '____', 'fog lifts'],
        thirdLine: 'What does             life leave            when its gone?',
        fourthLine: 'Who knows            When we’ve            lost its gifts',
        answers: ['dull', 'bare', 'faint'],
      },
    ],
    options: ['dull', 'blue', 'faint', 'bare',  'tall', 'gray'],
  },
];

const quizSet2 = [
  {
    type: 'matching',
    question: 'Match the natural scene (couplet-1) with their corresponding emotional resonance:',
    couplets: [
      'Soft dusk      falls through     tall thick pines\nCool breeze     slips by      ferns and vines',
      'Dark Night     hangs on      old hunched pines\nShrill breeze      slides by     snake spun vines',
    ],
    emotions: ['anxious', 'peaceful'],
    answers: ['peaceful', 'anxious'],
  },
  {
    type: 'dragAndDropCouplet',
    question: 'Amplifying Emotional Resonance between First (qi & cheng 起， 承) and Second Couplets (zhuan & he 转，合): Match the second couplet (zhuan & he 转， 合) with the first (qi & cheng 起， 承)',
    couplets: [
      {
        firstCouplet: 'Low clouds soak through oak green sieves\nRain slips down their cool spring sleeves',
        correctAnswer: 'If we walk life\'s paths in haste\nOur prints will fade as each leaves',
      },
      {
        firstCouplet: 'Green shoots split buds still so slim\nsmall fronds break through thick bark limb',
        correctAnswer: 'What wakes pulls us back to life\nWhat sleeps weaves us when lights dim',
      },
    ],
    options: [
      'What wakes pulls us back to life\nWhat sleeps weaves us when lights dim',
      'If we walk life\'s paths in haste\nOur prints will fade as each leaves',
    ],
  },
];

const Emotion = () => {
  const [currentQuiz1, setCurrentQuiz1] = useState(0);
  const [currentQuiz2, setCurrentQuiz2] = useState(0);
  const [selectedOptions1, setSelectedOptions1] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [feedback1, setFeedback1] = useState('');
  const [feedback2, setFeedback2] = useState('');
  const [completed1, setCompleted1] = useState(false);
  const [completed2, setCompleted2] = useState(false);
  const [isCorrect1, setIsCorrect1] = useState(false);
  const [isCorrect2, setIsCorrect2] = useState(false);
  const [slideOut1, setSlideOut1] = useState(false);
  const [slideOut2, setSlideOut2] = useState(false);
  const [usedWords, setUsedWords] = useState([]);

  const initializeSelectedOptions = (couplets) => {
    if (!couplets) return [];
    return couplets.flatMap(couplet => (couplet.secondLine ? couplet.secondLine.filter(word => word === '____').map(() => undefined) : []));
  };

  const handleDragStart = (item, event) => {
    event.dataTransfer.setData('text/plain', item);
  };

  const handleDrop = (index, event, setSelectedOptions) => {
    event.preventDefault();
    const item = event.dataTransfer.getData('text');
    setSelectedOptions(prevOptions => {
      const newOptions = [...prevOptions];
      newOptions[index] = item;
      return newOptions;
    });
    setUsedWords(prevUsedWords => [...prevUsedWords, item]); // Mark word as used
  };

  const handleCheckQuizSet1 = () => {
  const currentQuizContent = quizSet1[currentQuiz1];
  let isCorrect = false;

  if (currentQuizContent.type === 'matching') {
    isCorrect = selectedOptions1.every((option) => option === currentQuizContent.options[0].correctAnswer);
  } else if (currentQuizContent.type === 'dragAndDrop') {
    const flattenedAnswers = currentQuizContent.couplets.flatMap(couplet => couplet.answers);
    isCorrect = flattenedAnswers.every((answer, index) => selectedOptions1[index] === answer);
  }

  setIsCorrect1(isCorrect); // Use setIsCorrect1 for Quiz Set 1
  setFeedback1(isCorrect ? 'Correct! Moving to the next quiz.' : 'Try again!');

  // Add the animation and feedback display for quiz set 1 specifically
  const cardElement1 = document.querySelector(`.${Emotionstyles.quizCardSet1}`); // Unique selector for Quiz Set 1
  if (isCorrect) {
    cardElement1.classList.add(Emotionstyles.correct);
    setTimeout(() => {
      setSlideOut1(true);
      setTimeout(() => {
        if (currentQuiz1 + 1 < quizSet1.length) {
          setCurrentQuiz1((prevQuiz) => prevQuiz + 1);
          setSelectedOptions1(initializeSelectedOptions(quizSet1[currentQuiz1 + 1].couplets));
          setFeedback1('');
          setIsCorrect1(false);
          setSlideOut1(false);
          cardElement1.classList.remove(Emotionstyles.correct);
        } else {
          setCompleted1(true);
          setFeedback1('You\'re done with the set 1.');
        }
      }, 500);
    }, 1500); // Ensure this timing matches your animation duration
  } else {
    cardElement1.classList.add(Emotionstyles.incorrectAnimation);
    setTimeout(() => {
      cardElement1.classList.remove(Emotionstyles.incorrectAnimation);
    }, 1000);
  }
};



  // Updated handleCheckQuizSet2 similarly
  const handleCheckQuizSet2 = () => {
    const currentQuizContent = quizSet2[currentQuiz2];
    let isCorrect = false;

    if (currentQuizContent.type === 'matching') {
        isCorrect = selectedOptions2.every((option, index) => option === currentQuizContent.answers[index]);
    } else if (currentQuizContent.type === 'dragAndDropCouplet') {
        isCorrect = currentQuizContent.couplets.every((couplet, index) => selectedOptions2[index] === couplet.correctAnswer);
    }

    setIsCorrect2(isCorrect); // Use setIsCorrect2 for Quiz Set 2
    setFeedback2(isCorrect ? 'Correct! Moving to the next quiz.' : 'Try again!');

    const cardElement = document.querySelector(`.${Emotionstyles.quizCard}`);
    if (isCorrect) {
        cardElement.classList.add(Emotionstyles.correct);
        setTimeout(() => {
            setSlideOut2(true);
            setTimeout(() => {
                if (currentQuiz2 + 1 < quizSet2.length) {
                    setCurrentQuiz2((prevQuiz) => prevQuiz + 1);
                    setSelectedOptions2(initializeSelectedOptions(quizSet2[currentQuiz2 + 1].couplets));
                    setFeedback2('');
                    setIsCorrect2(false);
                    setSlideOut2(false);
                    cardElement.classList.remove(Emotionstyles.correct);
                } else {
                    setCompleted2(true);
                    setFeedback2('You\'re done with the set 2.');
                }
            }, 500);
        }, 1500); // Ensure this timing matches your animation duration
    } else {
        cardElement.classList.add(Emotionstyles.incorrectAnimation);
        setTimeout(() => {
            cardElement.classList.remove(Emotionstyles.incorrectAnimation);
        }, 1000);
    }
};



  useEffect(() => {
    setSelectedOptions1(initializeSelectedOptions(quizSet1[currentQuiz1].couplets));
    setUsedWords([]);
  }, [currentQuiz1]);

  useEffect(() => {
    setSelectedOptions2(initializeSelectedOptions(quizSet2[currentQuiz2].couplets));
  }, [currentQuiz2]);

  const currentQuizContent1 = quizSet1[currentQuiz1];
  const currentQuizContent2 = quizSet2[currentQuiz2];


  return (
    <div className={Emotionstyles.container}>
      <h2 className={Emotionstyles.title}>Exploring Emotions in Jueju Poetry: jing/qing Attunement</h2>

      {/* Reference Image Section */}
      <section className={Emotionstyles.imageSection}>
        <img src={referenceImage} alt="Bamboo forest in late autumn" className={Emotionstyles.referenceImage} />
      </section>

      {/* Natural Scene Introduction */}
      <section className={Emotionstyles.explanation}>
        <h3>Describing a Natural Scene （境）</h3>
        <p>We begin with a natural scene couplet:</p>
        <div className={Emotionstyles.coupletContainer}>
          <p>
            Cold wind&nbsp;&nbsp;&nbsp;&nbsp;moves through&nbsp;&nbsp;&nbsp;&nbsp;tall bent reeds<br />
            Late blooms&nbsp;&nbsp;&nbsp;&nbsp;drift to&nbsp;&nbsp;&nbsp;&nbsp;brown cracked seeds
          </p>
        </div>
        <p>
        The couplet offers a relatively objective scene devoid of strong emotional resonances. Now, let's introduce an emotional resonance.
        </p>
      </section>

      {/* Side-by-side Positive and Negative Emotion Sections */}
      <section className={Emotionstyles.emotionComparison}>
        <div className={Emotionstyles.negativeEmotion}>
          <h4>Amplifying resonance between natural scene (境) and a NEGATIVE human emotion (情).</h4>
          <p>
          By substituting objective descriptors with emotionally charged language, the couplet is primed to resonate with negative human emotion in the second couplet.
          </p>
          <div className={Emotionstyles.coupletContainer}>
            {/* Transformation of words with striking out and replacement */}
            <p>
              <span className={Emotionstyles.strikeUnderlineWrapper}>
                <span className={Emotionstyles.strikeThrough}>Cold</span><br/>
                <span className={Emotionstyles.underline}>Shrill</span>
              </span> wind&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className={Emotionstyles.strikeUnderlineWrapper}>
                <span className={Emotionstyles.strikeThrough}>moves</span><br/>
                <span className={Emotionstyles.underline}>whips</span>
              </span> through&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className={Emotionstyles.strikeUnderlineWrapper}>
                <span className={Emotionstyles.strikeThrough}>tall</span><br/>
                <span className={Emotionstyles.underline}>cold</span>
              </span> bent reeds<br />
              <span className={Emotionstyles.strikeUnderlineWrapper}>
                <span className={Emotionstyles.strikeThrough}>Late</span><br/>
                <span className={Emotionstyles.underline}>Old</span>
              </span> blooms&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className={Emotionstyles.strikeUnderlineWrapper}>
                <span className={Emotionstyles.strikeThrough}>drift</span><br/>
                <span className={Emotionstyles.underline}>fall</span>
              </span>to&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className={Emotionstyles.strikeUnderlineWrapper}>
                <span className={Emotionstyles.strikeThrough}>brown</span><br/>
                <span className={Emotionstyles.underline}>dull</span>
              </span>cracked seeds
            </p>
          </div>
          <p>
          Notice that the negative sentiment of the poet’s feelings expressed in the second couplet is now foreshadowed by the negative description of the natural scene depicted in the first couplet. These modifications in the first couplet also amplify the emotional resonance of the third (“turn”) and fourth (“unity”) lines.
          </p>
          <div className={Emotionstyles.coupletContainer}>
            <p>
              Shrill wind&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;whips through&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cold bent reeds<br />
              Old blooms&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;fall to&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;dull cracked seeds<br />
              How long&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;can we&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;hold our breath<br />
              Or dream&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;of years&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;blown like weeds
            </p>
          </div>
        </div>

        <div className={Emotionstyles.positiveEmotion}>
          <h4>Amplifying resonance between natural scene (jing) and a POSITIVE human emotion (qing).</h4>
          <p>
          Now let’s describe the same autumn scene with positive imagery to prepare the reader for a positive human emotion in the second couplet.
          </p>
          <div className={Emotionstyles.coupletContainer}>
          <p>
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>Cold</span><br/>
              <span className={Emotionstyles.underline}>Brisk</span>
            </span> wind&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>moves</span><br/>
              <span className={Emotionstyles.underline}>slips</span>
            </span>through&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>tall</span><br/>
              <span className={Emotionstyles.underline}>deep&nbsp;</span>
            </span> 
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>bent</span><br/>
              <span className={Emotionstyles.underline}> green</span>
            </span> reeds<br />
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>Late</span><br/>
              <span className={Emotionstyles.underline}>Flung</span>
            </span> blooms&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>drift</span><br/>
              <span className={Emotionstyles.underline}>spin</span>
            </span> to&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>brown</span><br/>
              <span className={Emotionstyles.underline}>gold</span>
            </span>cracked seeds
          </p>
        </div>
          <p>
          Now, the same scene in nature becomes a lens through which to express a positive feeling of time, one that wants to be cherished and held on to rather than a feeling of remorse or regret. These modifications amplify the “turn” and “unity” of the third and fourth lines.
          </p>
          <div className={Emotionstyles.coupletContainer}>
            <p>
              Brisk wind&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;slips through&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;deep green reeds<br />
              Flung blooms&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;spin to&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;gold spun seeds<br />
              How long&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;can we&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;save this breath?<br />
              Warm winds&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;still blow&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;our winged seeds.
            </p>
          </div>
        </div>
      </section>

      {/* Nondual Attunement Section */}
      <section className={Emotionstyles.nondualSection}>
        <h4>Nondual Attunement Beyond Emotion</h4>
        <p>
        Sometimes Jueju poets allow nature to shape our emotions rather than letting our emotions shape nature. In this case, the focus turns to deeper philosophical questions about reality.
        </p>
        <div className={Emotionstyles.coupletContainer}>
          <p>
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>Cold</span><br/>
              <span className={Emotionstyles.underline}>Cool</span>
            </span> wind&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>moves</span><br/>
              <span className={Emotionstyles.underline}>slips</span>
            </span> through&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>tall</span><br/>
              <span className={Emotionstyles.underline}>green</span>
            </span> &nbsp;
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>bent</span><br/>
              <span className={Emotionstyles.underline}>reed</span><br />
            </span>&nbsp;
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>reeds</span><br/>
              <span className={Emotionstyles.underline}>seas</span><br />
            </span>
            <br></br>

            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>Late</span><br/>
              <span className={Emotionstyles.underline}>Pale</span>
            </span> blooms&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>drift</span><br/>
              <span className={Emotionstyles.underline}>flow</span>
            </span> &nbsp;
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>to</span><br/>
              <span className={Emotionstyles.underline}>on</span>
            </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>brown</span><br/>
              <span className={Emotionstyles.underline}>soft</span>
            </span>
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>cracked</span><br/>
              <span className={Emotionstyles.underline}>seeds</span>
            </span>
            <span className={Emotionstyles.strikeUnderlineWrapper}>
              <span className={Emotionstyles.strikeThrough}>seeds</span><br/>
              <span className={Emotionstyles.underline}>breeze</span>
            </span>

          </p>
        </div>
        <div className={Emotionstyles.coupletContainer}>
          <p>
            Cool wind&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;slips through&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;green reed seas<br />
            Pale blooms&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;flow on&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;soft blown breeze<br />
            Who hears&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;or feels&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;that which is<br />
            No ears&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;no hands&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;no self sees
          </p>
        </div>
      </section>

    
      {/* Quiz Section for Set 2 */}
      {currentQuizContent2 && !completed2 && (
        <section className={Emotionstyles.exercise}>
          <h3>{currentQuizContent2.question}</h3>
          {currentQuizContent2.type === 'matching' && (
            <>
            <div className={`${Emotionstyles.quizCard} ${isCorrect2 ? (slideOut2 ? Emotionstyles.slideOut : '') : ''}`}>
              <div className={Emotionstyles.coupletsContainer}>
                {currentQuizContent2.couplets.map((couplet, index) => (
                  <div key={index} className={Emotionstyles.couplet}>
                    <p>{couplet.split('\n')[0]}</p>
                    <p>{couplet.split('\n')[1]}</p>
                    <div
                      className={Emotionstyles.dropBox}
                      onDrop={(event) => handleDrop(index, event, setSelectedOptions2)}
                      onDragOver={(event) => event.preventDefault()}
                    >
                      {selectedOptions2[index] || 'Drop emotion here'}
                    </div>
                  </div>
                ))}
              </div>
              <div className={Emotionstyles.optionsContainer}>
                {currentQuizContent2.emotions.map((emotion, index) => (
                  <div
                    key={index}
                    className={Emotionstyles.emotion}
                    draggable
                    onDragStart={(event) => handleDragStart(emotion, event)}
                  >
                    {emotion}
                  </div>
                ))}
              </div>
              <button onClick={handleCheckQuizSet2} className={Emotionstyles.checkButton}>Check</button>
              <div className={Emotionstyles.feedback}>{feedback2}</div>
            </div>
            </>
          )}
          {currentQuizContent2.type === 'dragAndDropCouplet' && (
            <div className={`${Emotionstyles.quizCard} ${isCorrect2 ? (slideOut2 ? Emotionstyles.slideOut : '') : ''}`}>
              <div className={Emotionstyles.coupletsContainer}>
                {currentQuizContent2.couplets.map((couplet, index) => (
                  <div key={index} className={Emotionstyles.couplet}>
                    <p>{couplet.firstCouplet.split('\n').map((line, lineIndex) => (
                      <React.Fragment key={lineIndex}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}</p>
                    <div
                      className={Emotionstyles.dropBoxCouplet}
                      onDrop={(event) => handleDrop(index, event, setSelectedOptions2)}
                      onDragOver={(event) => event.preventDefault()}
                    >
                      {selectedOptions2[index] || 'Drop couplet here'}
                    </div>
                  </div>
                ))}
              </div>
              <div className={Emotionstyles.optionsContainer}>
                {currentQuizContent2.options.map((option, index) => (
                  <div
                    key={index}
                    className={Emotionstyles.coupletOption}
                    draggable
                    onDragStart={(event) => handleDragStart(option, event)}
                  >
                    {option.split('\n').map((line, lineIndex) => (
                      <React.Fragment key={lineIndex}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                ))}
              </div>
              <button onClick={handleCheckQuizSet2} className={Emotionstyles.checkButton}>Check</button>
              <div className={Emotionstyles.feedback}>{feedback2}</div>
            </div>
          )}
        </section>
      )}

      {completed2 && (
        <div className={Emotionstyles.exercise}>
          <h3>Quiz Set Completed!</h3>
          <div className={Emotionstyles.feedback}>You're done with this quiz set.</div>
        </div>
      )}

      {/* Quiz Section for Set 1 */}
      {currentQuizContent1 && !completed1 && (
        <section className={Emotionstyles.exercise}>
          <h3>{currentQuizContent1.question}</h3>
          {currentQuizContent1.type === 'dragAndDrop' && (
            <>
            <div className={`${Emotionstyles.quizCard} ${isCorrect1 ? (slideOut1 ? Emotionstyles.slideOut : '') : ''}`}>
              <div className={Emotionstyles.coupletsContainer}>
                {currentQuizContent1.couplets.map((couplet, coupletIndex) => {
                  const blankIndexes = couplet.secondLine
                    .map((word, index) => (word === '____' ? index : null))
                    .filter(index => index !== null);
                  return (
                    <div key={coupletIndex} className={Emotionstyles.couplet}>
                      <div className={Emotionstyles.firstLine}>{couplet.firstLine}</div>
                      <div className={Emotionstyles.secondLine}>
                        {couplet.secondLine.map((word, index) => (
                          <span key={index} className={word === '____' ? Emotionstyles.dropBox : ''}>
                            {word === '____' ? (
                              <span
                                className={Emotionstyles.dropBox}
                                onDrop={(event) => handleDrop(
                                  coupletIndex * blankIndexes.length + blankIndexes.indexOf(index),
                                  event,
                                  setSelectedOptions1
                                )}
                                onDragOver={(event) => event.preventDefault()}
                              >
                                {selectedOptions1[coupletIndex * blankIndexes.length + blankIndexes.indexOf(index)] || '____'}
                              </span>
                            ) : (
                              word
                            )}
                          </span>
                        ))}
                      </div>
                      <div className={Emotionstyles.thirdLine}>{couplet.thirdLine}</div>
                      <div className={Emotionstyles.fourthLine}>{couplet.fourthLine}</div>
                    </div>
                  );
                })}
              </div>
              <div className={Emotionstyles.poolContainer}>
                <div className={Emotionstyles.poolTitle}>POOL of words here</div>
                <div className={Emotionstyles.optionsContainer}>
                  {currentQuizContent1.options.map((option, index) => (
                    <div
                      key={index}
                      className={`${Emotionstyles.emotion} ${usedWords.includes(option) ? Emotionstyles.usedWord : ''}`}
                      draggable={!usedWords.includes(option)} // Disable drag for used words
                      onDragStart={(event) => handleDragStart(option, event)}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              </div>
              <button onClick={handleCheckQuizSet1} className={Emotionstyles.checkButton}>Check</button>
              <div className={Emotionstyles.feedback}>{feedback1}</div>
            </div>
          </>
        )}
      </section>
      )}

      {completed1 && (
        <div className={Emotionstyles.exercise}>
          <h3>Quiz Set Completed!</h3>
          <div className={Emotionstyles.feedback}>You're done with this quiz set.</div>
        </div>
      )}
    </div>
  );
};

export default Emotion;
