import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginAdmin } from '../api';
import AdminStyles from './AdminLogin.module.css';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await loginAdmin({ email, password });
      localStorage.setItem('adminToken', response.access_token); // Ensure token is correctly stored
      navigate('/admin/dashboard');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className={AdminStyles.adminFormContainer}>
      <div className={AdminStyles.center}>
        <h2 className={AdminStyles.formTitle}>Admin Login</h2>
        {error && <p className={AdminStyles.error}>{error}</p>}
        <form className={AdminStyles.formHolder} onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={AdminStyles.input}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={AdminStyles.input}
            required
          />
          <button type="submit" className={AdminStyles.submitBtn}>Login</button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
