import React, { useState, useEffect } from 'react';
import modalStyles from './Modal.module.css';
const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

const SubmissionsModal = ({ closeModal }) => {
  const [submissions, setSubmissions] = useState([]);
  const [expandedSubmission, setExpandedSubmission] = useState(null);

  useEffect(() => {
    const fetchUserSubmissions = async () => {
      try {
        const response = await fetch(`${API_URL}/user/submissions`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();

        if (Array.isArray(data)) {
          setSubmissions(data);
        } else {
          setSubmissions([]);
        }
      } catch (error) {
        console.error('Error fetching submissions:', error);
        setSubmissions([]);
      }
    };

    fetchUserSubmissions();
  }, []);

  const renderTextWithLineBreaks = (text) => {
    if (!text) {
      return <p>No content available</p>; // Handle undefined or null text
    }

    const lines = text.split('\n');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== lines.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const handleReadMoreClick = (index) => {
    setExpandedSubmission(expandedSubmission === index ? null : index);
  };

  const getFeedback = (submission, index) => {
    if (submission.submission_type === 'manual') {
      if (submission.status === 'finished' && submission.feedback) {
        return submission.feedback;
      }
      return <span className={modalStyles.pending}>Pending</span>;
    } else if (submission.submission_type === 'ai' && submission.model_assessment) {
      if (submission.model_assessment.length > 100 && expandedSubmission !== index) {
        return (
          <>
            {submission.model_assessment.substring(0, 100)}...
            <span
              className={modalStyles.readMore}
              onClick={() => handleReadMoreClick(index)}
            >
              Read More
            </span>
          </>
        );
      } else {
        return submission.model_assessment;
      }
    } else {
      return 'No feedback available';
    }
  };

  const categorizedSubmissions = {
    Tongsheng: [],
    Xicuai: [],
    Juren: [],
    Gongshi: [],
  };

  // Categorize submissions based on the submission level
  submissions.forEach((submission) => {
    if (categorizedSubmissions[submission.submission_level]) {
      categorizedSubmissions[submission.submission_level].push(submission);
    }
  });

  const renderSubmissionsByLevel = (level) => {
    const levelSubmissions = categorizedSubmissions[level] || [];

    if (levelSubmissions.length === 0) {
      return <p>No submissions for {level} level.</p>;
    }

    return levelSubmissions.map((submission, index) => (
      <div key={index} className={modalStyles.submissionItem}>
        <p className={modalStyles.submissionTitle}><strong>{submission.couplet ? 'Couplet' : 'Poem'}:</strong></p>
        <p className={modalStyles.coupletText}>
          {submission.couplet ? renderTextWithLineBreaks(submission.couplet) : renderTextWithLineBreaks(submission.poem)}
        </p>
        <p><strong>Feedback:</strong> {getFeedback(submission, index)}</p>
        <p><strong>Type:</strong> {submission.submission_type}</p>
      </div>
    ));
  };

  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modalContent}>
        <span className={modalStyles.closeButton} onClick={closeModal}>&times;</span>
        <h2 className={modalStyles.title}>Your Submissions</h2>

        <h3>Tongsheng Level</h3>
        {renderSubmissionsByLevel('Tongsheng')}

        <h3>Xicuai Level</h3>
        {renderSubmissionsByLevel('Xicuai')}

        <h3>Juren Level</h3>
        {renderSubmissionsByLevel('Juren')}

        <h3>Gongshi Level</h3>
        {renderSubmissionsByLevel('Gongshi')}

        <button onClick={closeModal} className={modalStyles.closeModalButton}>Close</button>
      </div>
    </div>
  );
};

export default SubmissionsModal;

