import React, { useState, useRef, useEffect} from 'react';
import Grammarstyles from './Grammar.module.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = {
  UNIT: 'unit',
};

const Blank = ({ blankKey, text, handleBlankDrop }) => {
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData('text'));
    handleBlankDrop(data, blankKey);
  };

  const isThreeWordBox = blankKey === "blank2"; // Check if it's the second blank

  return (
    <div className={Grammarstyles.blankContainer}>
      <div
        className={`${Grammarstyles.dropBox} ${isThreeWordBox ? Grammarstyles.threeWordDropBox : ''}`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <span className={Grammarstyles.blank}>
          {text || '____'}
        </span>
      </div>
    </div>
  );
};

const DraggableUnit = ({ id, text, onDragStart }) => {
  return (
    <div
      className={Grammarstyles.unitContainer}
      draggable
      onDragStart={(e) => onDragStart(e, id, text)}
    >
      {text}
    </div>
  );
};

const MatchingUnit = ({ id, text, type, handleDrop }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.UNIT,
    drop: (item) => handleDrop(item.id, id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.UNIT,
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={drop} className={`${Grammarstyles.matchingUnit} ${type}`}>
      {type === 'left' ? <span>{text}</span> : null}
      <div ref={drag} className={`${Grammarstyles.diamond} ${isDragging ? Grammarstyles.dragging : ''}`} id={`dot-${id}`} />
      {type === 'right' ? <span>{text}</span> : null}
      {isOver && canDrop && <div className={Grammarstyles.highlight} />}
    </div>
  );
};

const MatchingSection = () => {
  const [matchesTwoWord, setMatchesTwoWord] = useState([]);
  const [matchesThreeWord, setMatchesThreeWord] = useState([]);
  const [linesTwoWord, setLinesTwoWord] = useState([]);
  const [linesThreeWord, setLinesThreeWord] = useState([]);
  const [feedbackTwoWord, setFeedbackTwoWord] = useState('');
  const [feedbackThreeWord, setFeedbackThreeWord] = useState('');
  const linesContainerRef = useRef(null);

  // Two-word units
  const unitsTwoWordLeft = [
    { id: 'late-sun', text: 'Late Sun' },
    { id: 'fish-swim', text: 'Fish Swim' },
  ];

  const unitsTwoWordRight = [
    { id: 'cranes-fly', text: 'Cranes fly' },
    { id: 'faint-stars', text: 'Faint Stars' },
  ];

  const correctMatchesTwoWord = {
    'late-sun': 'faint-stars',
    'fish-swim': 'cranes-fly',
  };

  // Three-word units
  const unitsThreeWordLeft = [
    { id: 'cold-gray-mist', text: 'Cold Gray mist' },
    { id: 'glows-bright-sky', text: 'Glows bright sky' },
    { id: 'bright-moon-shines', text: 'Bright moon shines' },
    { id: 'through-oak-woods', text: 'Through Oak woods' },
    { id: 'moves-through-trees', text: 'Moves through trees' },
  ];

  const unitsThreeWordRight = [  
    { id: 'stills-cold-night', text: 'Stills cold night' },
    { id: 'in-pine-grove', text: 'In Pine grove' },
    { id: 'white-stars-glow', text: 'White Stars glow' },
    { id: 'slips-past-ferns', text: 'Slips past ferns' },
    { id: 'cool-dark-clouds', text: 'Cool Dark clouds' },
  ];

  const correctMatchesThreeWord = {
    'cold-gray-mist': 'cool-dark-clouds',
    'glows-bright-sky': 'stills-cold-night',
    'through-oak-woods': 'in-pine-grove',
    'moves-through-trees': 'slips-past-ferns',
    'bright-moon-shines': 'white-stars-glow',
  };

  const calculateLinePositions = (matches) => {
    const newLines = matches.map((match) => {
      const from = document.getElementById(`dot-${match.from}`);
      const to = document.getElementById(`dot-${match.to}`);
      if (from && to) {
        const fromRect = from.getBoundingClientRect();
        const toRect = to.getBoundingClientRect();
        const containerRect = linesContainerRef.current.getBoundingClientRect();
        const x1 = fromRect.right - containerRect.left;
        const y1 = fromRect.top + fromRect.height / 2 - containerRect.top;
        const x2 = toRect.left - containerRect.left;
        const y2 = toRect.top + toRect.height / 2 - containerRect.top;
        console.log(`Line coordinates: x1=${x1}, y1=${y1}, x2=${x2}, y2=${y2}`);
        return { x1, y1, x2, y2 };
      }
      return null;
    }).filter(line => line !== null);
    return newLines;
  };

  useEffect(() => {
    setLinesTwoWord(calculateLinePositions(matchesTwoWord));
  }, [matchesTwoWord]);

  useEffect(() => {
    setLinesThreeWord(calculateLinePositions(matchesThreeWord));
  }, [matchesThreeWord]);

  const handleDropTwoWord = (leftId, rightId) => {
    setMatchesTwoWord((prevMatches) => {
      const updatedMatches = prevMatches.filter(match => match.from !== leftId && match.to !== rightId);
      return [...updatedMatches, { from: leftId, to: rightId }];
    });
  };

  const handleDropThreeWord = (leftId, rightId) => {
    setMatchesThreeWord((prevMatches) => {
      const updatedMatches = prevMatches.filter(match => match.from !== leftId && match.to !== rightId);
      return [...updatedMatches, { from: leftId, to: rightId }];
    });
  };

  const verifyMatchesTwoWord = () => {
    if (matchesTwoWord.length !== unitsTwoWordLeft.length) {
      setFeedbackTwoWord('Please match all the units on the left.');
      return;
    }
    const correct = matchesTwoWord.every(
      (match) => correctMatchesTwoWord[match.from] === match.to
    );
    setFeedbackTwoWord(correct ? 'Good job! You have matched them correctly.' : 'Try again.');
  };

  const verifyMatchesThreeWord = () => {
    if (matchesThreeWord.length !== unitsThreeWordLeft.length) {
      setFeedbackThreeWord('Please match all the units on the left.');
      return;
    }
    const correct = matchesThreeWord.every(
      (match) => correctMatchesThreeWord[match.from] === match.to
    );
    setFeedbackThreeWord(correct ? 'Good job! You have matched them correctly.' : 'Try again.');
  };

  const clearMatchesTwoWord = () => {
    setMatchesTwoWord([]);
    setFeedbackTwoWord('');
    setLinesTwoWord([]);
  };

  const clearMatchesThreeWord = () => {
    setMatchesThreeWord([]);
    setFeedbackThreeWord('');
    setLinesThreeWord([]);
  };

  return (
    <div
      className={Grammarstyles.matchingContainer}
      ref={linesContainerRef}
      style={{ position: 'relative' }} // Ensure parent container is positioned relative to handle absolute positioning of SVG
    >
      {/* Two-word units matching section */}
      <div className={Grammarstyles.matchingSection}>
        <h4>Two-word Units</h4>
        <div className={Grammarstyles.matchingUnitsContainer}>
          <div className={Grammarstyles.unitsColumn}>
            {unitsTwoWordLeft.map((unit) => (
              <MatchingUnit key={unit.id} id={unit.id} text={unit.text} type="left" handleDrop={handleDropTwoWord} />
            ))}
          </div>
          <div className={Grammarstyles.unitsColumn}>
            {unitsTwoWordRight.map((unit) => (
              <MatchingUnit key={unit.id} id={unit.id} text={unit.text} type="right" handleDrop={handleDropTwoWord} />
            ))}
          </div>
        </div>
        {/* Render the lines for two-word matches */}
        <svg
          className={Grammarstyles.lines}
          width="100%"
          height="100%"
          style={{ position: "absolute", top: 0, left: 0, backgroundColor: "transparent" }} // Remove background color
        >
          {linesTwoWord.map((line, index) => (
            <line key={index} x1={line.x1} y1={line.y1} x2={line.x2} y2={line.y2} stroke="black" strokeWidth="2" />
          ))}
        </svg>
        <div className={Grammarstyles.buttons}>
          <button onClick={verifyMatchesTwoWord} className={Grammarstyles.verifyButton}>Check</button>
          <button onClick={clearMatchesTwoWord} className={Grammarstyles.clearButton}>Clear</button>
        </div>
        {feedbackTwoWord && <p className={Grammarstyles.feedback}>{feedbackTwoWord}</p>}
      </div>

      {/* Three-word units matching section */}
      <div className={Grammarstyles.matchingSection}>
        <h4>Three-word Units</h4>
        <div className={Grammarstyles.matchingUnitsContainer}>
          <div className={Grammarstyles.unitsColumn}>
            {unitsThreeWordLeft.map((unit) => (
              <MatchingUnit key={unit.id} id={unit.id} text={unit.text} type="left" handleDrop={handleDropThreeWord} />
            ))}
          </div>
          <div className={Grammarstyles.unitsColumn}>
            {unitsThreeWordRight.map((unit) => (
              <MatchingUnit key={unit.id} id={unit.id} text={unit.text} type="right" handleDrop={handleDropThreeWord} />
            ))}
          </div>
        </div>
        {/* Render the lines for three-word matches */}
        <svg
          className={Grammarstyles.lines}
          width="100%"
          height="100%"
          style={{ position: "absolute", top: 0, left: 0, backgroundColor: "transparent" }} // Remove background color
        >
          {linesThreeWord.map((line, index) => (
            <line key={index} x1={line.x1} y1={line.y1} x2={line.x2} y2={line.y2} stroke="black" strokeWidth="2" />
          ))}
        </svg>
        <div className={Grammarstyles.buttons}>
          <button onClick={verifyMatchesThreeWord} className={Grammarstyles.verifyButton}>Check</button>
          <button onClick={clearMatchesThreeWord} className={Grammarstyles.clearButton}>Clear</button>
        </div>
        {feedbackThreeWord && <p className={Grammarstyles.feedback}>{feedbackThreeWord}</p>}
      </div>
    </div>
  );
};

const QuizCard = ({ question, options, correctAnswer, handleComplete }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);

  const handleOptionClick = (index) => {
    setSelectedOption(index);
  };

  const verifyAnswers = () => {
    if (selectedOption === correctAnswer) {
      setFeedback('Correct answer!');
      setIsCorrect(true);
      setTimeout(() => {
        const cardElement = document.querySelector(`.${Grammarstyles.quizCard}`);
        if (cardElement) {
          cardElement.classList.add(Grammarstyles.slideOut);
        }
        setTimeout(() => {
          handleComplete();
          setSelectedOption(null);
          setFeedback('');
          setIsCorrect(false);
          if (cardElement) {
            cardElement.classList.remove(Grammarstyles.slideOut);
          }
        }, 500);
      }, 1000);
    } else {
      setFeedback('Incorrect answer. Try again.');
      const cardElement = document.querySelector(`.${Grammarstyles.quizCard}`);
      if (cardElement) {
        cardElement.classList.add(Grammarstyles.shake);
      }
      setTimeout(() => {
        if (cardElement) {
          cardElement.classList.remove(Grammarstyles.shake);
        }
      }, 500);
      setSelectedOption(null);
    }
  };

  return (
    <div className={`${Grammarstyles.quizCard} ${isCorrect ? Grammarstyles.correct : ''}`}>
      <h3>{question}</h3>
      <div className={Grammarstyles.options}>
        {options.map((option, index) => (
          <div
            key={index}
            className={`${Grammarstyles.option} ${selectedOption === index ? Grammarstyles.selected : ''}`}
            onClick={() => handleOptionClick(index)}
          >
            {option}
          </div>
        ))}
      </div>
      <div className={Grammarstyles.buttons}>
        <button onClick={verifyAnswers} className={Grammarstyles.verifyButton}>Check</button>
      </div>
      {feedback && <p className={Grammarstyles.feedback}>{feedback}</p>}
    </div>
  );
};

const FillInTheBlanksQuiz = ({ handleComplete }) => {
  const initialBlanks = {
    blank1: '',
    blank2: '',
    blank3: '',
  };

  const correctAnswers = {
    blank1: ['light fog'],
    blank2: ['two creeks blend'],
    blank3: ['stars glow'],
  };

  const [blanks, setBlanks] = useState(initialBlanks);
  const [feedback, setFeedback] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);

  const units = [
    'light fog', 'as if', 'two creeks blend', 'shine on', 'stars glow', 'sweet cold winds', 'through dark night'
  ]

  const handleBlankDrop = (item, blankKey) => {
    setBlanks((prevBlanks) => ({ ...prevBlanks, [blankKey]: item.text }));
  };

  const handleDragStart = (e, id, text) => {
    e.dataTransfer.setData('text', JSON.stringify({ id, text }));
  };

  const verifyAnswers = () => {
    const correct = Object.keys(blanks).every(
      (blankKey) => correctAnswers[blankKey].includes(blanks[blankKey])
    );

    setFeedback(correct ? 'All answers are correct!' : 'Some answers are incorrect. Try again.');
    setIsCorrect(correct);

    if (correct) {
      setTimeout(() => {
        const cardElement = document.querySelector(`.${Grammarstyles.quizCard}`);
        if (cardElement) {
          cardElement.classList.add(Grammarstyles.slideOut);
        }
        setTimeout(() => {
          handleComplete();
          setBlanks(initialBlanks);
          setFeedback('');
          setIsCorrect(false);
          if (cardElement) {
            cardElement.classList.remove(Grammarstyles.slideOut);
          }
        }, 500);
      }, 1000);
    }
  };

  return (
    <div className={`${Grammarstyles.quizCard} ${isCorrect ? Grammarstyles.correct : ''}`}>
      <h3>Fill in the Blanks</h3>
      <p>
        <Blank blankKey="blank1" text={blanks.blank1} handleBlankDrop={handleBlankDrop} /> moon shines <Blank blankKey="blank2" text={blanks.blank2} handleBlankDrop={handleBlankDrop} /> <br />
        gray mist <Blank blankKey="blank3" text={blanks.blank3} handleBlankDrop={handleBlankDrop} /> clear streams bend
      </p>
      <div className={Grammarstyles.optionsContainer}>
        {units.map((unit) => (
          <DraggableUnit key={unit} id={unit} text={unit} onDragStart={handleDragStart} />
        ))}
      </div>
      <div className={Grammarstyles.buttons}>
        <button onClick={verifyAnswers} className={Grammarstyles.verifyButton}>Check</button>
        <button onClick={() => setBlanks(initialBlanks)} className={Grammarstyles.clearButton}>Clear</button>
      </div>
      {feedback && <p className={Grammarstyles.feedback}>{feedback}</p>}
    </div>
  );
};

const BuildLineQuiz = ({ handleComplete }) => {
  const correctAnswers = [
    ['cold stream', 'deep lake', 'cat fish sleep'],
    ['deep lake', 'cold stream', 'cat fish sleep'],
  ];

  const [units] = useState([
    'deep lake', 'shores meet', 'cold stream', 'wind glides', 'cat fish sleep', 'dry crisp leaves'
  ]);

  const [line, setLine] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);

  const handleUnitDrop = (unit, index) => {
    const newLine = [...line];
    newLine[index] = unit;
    setLine(newLine);
  };

  const handleDragStart = (e, id, text) => {
    e.dataTransfer.setData('text', JSON.stringify({ id, text }));
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData('text'));
    handleUnitDrop(data.text, index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const verifyAnswers = () => {
    const correct = correctAnswers.some(
      (answer) => JSON.stringify(answer) === JSON.stringify(line)
    );

    setFeedback(correct ? 'All answers are correct!' : 'Some answers are incorrect. Try again.');
    setIsCorrect(correct);

    if (correct) {
      setTimeout(() => {
        const cardElement = document.querySelector(`.${Grammarstyles.quizCard}`);
        if (cardElement) {
          cardElement.classList.add(Grammarstyles.slideOut);
        }
        setTimeout(() => {
          handleComplete();
          setLine([]);
          setFeedback('');
          setIsCorrect(false);
          if (cardElement) {
            cardElement.classList.remove(Grammarstyles.slideOut);
          }
        }, 500);
      }, 1000);
    } else {
      setLine([]);
    }
  };

  return (
    <div className={`${Grammarstyles.quizCard} ${isCorrect ? Grammarstyles.correct : ''}`}>
      <h3>Build the Second Line</h3>
      <p>cool breeze still pond green frogs leap</p>
      <div className={Grammarstyles.dropArea}>
        <div 
          className={Grammarstyles.dropBox} 
          onDrop={(e) => handleDrop(e, 0)} 
          onDragOver={handleDragOver}>
          {line[0] && <span className={Grammarstyles.word}>{line[0]}</span>}
        </div>
        <div 
          className={Grammarstyles.dropBox} 
          onDrop={(e) => handleDrop(e, 1)} 
          onDragOver={handleDragOver}>
          {line[1] && <span className={Grammarstyles.word}>{line[1]}</span>}
        </div>
        <div 
          className={`${Grammarstyles.dropBox} ${Grammarstyles.threeWordDropBox}`} 
          onDrop={(e) => handleDrop(e, 2)} 
          onDragOver={handleDragOver}>
          {line[2] && <span className={Grammarstyles.word}>{line[2]}</span>}
        </div>
      </div>
      <div className={Grammarstyles.optionsContainer}>
        {units.map((unit) => (
          <DraggableUnit key={unit} id={unit} text={unit} onDragStart={handleDragStart} />
        ))}
      </div>
      <div className={Grammarstyles.buttons}>
        <button onClick={verifyAnswers} className={Grammarstyles.verifyButton}>Check</button>
      </div>
      {feedback && <p className={Grammarstyles.feedback}>{feedback}</p>}
    </div>
  );
};


const QuizSet = () => {
  const [currentQuiz, setCurrentQuiz] = useState(0);

  const handleComplete = () => {
    setCurrentQuiz((prevQuiz) => prevQuiz + 1);
  };

  return (
    <div className={Grammarstyles.quizSet}>
      {currentQuiz === 0 && (
        <QuizCard
          question="1. Choose a two-word unit that has the same sequence of parts of speech as 'cool breeze'"
          options={['A. Stream flows', 'B. Walk through', 'C. The path', 'D. Soft clouds']}
          correctAnswer={3}
          handleComplete={handleComplete}
        />
      )}
      {currentQuiz === 1 && (
        <QuizCard
          question="2. Choose a two-word unit that has the same sequence of parts of speech as 'walk in'"
          options={['A. pine trees', 'B. bent grass', 'C. slip through', 'D. thick mist']}
          correctAnswer={2}
          handleComplete={handleComplete}
        />
      )}
      {currentQuiz === 2 && (
        <QuizCard
          question="3. Choose a three-word unit that has the same sequence of parts of speech as 'tall aged oak'"
          options={['A. streams pass slow', 'B. new snow falls', 'C. smooth wet stones', 'D. slow as tides']}
          correctAnswer={2}
          handleComplete={handleComplete}
        />
      )}
      {currentQuiz === 3 && <FillInTheBlanksQuiz handleComplete={handleComplete} />}
      {currentQuiz === 4 && <BuildLineQuiz handleComplete={handleComplete} />}
      {currentQuiz > 4 && <p>All quizzes completed!</p>}
    </div>
  );
};

const Grammar = () => {
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className={Grammarstyles.grammarContainer}>
          <h2 className={Grammarstyles.header}>Grammatical Rhyming/Parallelism in Regulated Poetry</h2>
          <p className={Grammarstyles.intro}>
          In regulated Chinese poetry, grammar plays a crucial role. Being able to identify a word's part of speech (noun, verb, adjective etc.) and to sequence them in parallel ways between lines is an essential part of first two lines of regulated poetry. This section covers essential grammatical rules that govern regulated poetry.
          </p>
          <div className={Grammarstyles.card}>
            <h3 className={Grammarstyles.subHeader}>Introduction to Grammatical Rhyming/Parallelism</h3>
            <p>
            Grammatical Rhyming/parallelism is an essential aspect of regulated jueju. Poets must organize the first couplet according to the rule of grammatical parallelism by matching the sequence of parts of speech in the first line with the corresponding units in the second line.
            </p>
            <h4 className={Grammarstyles.exampleHeader}>Example of Grammatical Rhyming/Parallelism:</h4>
            <p className={Grammarstyles.exampleText}>
              Sun sets&nbsp;&nbsp;&nbsp;&nbsp; thin reeds&nbsp;&nbsp;&nbsp;&nbsp; dark spills cold<br />
              Haze grows&nbsp;&nbsp;&nbsp;&nbsp; thick wheat&nbsp;&nbsp;&nbsp;&nbsp; dusk spreads gold
            </p>
            <p>
              In this couplet, notice that the grammar patterns of corresponding units in each line are matching:
            </p>
            <ul className={Grammarstyles.exampleList}>
              <li><strong>Sun sets</strong> (noun + verb) and <strong>Haze grows</strong> (noun + verb)</li>
              <li><strong>thin reeds</strong> (adjective + noun) and <strong>thick wheat</strong> (adjective + noun)</li>
              <li><strong>Dark spills cold</strong> (noun + verb + adverb) and <strong>Dusk spreads gold</strong> (noun + verb + adverb)</li>
            </ul>
            <p>
            Maintaining this parallelism in the units makes the couplet one step closer to building a regulated couplet. Remember that parallelism is only required for the first couplet of jueju; the second couplet needn't be parallel in this way.
            </p>
          </div>
          <h3 className={Grammarstyles.subHeader}>Match the Units according to their Grammatical Rhyme/parallelism</h3>
          <MatchingSection />
        </div>
      </DndProvider>
      <div className={Grammarstyles.quizSection}>
        <h3 className={Grammarstyles.subHeader}>Quiz Set</h3>
        <QuizSet />
      </div>
    </>
  );
};

export default Grammar;
