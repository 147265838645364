import React, { useState, useEffect, useRef } from 'react';
import GongshiStyles from './Gongshi.module.css';
const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

const Gongshi = () => {
  const [isFiveWordLine, setIsFiveWordLine] = useState(true);
  const [poemUnits, setPoemUnits] = useState(Array(20).fill(''));
  const [submittedPoem, setSubmittedPoem] = useState('');
  const [assessmentResult, setAssessmentResult] = useState('');
  const [modelAssessment, setModelAssessment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [placeholderText, setPlaceholderText] = useState('');
  const [showBadgePrompt, setShowBadgePrompt] = useState(false);
  const [currentLevel, setCurrentLevel] = useState('');
  const [submissions, setSubmissions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasGongshiBadge, setHasGongshiBadge] = useState(false);
  const videoRef = useRef(null);
  const [pingZeFeedback, setPingZeFeedback] = useState('');
  const placeholderContent = 'Type here...';

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);

    if (token) {
      fetchUserLevel(token);
    }
  }, []);

  useEffect(() => {
    if (!poemUnits[0]) {
      let index = 0;
      const interval = setInterval(() => {
        setPlaceholderText(placeholderContent.substring(0, index));
        index = (index + 1) % (placeholderContent.length + 1);
      }, 200);
      return () => clearInterval(interval);
    }
  }, [poemUnits]);

  useEffect(() => {
    if (showBadgePrompt && videoRef.current) {
      videoRef.current.play();
    }
  }, [showBadgePrompt]);

  const handleToggle = () => {
    setIsFiveWordLine(!isFiveWordLine);
    setPoemUnits(Array(!isFiveWordLine ? 20 : 28).fill(''));
  };

  const handleInputChange = (index, value) => {
    const newPoemUnits = [...poemUnits];
    newPoemUnits[index] = value;
    setPoemUnits(newPoemUnits);
  };

  const renderPoemInputs = () => {
    const lineUnits = isFiveWordLine ? 5 : 7;
    const totalLines = 4;

    const lineBreaks = [];
    for (let i = 0; i < totalLines; i++) {
      const units = [];
      for (let j = 0; j < lineUnits; j++) {
        units.push(
          <input
            key={i * lineUnits + j}
            type="text"
            value={poemUnits[i * lineUnits + j]}
            onChange={(e) => handleInputChange(i * lineUnits + j, e.target.value)}
            className={GongshiStyles.unitBox}
            placeholder={j === 0 && i === 0 ? placeholderText : ''}
            onFocus={() => j === 0 && i === 0 && setPlaceholderText('')}
          />
        );
        if (isFiveWordLine && j === 1) {
          units.push(<div key={`gap-${i}-${j}`} className={GongshiStyles.gap}></div>);
        } else if (!isFiveWordLine && (j === 1 || j === 3)) {
          units.push(<div key={`gap-${i}-${j}`} className={GongshiStyles.gap}></div>);
        }
      }
      lineBreaks.push(
        <div key={`line-${i}`} className={GongshiStyles.line}>
          {units}
        </div>
      );
    }
    return lineBreaks;
  };

  const formatPoem = () => {
    const lineUnits = isFiveWordLine ? 5 : 7;
    const totalLines = 4;
    let poem = '';

    for (let i = 0; i < totalLines; i++) {
      const line = poemUnits.slice(i * lineUnits, (i + 1) * lineUnits).join(' ');
      poem += line + '\n';
    }

    return poem.trim();
  };

  const handleSubmission = async (type) => {
    const poem = submittedPoem.trim() || formatPoem();
  
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const endpoint = type === 'manual' ? 'manual-poem-submission' : 'assess-poem';
      const payload = {
        poem: poem,
        user_level: currentLevel,
        level: 'Gongshi', // Specify the level as Gongshi
      };
  
      const token = localStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` }),
      };
  
      const response = await fetch(`${API_URL}/${endpoint}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
  
      if (response.ok && data.is_valid) {
        if (type === 'manual') {
          setSuccessMessage(data.message || 'Poem submitted for manual review.');
        } else {
          setAssessmentResult(data.model_assessment); // Display AI feedback
          setPingZeFeedback(data.ping_ze_feedback || 'No Ping/Ze feedback available'); // Capture Ping/Ze feedback
          if (isLoggedIn && !hasGongshiBadge) {
            setShowBadgePrompt(true);
          }
        }
      } else {
        setErrorMessage(data.feedback || 'Failed to submit poem.');
        setAssessmentResult('');
        setPingZeFeedback('');
      }
    } catch (error) {
      console.error('Error submitting poem:', error);
      setErrorMessage('Error submitting poem.');
      setPingZeFeedback('');
    }
    setIsLoading(false);
  };
  
  
  
  const fetchUserLevel = async (token) => {
    try {
      const response = await fetch(`${API_URL}/user/current-level`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setCurrentLevel(data.level);
  
      if (data.achievements && data.achievements.Gongshi) {
        setHasGongshiBadge(true);
      }
    } catch (error) {
      console.error('Error fetching user level:', error);
    }
  };
  
  const openSubmissionsModal = () => {
    setIsModalOpen(true);
    const token = localStorage.getItem('token');
    if (token) {
      fetchSubmissions(token);
    }
  };

  const handleBadgeSubmission = async (passed) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found. Please log in again.');
      }

      if (passed) {
        const response = await fetch(`${API_URL}/user/achievements/gongshi`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ level: 'Gongshi' }),
        });

        if (response.ok) {
          alert('Congratulations! You have achieved the Gongshi level.');
          setCurrentLevel('Gongshi');
          setHasGongshiBadge(true);
          window.location.reload();
        } else {
          console.error('Failed to update user level.');
        }
      } else {
        setErrorMessage('Try again and make your poem better based on the assessment feedback.');
      }
    } catch (error) {
      console.error('Error updating user level:', error);
    }
    setShowBadgePrompt(false);
  };

  const fetchSubmissions = async (token) => {
    try {
      const response = await fetch(`${API_URL}/submissions/?level=Gongshi`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (Array.isArray(data)) {
        setSubmissions(data);
      } else {
        setSubmissions([]);
      }
    } catch (error) {
      console.error('Error fetching submissions:', error);
      setSubmissions([]);
    }
  };

  const handleReadMore = (submission) => {
    setSelectedSubmission(submission);
  };

  const renderPoemWithLineBreaks = (poem) => {
    if (!poem) {
      return <span>No poem provided.</span>;
    }

    const lines = poem.split('\n');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== lines.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const renderSubmissionsModal = () => (
    <div className={`${GongshiStyles.modal} ${isModalOpen ? GongshiStyles.modalOpen : ''}`}>
      <div className={GongshiStyles.modalContent}>
        <span className={GongshiStyles.closeButton} onClick={() => setIsModalOpen(false)}>&times;</span>
        <h3>Your Submissions</h3>
        {Array.isArray(submissions) && submissions.length === 0 ? (
          <p>No submissions yet.</p>
        ) : (
          <div className={GongshiStyles.submissionsContainer}>
            {Array.isArray(submissions) && submissions.map((submission, index) => (
              <div key={index} className={GongshiStyles.submissionItem}>
                <p><strong>Poem:</strong> {renderPoemWithLineBreaks(submission.poem)}</p>
                <p>
                  <strong>Feedback:</strong>
                  {submission.submission_type === 'manual' && submission.status === 'finished' ? (
                    submission.feedback || 'No feedback available'
                  ) : submission.submission_type === 'manual' && submission.status !== 'finished' ? (
                    <span className={GongshiStyles.pending}>Pending</span>
                  ) : submission.model_assessment.length > 100 ? (
                    <>
                      {submission.model_assessment.slice(0, 100)}...
                      <span
                        className={GongshiStyles.readMore}
                        onClick={() => handleReadMore(submission)}
                      >
                        Read More
                      </span>
                    </>
                  ) : (
                    submission.model_assessment || 'No feedback available'
                  )}
                </p>
                <p><strong>Type:</strong> {submission.submission_type}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  const renderReadMoreModal = () => (
    selectedSubmission && (
      <div className={`${GongshiStyles.modal} ${GongshiStyles.modalOpen}`} style={{ zIndex: 1100 }}>
        <div className={GongshiStyles.readMoreModalContent}>
          <span className={GongshiStyles.closeButton} onClick={() => setSelectedSubmission(null)}>&times;</span>
          <h3>Submission Details</h3>
          <p className={GongshiStyles.poem}><strong>Poem:</strong> {renderPoemWithLineBreaks(selectedSubmission.poem)}</p>
          <p className={GongshiStyles.feedback}><strong>Feedback:</strong> {selectedSubmission.submission_type === 'ai' ? selectedSubmission.model_assessment : selectedSubmission.feedback || 'No feedback available'}</p>
          <p className={GongshiStyles.type}><strong>Type:</strong> {selectedSubmission.submission_type}</p>
          <p className={GongshiStyles.status}><strong>Status:</strong> {selectedSubmission.status}</p>
          <p className={GongshiStyles.submittedAt}><strong>Submitted At:</strong> {new Date(selectedSubmission.submitted_at).toLocaleString()}</p>
        </div>
      </div>
    )
  );

  const renderManualAssessmentPrompt = () => (
    isLoggedIn && (
      <div className={GongshiStyles.manualAssessmentContainer}>
        <p>If you want a manual assessment for more accuracy or if you disagree with the model, you can make a manual submission and receive feedback after a few days or weeks by the admin (check your submissions to see the feedback).</p>
        <button
          onClick={() => handleSubmission('manual')}
          disabled={isLoading}
          className={GongshiStyles.manualButton}
          title="Your submission will be sent for manual review."
        >
          Submit for Manual Assessment
        </button>
      </div>
    )  
  );

  const renderBadgePrompt = () => (
    <div className={GongshiStyles.badgePrompt}>
      <h4>Did you pass the Gongshi level?</h4>
      <button className={GongshiStyles.button} onClick={() => handleBadgeSubmission(true)}>Yes</button>
      <button className={GongshiStyles.button} onClick={() => handleBadgeSubmission(false)}>No</button>
    </div>
  );

  return (
    <div className={GongshiStyles.container}>
      <button onClick={openSubmissionsModal} className={`${GongshiStyles.openModalButton} ${GongshiStyles.button}`}>
        View Previous Submissions
      </button>
      <h2>Build a Regulated Poem</h2>
      <div className={GongshiStyles.toggleContainer}>
        <span>{isFiveWordLine ? '5-word per line' : '7-word per line'}</span>
        <label className={GongshiStyles.switch}>
          <input type="checkbox" checked={!isFiveWordLine} onChange={handleToggle} />
          <span className={`${GongshiStyles.slider} ${GongshiStyles.round}`}></span>
        </label>
      </div>
      <p>Type in unit by unit:</p>
      {renderPoemInputs()}
      <p>OR</p>
      <p>Drop your whole poem</p>
      <textarea
        placeholder="Enter your poem here..."
        value={submittedPoem}
        onChange={(e) => setSubmittedPoem(e.target.value)}
        className={GongshiStyles.textArea}
      />
      <div className={GongshiStyles.submitButtonsContainer}>
        <button
          onClick={() => handleSubmission('ai')}
          disabled={isLoading}
          className={`${GongshiStyles.aiButton} ${GongshiStyles.button}`}
          title="You will receive an instant AI assessment."
        >
          Submit for AI Assessment
        </button>
      </div>
      {isLoading && <div className={GongshiStyles.loader}></div>}
      {errorMessage && <div className={GongshiStyles.errorMessage}>{errorMessage}</div>}
      {successMessage && <div className={GongshiStyles.successMessage}>{successMessage}</div>}
      {assessmentResult && (
        <div className={GongshiStyles.assessmentResult}>
          {assessmentResult}
          {!hasGongshiBadge && showBadgePrompt && renderBadgePrompt()}
          {renderManualAssessmentPrompt()}
        </div>
      )}
      {modelAssessment && (
        <div className={GongshiStyles.modelAssessment}>
          <h4>Model Assessment</h4>
          <p className={GongshiStyles.generatedText}>{modelAssessment}</p>
        </div>
        
      )}
      {pingZeFeedback && (
          <div className={GongshiStyles.pingZeFeedback}>
            <h4>Ping/Ze Analysis</h4>
            <pre>{pingZeFeedback}</pre>
          </div>
        )}
      {renderSubmissionsModal()}
      {renderReadMoreModal()}
    </div>
  );
};

export default Gongshi;

