import React, { useState, useEffect } from 'react';
import { FaTrophy } from 'react-icons/fa';
import modalStyles from './Modal.module.css';
const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

const BadgesModal = ({ closeModal }) => {
  const [badges, setBadges] = useState({ achieved: {}, available: [] });

  useEffect(() => {
    const fetchUserBadges = async () => {
      try {
        const response = await fetch(`${API_URL}/user/badges`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        setBadges({
          achieved: data.achieved || {},
          available: data.available || [],
        });
      } catch (error) {
        console.error('Error fetching badges:', error);
      }
    };

    fetchUserBadges();
  }, []);

  const achievedBadges = Object.keys(badges.achieved).filter(
    (badge) => badges.achieved[badge]
  );

  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modalContent}>
        <span className={modalStyles.closeButton} onClick={closeModal}>&times;</span>
        <h2 className={modalStyles.title}>Your Badges</h2>
        <div className={modalStyles.badgeSection}>
          <h3>Achieved</h3>
          <ul className={modalStyles.badgeList}>
            {achievedBadges.length > 0 ? (
              achievedBadges.map((badge, index) => (
                <li key={index} className={modalStyles.achievedBadge}>
                  <FaTrophy className={modalStyles.trophyIcon} /> {badge}
                </li>
              ))
            ) : (
              <li>No badges achieved yet.</li>
            )}
          </ul>
        </div>
        <div className={modalStyles.badgeSection}>
          <h3>Available</h3>
          <ul className={modalStyles.badgeList}>
            {badges.available.length > 0 ? (
              badges.available.map((badge, index) => (
                <li
                  key={index}
                  className={
                    achievedBadges.includes(badge)
                      ? modalStyles.achievedBadge
                      : modalStyles.availableBadge
                  }
                >
                  {badge}
                </li>
              ))
            ) : (
              <li>No badges available.</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BadgesModal;

