import React, { useState, useEffect } from 'react';
import Syllablestyles from './Syllable.module.css';
import handIcon from './hand-icon.svg'; // Assuming you have a hand icon SVG

const monosyllables = [
  "Moon", "Sun", "Light", "Bright", "Grass", "Creek", "Pale", "Soft",
  "Red", "Flow", "Glow", "Cracked", "Sky", "Star", "Bat", "Cat", "Tree",
  "Dog", "Fish", "Frog", "Sun", "Moon", "Rain", "Stone", "Leaf", "Wind",
  "Bird", "Nest", "Wave", "Sand", "Storm", "Rock", "Cloud", "Mist", "Ice",
  "Fire", "Heat", "Cold", "Snow", "Dust", "Mud", "Hill", "Peak"
];

const monosyllableQuizzes = [
  { options: ['flower', 'bloom', 'daisy', 'bud'], answer: ['bloom', 'bud'] },
  { options: ['anger', 'rage', 'happy', 'joy'], answer: ['rage', 'joy'] },
  { options: ['mind', 'memory', 'thought', 'forget'], answer: ['mind', 'thought'] },
  { options: ['easy', 'hard', 'bright', 'shiny'], answer: ['hard', 'bright'] },
  { options: ['through', 'above', 'by', 'under'], answer: ['through', 'by'] }
];

const multisyllableQuizzes = [
  { options: ['river', 'stream', 'creek', 'lake'], answer: 'river' },
  { options: ['hill', 'peak', 'mountain', 'cliff'], answer: 'mountain' },
  { options: ['sun', 'moon', 'planet', 'sky'], answer: 'planet' },
  { options: ['forest', 'trees', 'leaves', 'stems'], answer: 'forest' },
  { options: ['beach', 'desert', 'sea', 'farm'], answer: 'desert' }
];

const correctClassification = {
  "Natural Imagery Words": ["sky", "tree", "grass", "sun", "moon"].sort(),
  "Empty Words": ["the", "an", "and", "but", "yet"].sort()
};


const MonosyllablePractice = () => {
  const [userAnswers, setUserAnswers] = useState(Array(monosyllableQuizzes.length).fill([]));
  const [feedbacks, setFeedbacks] = useState(Array(monosyllableQuizzes.length).fill(''));
  const [flipped, setFlipped] = useState(Array(monosyllableQuizzes.length).fill(false));
  const [draggedWord, setDraggedWord] = useState('');
  const [multiAnswers, setMultiAnswers] = useState(Array(multisyllableQuizzes.length).fill(''));
  const [multiFeedbacks, setMultiFeedbacks] = useState(Array(multisyllableQuizzes.length).fill(''));
  const [multiFlipped, setMultiFlipped] = useState(Array(multisyllableQuizzes.length).fill(false));
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showMonosyllableSection, setShowMonosyllableSection] = useState(false);
  const [showHandAnimation, setShowHandAnimation] = useState(true);
  const [classification, setClassification] = useState({
    "Natural Imagery Words": [],
    "Empty Words": []
  });
  const [classificationFeedback, setClassificationFeedback] = useState('');
  const [isClassificationCorrect, setIsClassificationCorrect] = useState(false);
  const [classifyWords, setClassifyWords] = useState([
    "the", "an", "and", "sky", "tree", "grass", "but", "yet", "sun", "moon"
  ]);
  const handleOptionClick = (quizIndex, option) => {
    setUserAnswers(prevAnswers => {
      const newAnswers = [...prevAnswers];
      if (newAnswers[quizIndex].includes(option)) {
        newAnswers[quizIndex] = newAnswers[quizIndex].filter(answer => answer !== option);
      } else {
        newAnswers[quizIndex] = [...newAnswers[quizIndex], option];
      }
      return newAnswers;
    });
  };

  const handleQuizSubmit = (quizIndex) => {
    const correctAnswer = monosyllableQuizzes[quizIndex].answer.sort().join(' ');
    const userAnswer = userAnswers[quizIndex].sort().join(' ');
    if (userAnswer.toLowerCase() === correctAnswer.toLowerCase()) {
      setFeedbacks(prevFeedbacks => {
        const newFeedbacks = [...prevFeedbacks];
        newFeedbacks[quizIndex] = 'Well done! Correct answer';
        return newFeedbacks;
      });
      setFlipped(prevFlipped => {
        const newFlipped = [...prevFlipped];
        newFlipped[quizIndex] = true;
        return newFlipped;
      });
    } else {
      setFeedbacks(prevFeedbacks => {
        const newFeedbacks = [...prevFeedbacks];
        newFeedbacks[quizIndex] = 'Try again!';
        return newFeedbacks;
      });
      setShakeEffect(quizIndex);
    }
  };

  const setShakeEffect = (quizIndex) => {
    const cardElement = document.getElementById(`quizCard-${quizIndex}`);
    if (cardElement) {
      cardElement.classList.add(Syllablestyles.shake);
    }
    setTimeout(() => {
      if (cardElement) {
        cardElement.classList.remove(Syllablestyles.shake);
      }
    }, 500);
  };

  const handleDragStart = (word) => {
    setDraggedWord(word);
  };
  const handleClear = () => {
    // Combine words from both categories
    const wordsToReturn = [...classification["Natural Imagery Words"], ...classification["Empty Words"]];
    
    // Reset classification categories
    setClassification({
      "Natural Imagery Words": [],
      "Empty Words": []
    });
  
    // Add words back to the pool
    setClassifyWords(prevWords => [...prevWords, ...wordsToReturn]);
    
    // Clear feedback
    setClassificationFeedback('');
    setIsClassificationCorrect(false);
  };
  
  const handleDrop = (category, quizIndex) => {
    if (category === "multiSyllable") {
      setMultiAnswers(prevAnswers => {
        const newAnswers = [...prevAnswers];
        newAnswers[quizIndex] = draggedWord;
        return newAnswers;
      });
    } else {
      setClassification(prevClassification => {
        const newClassification = { ...prevClassification };
        Object.keys(newClassification).forEach(key => {
          newClassification[key] = newClassification[key].filter(word => word !== draggedWord);
        });
        newClassification[category].push(draggedWord);
        return newClassification;
      });
      // Remove the dragged word from the pool
    setClassifyWords(prevWords => prevWords.filter(word => word !== draggedWord));
    }
    setDraggedWord('');
  };

  const handleMultiQuizSubmit = (quizIndex) => {
    if (multiAnswers[quizIndex] === multisyllableQuizzes[quizIndex].answer) {
      setMultiFeedbacks(prevFeedbacks => {
        const newFeedbacks = [...prevFeedbacks];
        newFeedbacks[quizIndex] = 'Well done! Correct answer';
        return newFeedbacks;
      });
      setMultiFlipped(prevFlipped => {
        const newFlipped = [...prevFlipped];
        newFlipped[quizIndex] = true;
        return newFlipped;
      });
    } else {
      setMultiFeedbacks(prevFeedbacks => {
        const newFeedbacks = [...prevFeedbacks];
        newFeedbacks[quizIndex] = 'Try again!';
        return newFeedbacks;
      });
      setShakeEffect(quizIndex);
    }
  };

  const handleClassificationCheck = () => {
    const sortedClassification = {
      "Natural Imagery Words": [...classification["Natural Imagery Words"]].sort(),
      "Empty Words": [...classification["Empty Words"]].sort()
    };
    const isCorrect = JSON.stringify(sortedClassification) === JSON.stringify(correctClassification);
    setIsClassificationCorrect(isCorrect);
    setClassificationFeedback(isCorrect ? 'Well done! Correct classification' : 'Try again!');

    // Add shake or scale effect to the card
    const cardElement = document.querySelector(`.${Syllablestyles.classifyQuiz}`);
    if (cardElement) {
      cardElement.classList.add(isCorrect ? Syllablestyles.correct : Syllablestyles.incorrect);
    }
    setTimeout(() => {
      if (cardElement) {
        cardElement.classList.remove(isCorrect ? Syllablestyles.correct : Syllablestyles.incorrect);
      }
    }, 1000);
  };

  const nextWord = () => {
    setCurrentIndex((currentIndex + 1) % monosyllables.length);
  };

  const prevWord = () => {
    setCurrentIndex((currentIndex - 1 + monosyllables.length) % monosyllables.length);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHandAnimation(false);
    }, 20000); // Hide the animation after 20 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={Syllablestyles.container}>
      <section className={Syllablestyles.juejuIntro}>
        <h2>Begin Writing English Jueju</h2>
        <p>To begin writing English Jueju, you must first limit yourself to using monosyllable words only. In Middle Chinese (Chinese during the Tang Dynasty) all words were single-syllable (often referred to as monosyllabic) words, and these became the most fundamental feature of Jueju. </p>
        <ul>
          <li>A single character like 月, represents a single syllable sound (today pronounced yuè in Mandarin) and signifies a single word’s meaning “moon.”</li>
          <li>But many other words today, like “understand” in modern Chinese, include two characters 明白 (pronounced “mingbai” in Mandarin).</li>
          <li>So while in Chinese each character may still have only a single-syllable sound, not all Chinese words today are monosyllabic, but in Classical Chinese, most words were still monosyllabic.</li>
          <li>The interlocking modular system of Classical Chinese poetry was created from this earlier, monosyllabic language system and that is why Jueju poets today must still employ only single-syllable words.</li>
        </ul>
        <p>Luckily, English is second only to Chinese in its number of single-syllable words (over 10,000) with many (roughly 5,000) that are great for poetry composition making it the only widely spoken non-East Asian language words capable of composing unregulated and regulated jueju. Yet before we can learn how to group these single-syllable words into the poetry units we will employ to write jueju, let's review what a single-syllable word is:</p>
      </section>

      <section>
        <button 
          className={Syllablestyles.expandButton}
          onClick={() => setShowMonosyllableSection(!showMonosyllableSection)}
        >
          {showMonosyllableSection ? 'Hide' : 'What are monosyllables?'}
        </button>
        
        {showMonosyllableSection && (
          <div className={Syllablestyles.monosyllableContent}>
            <p>A single syllable word is one that has only one vowel sound like “cat, dog, sing, clock, wave, and “time.” Some single syllable words add a syllable when you add a plural or possessive “s” or “d” while others do not. For instance, the single-syllable “bus” becomes “buses” but the single-syllable “car” stays single-syllable as “cars, “ and “weight” becomes weighted” (a two syllable word) while “live” becomes “lived” and does not add a syllable.</p>
            <br></br>
            <ul>
              <strong>Nouns</strong>: tree, brook, sky<br></br>
              <br></br>
              <strong>Verbs</strong>: flow, fade, rest<br></br>
              <br></br>
              <strong>Adjectives</strong>: wide, blue, cool<br></br>
            </ul>
            <br></br>
            <h3>How to Identify Monosyllables:</h3>
            <ul>
              <li><strong>Count the Vowel Sounds</strong>: If a word has only one vowel sound, it’s a monosyllable (e.g., "bat").</li>
              <li><strong>Clap Method</strong>: Clap once for every distinct vowel in a word. If you can fit only one clap in, it’s monosyllabic (e.g., "fish"), while “banana” takes three claps to say.</li>
              <li><strong>No Breaks</strong>: If the word can’t be divided into smaller parts, it’s very likely a monosyllable (e.g., "tree").</li>
            </ul>
          </div>
        )}
      </section>

      <section className={Syllablestyles.examples}>
        <br></br>
        <h3>Examples:</h3>
        <div className={Syllablestyles.exampleContainer}>
          <button onClick={prevWord} className={Syllablestyles.navButton}>{"<"}</button>
          <div className={Syllablestyles.exampleWords}>
            {monosyllables.slice(currentIndex, currentIndex + 10).map((word, index) => (
              <span key={index} className={Syllablestyles.exampleWord}>{word}</span>
            ))}
          </div>
          <button onClick={nextWord} className={Syllablestyles.navButton}>{">"}</button>
        </div>
      </section>

      <section className={Syllablestyles.practice}>
        <h3>Identify the monosyllables:</h3>
        <p>Select the words you think are monosyllable and then hit submit.</p>
        <div className={Syllablestyles.quizContainer}>
          <div className={Syllablestyles.quizCards}>
            {monosyllableQuizzes.map((quiz, index) => (
              <div id={`quizCard-${index}`} key={index} className={`${Syllablestyles.quizCard} ${flipped[index] ? Syllablestyles.flipped : ''}`}>
                <div className={Syllablestyles.cardFront}>
                  <div className={Syllablestyles.options}>
                    {quiz.options.map((option, i) => (
                      <button
                        key={i}
                        className={`${Syllablestyles.optionButton} ${userAnswers[index].includes(option) ? Syllablestyles.selected : ''}`}
                        onClick={() => handleOptionClick(index, option)}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                  <button onClick={() => handleQuizSubmit(index)} className={Syllablestyles.submitButton}>Submit</button>
                  {feedbacks[index] && (
                    <div className={`${Syllablestyles.feedback} ${feedbacks[index] === 'Try again!' ? Syllablestyles.incorrectFeedback : Syllablestyles.correctFeedback}`}>
                      {feedbacks[index]}
                    </div>
                  )}
                </div>
                <div className={Syllablestyles.cardBack}>
                  <h3>{feedbacks[index]}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={Syllablestyles.multiPractice}>
        <h3>Find the multi syllable</h3>
        <p>Drag the multisyllable word out of the list and place it in the multisyllable container.</p>
        <div className={Syllablestyles.quizContainer}>
          <div className={Syllablestyles.quizCards}>
            {multisyllableQuizzes.map((quiz, index) => (
              <div
                key={index}
                className={`${Syllablestyles.quizCard} ${multiFlipped[index] ? Syllablestyles.flipped : ''}`}
                onDrop={() => handleDrop("multiSyllable", index)}
                onDragOver={(e) => e.preventDefault()}
              >
                <div className={Syllablestyles.cardFront}>
                  <div className={Syllablestyles.options}>
                    {quiz.options.map((option, i) => (
                      <div
                        key={i}
                        className={Syllablestyles.draggableOption}
                        draggable
                        onDragStart={() => handleDragStart(option)}
                        style={{ visibility: multiAnswers[index] === option ? 'hidden' : 'visible' }} // Hide the picked word
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                  <div className={Syllablestyles.multiSyllableContainer} style={{ border: '2px dashed #8c7266' }}>
                    <p>multi syllable: {multiAnswers[index]}</p>
                    {showHandAnimation && index === 0 && (
                      <img src={handIcon} alt="Hand dragging" className={Syllablestyles.handAnimation} />
                    )}
                  </div>
                  <button onClick={() => handleMultiQuizSubmit(index)} className={Syllablestyles.submitButton}>Submit</button>
                  {multiFeedbacks[index] && (
                    <div className={multiFeedbacks[index] === 'Well done! Correct answer' ? Syllablestyles.correctFeedback : Syllablestyles.incorrectFeedback}>
                      {multiFeedbacks[index]}
                    </div>
                  )}
                </div>
                <div className={Syllablestyles.cardBack}>
                  <h3>{multiFeedbacks[index]}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={Syllablestyles.explanation}>
        <h2>Natural Imagery in Jueju</h2>
        <p>It is important in the first two lines of a jueju (the first couplet) to use mostly natural imagery. Natural imagery are words that refer to things found in nature that you can perceive through your senses. In the first two lines, it’s important to avoid abstraction, as well as “empty words” (xuzi) which refer to grammatical particles like “the” and “an,” conjunctions like “and, yet, but” or helping verbs like “is” “am” as well as past tenses and in the first two lines even pronouns. </p><br></br>
        <p>“Just the facts ma’am” as the American Poet William Carlos Williams once said. <br></br>

        Or as another American poet inspired by Jueju Ezra Pound once said, “no idea but in things.” 
        </p><br></br>
        <p>
        Don’t worry, you will have a chance to introduce human feelings and ideas into your jueju, but this will take place at a later stage of your development. Okay, so its time to test your knowledge!
        </p>
      </section>

      <section className={Syllablestyles.classifyQuiz}>
        <h2>Classify the Words</h2>
        <p>Drag and drop the words into the appropriate categories: "Natural Imagery Words" or "Empty Words".</p>
        <br></br>
        <div className={Syllablestyles.wordPool}>
          <h3>Pool of words</h3>
          <div className={Syllablestyles.wordList}>
            {classifyWords.map((word, index) => (
              <div
                key={index}
                className={Syllablestyles.draggableWord}
                draggable
                onDragStart={() => handleDragStart(word)}
              >
                {word}
              </div>
            ))}
          </div>
        </div>
        <div className={Syllablestyles.dropContainers}>
          <div
            className={Syllablestyles.dropContainer}
            onDrop={() => handleDrop("Natural Imagery Words")}
            onDragOver={(e) => e.preventDefault()}
          >
            <h3>Natural Imagery Words</h3>
            {classification["Natural Imagery Words"].map((word, index) => (
              <div key={index} className={Syllablestyles.droppedWord}>{word}</div>
            ))}
          </div>
          <div
            className={Syllablestyles.dropContainer}
            onDrop={() => handleDrop("Empty Words")}
            onDragOver={(e) => e.preventDefault()}
          >
            <h3>Empty Words</h3>
            {classification["Empty Words"].map((word, index) => (
              <div key={index} className={Syllablestyles.droppedWord}>{word}</div>
            ))}
          </div>
        </div>
        <div className={Syllablestyles.buttons}>
          <button onClick={handleClassificationCheck} className={Syllablestyles.checkButton}>Check</button>
          <button onClick={handleClear} className={Syllablestyles.clearButton}>Clear</button>

        </div>
        {classificationFeedback && (
          <div className={`${classificationFeedback === 'Well done! Correct classification' ? Syllablestyles.correctFeedback : Syllablestyles.incorrectFeedback} ${isClassificationCorrect ? Syllablestyles.correct : Syllablestyles.incorrect}`}>
            {classificationFeedback}
          </div>
        )}
      </section>
    </div>
  );
};

export default MonosyllablePractice;

