import React, { useState } from 'react';
import BuildLineStyles from './BuildLine.module.css';
import limg1 from './limg1.jpg';
import limg2 from './limg2.webp';
import limg3 from './limg3.jpg';
import limg5 from './limg5.webp';
import limg6 from './limg6.webp';
import limg7 from './limg7.webp'; // Image for the summer scene

const quizzes = [
  {
    question: "Build a 7-word line that describes extreme winters:",
    units: ["thick ice", "green lawns", "lush leaves", "crisp snow", "cold wind bites", "warm stream flows"],
    type: "sevenWord",
    validLines: [
      "thick ice crisp snow cold wind bites",
      "crisp snow thick ice cold wind bites"
    ],
    image: limg1
  },
  {
    question: "Build a 5-word line that describes an ocean scene:",
    units: ["big waves", "old red barn", "wheat fields", "rough seas crash"],
    type: "fiveWord",
    validLines: [
      "big waves rough seas crash",
    ],
    image: limg2
  },
  {
    type: "multipleChoice",
    question: "Choose a 5-word line that describes a desert:",
    options: [
      "Rain Pours Damp Air Blows",
      "Warm Sun Dry Sand Blows",
      "Thick Fog Moon glows dim",
      "Green moss tall pine tress"
    ],
    validLines: [
      "Warm Sun Dry Sand Blows"
    ],
    image: limg3
  },
  {
    type: "fillInTheBlank",
    question: "Tall Trees ______",
    options: [
      "Run so quick",
      "Reach past clouds",
      "Swim to shore"
    ],
    validLines: [
      "Reach past clouds"
    ],
    image: limg5
  },
  {
    type: "fillInTheBlank",
    question: "Green Fields ______ Dark Stream Flows",
    options: [
      "Thick ice",
      "Lush Hills",
      "of the"
    ],
    validLines: [
      "Lush Hills"
    ],
    image: limg6
  }
];

const BuildLine = () => {
  const [selectedWords, setSelectedWords] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [currentQuiz, setCurrentQuiz] = useState(0);
  const [isCorrect, setIsCorrect] = useState(false);
  const [draggedWord, setDraggedWord] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  const handleDragStart = (word) => {
    setDraggedWord(word);
  };

  const handleDrop = (index) => {
    const newWords = [...selectedWords];
    newWords[index] = draggedWord;
    setSelectedWords(newWords);
    setDraggedWord(null);

    const dropbox = document.querySelectorAll(`.${BuildLineStyles.dropBox}`)[index];
    dropbox.classList.add(BuildLineStyles.filled);
  };

  const handleClear = () => {
    setSelectedWords([]);
    setFeedback('');
    setShowImage(false);
  };

  const handleCheck = () => {
    const quiz = quizzes[currentQuiz];
    const line = selectedWords.filter(Boolean).join(' ');
    const isValid = quiz.validLines.includes(line);

    if (isValid) {
      setFeedback('Correct! You have formed a valid line.');
      setIsCorrect(true);
      setShowImage(true);
      const cardElement = document.querySelector(`.${BuildLineStyles.quiz}`);
      cardElement.classList.add(BuildLineStyles.correct);
      cardElement.classList.add(BuildLineStyles.flipOutAnimation);
      setTimeout(() => {
        if (currentQuiz < quizzes.length - 1) {
          setCurrentQuiz(currentQuiz + 1);
          handleClear();
          setIsCorrect(false);
          cardElement.classList.remove(BuildLineStyles.correct, BuildLineStyles.flipOutAnimation);
          cardElement.classList.add(BuildLineStyles.flipInAnimation);
          setTimeout(() => {
            cardElement.classList.remove(BuildLineStyles.flipInAnimation);
          }, 500);
        } else {
          setIsFinished(true);
        }
      }, 1500); // Increased the time the image is displayed
    } else {
      setFeedback('Try again! The selected words do not form a valid line.');
      const cardElement = document.querySelector(`.${BuildLineStyles.quiz}`);
      cardElement.classList.add(BuildLineStyles.incorrectAnimation);
      setTimeout(() => {
        cardElement.classList.remove(BuildLineStyles.incorrectAnimation);
      }, 1000);
    }
  };

  const handleOptionSelect = (option) => {
    const selectedOption = option;
    setSelectedWords([selectedOption]);
  };

  const handleMultipleChoiceCheck = () => {
    const quiz = quizzes[currentQuiz];
    const selectedOption = selectedWords.join(' ');

    if (quiz.validLines.includes(selectedOption)) {
      setFeedback('Correct! You have selected a valid line.');
      setIsCorrect(true);
      setShowImage(true);
      const cardElement = document.querySelector(`.${BuildLineStyles.quiz}`);
      cardElement.classList.add(BuildLineStyles.correct);
      cardElement.classList.add(BuildLineStyles.flipOutAnimation);
      setTimeout(() => {
        if (currentQuiz < quizzes.length - 1) {
          setCurrentQuiz(currentQuiz + 1);
          handleClear();
          setIsCorrect(false);
          cardElement.classList.remove(BuildLineStyles.correct, BuildLineStyles.flipOutAnimation);
          cardElement.classList.add(BuildLineStyles.flipInAnimation);
          setTimeout(() => {
            cardElement.classList.remove(BuildLineStyles.flipInAnimation);
          }, 500);
        } else {
          setIsFinished(true);
        }
      }, 2000); 
    } else {
      setFeedback('Try again! The selected option is not correct.');
      const cardElement = document.querySelector(`.${BuildLineStyles.quiz}`);
      cardElement.classList.add(BuildLineStyles.incorrectAnimation);
      setTimeout(() => {
        cardElement.classList.remove(BuildLineStyles.incorrectAnimation);
      }, 1000);
    }
  };

  const renderQuizContent = () => {
    const quiz = quizzes[currentQuiz];
  
    let dropboxCount = 0;
    if (quiz.type === 'sevenWord') {
      dropboxCount = 3; // Two for 2-word units, one for 3-word unit
    } else if (quiz.type === 'fiveWord') {
      dropboxCount = 2; // One for 2-word unit, one for 3-word unit
    }
  
    if (quiz.type === 'multipleChoice') {
      return (
          <div className={BuildLineStyles.multipleChoiceCard}>
              <h3>{quiz.question}</h3>
              <img src={quiz.image} alt="description" className={BuildLineStyles.image} /> {/* Display image here */}
              <div className={BuildLineStyles.options}>
                  {quiz.options.map((option, index) => (
                      <button
                          key={index}
                          onClick={() => handleOptionSelect(option)}
                          className={selectedWords.includes(option) ? BuildLineStyles.selectedOption : ''}
                      >
                          {option}
                      </button>
                  ))}
              </div>
              <button onClick={handleMultipleChoiceCheck} className={BuildLineStyles.submitButton}>Check</button>
          </div>
      );
    } else if (quiz.type === 'fillInTheBlank') {
      return (
        <div className={BuildLineStyles.fillInTheBlankCard}>
          <h3>Choose a unit to fill in the blank in order to form a valid Jueju line</h3>
          <h3>{quiz.question}</h3>
          <div className={BuildLineStyles.dropArea}>
            <div className={`${BuildLineStyles.dropBox} ${BuildLineStyles.threeWordDropBox}`} onDrop={() => handleDrop(0)} onDragOver={(e) => e.preventDefault()}>
              {selectedWords[0] && <span className={BuildLineStyles.selectedWord}>{selectedWords[0]}</span>}
            </div>
          </div>
          <div className={BuildLineStyles.options}>
            {quiz.options.map((option, index) => (
              <div
                key={index}
                className={BuildLineStyles.unit}
                draggable
                onDragStart={() => handleDragStart(option)}
              >
                {option}
              </div>
            ))}
          </div>
          {showImage && <img src={quiz.image} alt="description" className={BuildLineStyles.image} />}
          <button onClick={handleCheck} className={BuildLineStyles.submitButton}>Check</button>
        </div>
      );
    } else {
      return (
        <div className={BuildLineStyles.unitsContainer}>
          <h3>{quiz.question}</h3>
          <img src={quiz.image} alt="description" className={BuildLineStyles.image} /> {/* Display image here */}
          <div className={BuildLineStyles.unitSection}>
            <div className={BuildLineStyles.unitPool}>
              {quiz.units.map((unit, index) => (
                <div key={index} className={BuildLineStyles.unit} draggable onDragStart={() => handleDragStart(unit)}>
                  {unit}
                </div>
              ))}
            </div>
          </div>
          <div className={BuildLineStyles.dropArea}>
            {Array.from({ length: dropboxCount }).map((_, index) => (
              <div
                key={index}
                className={`${BuildLineStyles.dropBox} ${index < dropboxCount - 1 ? BuildLineStyles.twoWordDropBox : BuildLineStyles.threeWordDropBox}`}
                onDrop={() => handleDrop(index)}
                onDragOver={(e) => e.preventDefault()}
              >
                {selectedWords[index] && <span className={BuildLineStyles.selectedWord}>{selectedWords[index]}</span>}
              </div>
            ))}
          </div>
        </div>
      );
    }
  };
  
  

  const renderFinalCard = () => {
    return (
      <div className={BuildLineStyles.finalCard}>
        <h3>Good job! You're done with building a line. Now move on to building a couplet.</h3>
      </div>
    );
  };

  return (
    <div className={BuildLineStyles.container}>
      <h2 className={BuildLineStyles.title}>Build a Jueju Line</h2>
      <ExplanationSection />
      <div className={`${BuildLineStyles.quiz} ${isCorrect ? BuildLineStyles.correct : ''}`}>
        {!isFinished ? (
          <>
            {renderQuizContent()}
            {(quizzes[currentQuiz].type !== 'multipleChoice' && quizzes[currentQuiz].type !== 'fillInTheBlank') && (
              <div className={BuildLineStyles.buttons}>
                <button onClick={handleCheck} className={BuildLineStyles.submitButton}>Check</button>
                <button onClick={handleClear} className={BuildLineStyles.clearButton}>Clear</button>
              </div>
            )}
            <p className={BuildLineStyles.feedback}>{feedback}</p>
          </>
        ) : (
          renderFinalCard()
        )}
        <div className={BuildLineStyles.indicator}>
          {Array.from({ length: quizzes.length }).map((_, index) => (
            <span
              key={index}
              className={`${BuildLineStyles.dot} ${currentQuiz === index ? BuildLineStyles.active : ''}`}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

const ExplanationSection = () => {
  return (
          
    <div className={BuildLineStyles.explanation}>
      <h3>What makes a Jueju line valid?</h3>
      <br></br>
      <p>
      In Jueju poetry, the first couplet (the first two lines) must describe a coherent natural scene, and it should be based on a single scene captured in a single moment of time. This means that all the units combined to form a line should logically and thematically fit together to paint a consistent picture of a real place and time. For instance:
      </p>
      <br></br>
      <ul>
        <li>
          <strong>late tide</strong> (describing the sea) is more likely to be combined with <strong>dark waves crash</strong> to form a line like <em>"late tide” +  “dark waves crash"</em>.
        </li>
        <br></br>
        <li>
          <strong>red barn</strong> (describing a farm) can be combined with <strong>dry wheat fields</strong> (also describing an agrarian scene) to form a line like <em>"red barn” + dry wheat “fields."</em>.
        </li>
      </ul>
      <br></br>
      <p>
      Combining units from different scenes, that do not correspond to a real place and time in your memory, like <strong>late tide</strong> and <strong>dry wheat fields</strong>, for instance, moves away from the tradition of jueju, which strives to vividly paint a genuine scene you have directly experienced. 
      </p>
      <br></br>
      <p>
      Remember, the goal is to ensure that each line in the first couplet presents a unified and vivid description of a single real scene, and you will later find this scene will set the mood and context for the feelings and ideas presented in the complete jueju.
      </p>
    </div>
  );
};

export default BuildLine;
