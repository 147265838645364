import React from 'react';
import GongshiStyles from './GongshiIntro.module.css';

const GongshiIntro = () => {
  return (
    <div className={GongshiStyles.container}>
      <h1 className={GongshiStyles.title}>Gongshi Introduction</h1>
      <p className={GongshiStyles.intro}>
        Congratulations, you have mastered the regulated couplet! Now it is time to apply everything you have learned to master the highest form of classical Chinese poetry, regulated jueju.
      </p>
      <p className={GongshiStyles.intro}>
        In this level, you will not only learn how to follow the traditional ping/ze patterns for five and seven-word couplets, but you will learn how to follow these patterns to create full regulated jueju (in both perfect and acceptably imperfect ways). Let’s begin.
      </p>
      
      <h2 className={GongshiStyles.subtitle}>Ping/Ze Patterns</h2>
      <p className={GongshiStyles.content}>
        Like regulated couplets, both five- and seven-word regulated jueju are sorted into two categories: “ping start” and “ze start” forms. And just as before, one must look to the second word in a two-word poetry unit to assess whether it is a ping or ze unit. The first word in a two-word unit is open to being either, but the second word must be one or the other. In three-word units, the first word is free, but the second and third words are not.
      </p>
      <p className={GongshiStyles.content}>
        To determine the ping-ze pattern of a poem, therefore, simply:
        <ol className={GongshiStyles.list}>
          <li>Count the number of words per line (five or seven).</li>
          <li>Determine if the second word of the first unit is ping or ze.</li>
        </ol>
        If the second word in the line is ping, it’s a “ping start.” If it is ze, then it is a “ze-start” poem.
      </p>

      <h3 className={GongshiStyles.exampleTitle}>Example: Ze-Start Seven-Word Jueju</h3>
      <p className={GongshiStyles.content}>
        Here is a well-known example of a “ze-start seven-word jueju” entitled <em>Mooring at Maple (Sweetgum) Bridge</em>:
      </p>
      <p className={GongshiStyles.poem}>
        枫桥夜泊<br />
        月落 乌啼 霜满天<br />
        江枫 渔火 对愁眠<br />
        姑苏 城外 寒山寺<br />
        夜半 钟声 到客船
      </p>
      <p className={GongshiStyles.romanized}>
        Romanized in modern Mandarin:<br />
        Fēng Qiáo Yè Bó<br />
        Yuè luò wū tí shuāng mǎn tiān,<br />
        Jiāng fēng yú huǒ duì chóu mián.<br />
        Gū Sū chéng wài Hán Shān sì,<br />
        Yè bàn zhōng shēng dào kè chuán.
      </p>
      <p className={GongshiStyles.content}>
        Try to match the missing poetry units into their proper order:
      </p>
      <div className={GongshiStyles.quiz}>
        <p>Moon light _____ _____ frost fills sky</p>
        <p>Sweet-gums boat lights ___ _____ _____</p>
        <p>Out side Su zhou, Han shan shrine</p>
        <p>_____ _____ bells ring sound close by</p>
        <p><strong>Word Bank:</strong> “wake I lie,” “from boat,” “crows caw,” “cars honk” (last one is incorrect).</p>
        <p><strong>Answer:</strong></p>
        <p>Moon light crows caw frost fills sky</p>
        <p>Sweet-gums boat lights wake I lie</p>
        <p>Out side Su zhou, Han shan shrine</p>
        <p>From boat bells ring sound close by</p>
      </div>

      <h3 className={GongshiStyles.exampleTitle}>Example: Ping-Start English Jueju</h3>
      <p className={GongshiStyles.content}>
        Try to match the missing poetry units of the following English jueju:
      </p>
      <div className={GongshiStyles.quiz}>
        <p>Carved stones ____ ____ brisk wind blows</p>
        <p>Flint path birds song ____ ____ ____</p>
        <p>Cut bait old hands ____ ____ ____</p>
        <p>____ ____ soft light fast mind slows</p>
        <p><strong>Word Bank:</strong> “swift brook,” “aged wrist casts,” “thick grass grows,” “sun fades,” “real clear line” (last one is incorrect).</p>
        <p><strong>Answer:</strong></p>
        <p>Carved stones swift brook brisk wind blows</p>
        <p>Flint path birds song thick grass grows</p>
        <p>Cut bait old hands aged wrist casts</p>
        <p>Sun fades soft light fast mind slows</p>
      </div>
    </div>
  );
};

export default GongshiIntro;

