import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Homestyles from './Home.module.css';
import Header from './Header';
import IntroVideo from './her5_animation.mp4';  // replace with the actual path to your video 
import { FaUser, FaUserAltSlash, FaUserTie, FaChild} from 'react-icons/fa';

function Home() {
  const navigate = useNavigate();
  const [showLoginSignup, setShowLoginSignup] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [showUserTypeQuestion, setShowUserTypeQuestion] = useState(false); // First question: Major or Minor
  const [showRoleQuestion, setShowRoleQuestion] = useState(false); 
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    school_name: '', // New field for teacher signup
    group_name: '',  // Optional field for teacher signup
    student_id: '',
  });
  const [userType, setUserType] = useState('');  
  const [role, setRole] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isGuest, setIsGuest] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasMadeSelection, setHasMadeSelection] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false); // New state for tracking teacher or user
  const loginSignupRef = useRef(null);
  
  // Base URL for the backend
  const API_URL = 'https://juejupath.com/api';

  const toggleUserType = (type) => {
    setUserType(type);
  };

  const toggleIsTeacher = () => {
    setIsTeacher(!isTeacher);
  };

  const refreshToken = async () => {
    try {
      const response = await axios.post(`${API_URL}/refresh-token/`, null, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 200) {
        localStorage.setItem('token', response.data.access_token);
      } else {
        console.error('Failed to refresh token:', response.status);
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  };
  
  useEffect(() => {
    const scrollAmount = 100; // Amount to scroll
    const scrollSpeed = 500; // Speed of each scroll (ms)
    let scrollingDown = true; // Flag to determine scroll direction
    let scrollCount = 0; // To keep track of how many times it has scrolled

    const scrollInterval = setInterval(() => {
      if (scrollCount = 1) { // Stop after 3 scrolls
        clearInterval(scrollInterval);
        return;
      }

      if (scrollingDown) {
        window.scrollBy({ top: scrollAmount, behavior: 'smooth' });
      } else {
        window.scrollBy({ top: -scrollAmount, behavior: 'smooth' });
        scrollCount++; // Increment the count after each cycle
      }
      scrollingDown = !scrollingDown; // Toggle direction
    }, scrollSpeed * 8); // Run every 1000ms (adjust if needed)

    return () => clearInterval(scrollInterval); // Cleanup on unmount
  }, []);

  // Periodically refresh the token
  useEffect(() => {
    const refreshInterval = setInterval(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const expiryTime = decodedToken.exp * 1000;
            const currentTime = Date.now();
            const timeLeft = expiryTime - currentTime;
            const threshold = 5 * 60 * 1000; // 5 minutes before expiration

            if (timeLeft < threshold) {
                refreshToken(); // Refresh the token if close to expiry
            }
        }
    }, 12000); // Check every 5 minutes

    return () => clearInterval(refreshInterval);
}, []);


useEffect(() => {
  const selection = sessionStorage.getItem('hasMadeSelection');
  if (selection) {
    setHasMadeSelection(true);
    if (selection === 'guest') {
      setIsGuest(true);
      setIsLoggedIn(false);
    } else if (selection === 'loggedIn') {
      setIsLoggedIn(true);
      setIsGuest(false);
    }
  }
}, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (loginSignupRef.current && !loginSignupRef.current.contains(event.target)) {
        setShowLoginSignup(false);
      }
    };

    if (showLoginSignup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showLoginSignup]);

  // Resets feedback after 3 seconds
  const resetFeedback = () => {
    setTimeout(() => {
      setErrorMessage('');
      setSuccessMessage('');
    }, 3000);
  };
  const handleCardClick = (event, link) => {
    if (!hasMadeSelection) {
      event.preventDefault();
      scrollToTop(); // Scroll to the top where the user can make a decision
    } else {
      navigate(link); // Allow navigation if a decision has been made
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Close all modals and reset the selection flow
  const closeModal = () => {
    setShowUserTypeQuestion(false);
    setShowRoleQuestion(false);
    setShowLoginSignup(false);
  };


  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      const navLinks = document.querySelectorAll('nav ul li a');
      let current = '';

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.scrollY >= sectionTop - 60) {
          current = section.getAttribute('id');
        }
      });

      navLinks.forEach((link) => {
        link.classList.remove(Homestyles.active);
        if (link.getAttribute('href').includes(current)) {
          link.classList.add(Homestyles.active);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setErrorMessage('');
    setSuccessMessage('');
  };
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Resets the flow and shows the initial container
  const resetSelection = () => {
    setShowUserTypeQuestion(false);  // Hide the first question
    setShowRoleQuestion(false);  // Hide the second question
    setShowLoginSignup(false); // Hide the login/signup form
    setUserType('');
    setRole('');
    setShowLoginSignup(false);
  };
  
  const loginUser = async (userData, endpoint) => {
    try {
      // Make the POST request to the login endpoint
      const response = await axios.post(`${API_URL}${endpoint}`, userData);
  
      // Log the entire response to see the structure
      console.log('Login Response:', response);
  
      // Return the token and name from the response data
      return { access_token: response.data.access_token, name: response.data.name };
    } catch (error) {
      throw error.response?.data?.detail || 'Login failed';
    }
  };
  
  const signupUser = async (userData, endpoint) => {
    try {
      // Make the POST request to the signup endpoint
      const response = await axios.post(`${API_URL}${endpoint}`, userData);
  
      // Log the entire response to see the structure
      console.log('Signup Response:', response);
  
      // Return the token and name from the response data
      return { access_token: response.data.access_token, name: userData.name };
    } catch (error) {
      throw error.response?.data?.detail || 'Sign up failed';
    }
  };
   
  // Handle the submission of the login/signup form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
  
    try {
      // Clear previous session data
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('role');
  
      let userPayload = {};
      let endpoint = '/login/';
  
      if (userType === 'major') {
        if (isLogin) {
          endpoint = role === 'teacher' ? '/teacher/login/' : '/user/login/';
          userPayload = { email: formData.email, password: formData.password };
        } else {
          endpoint = role === 'teacher' ? '/teacher/signup/' : '/user/signup/';
          userPayload = role === 'teacher'
            ? { name: formData.name, email: formData.email, password: formData.password, school_name: formData.school_name, group_name: formData.group_name }
            : { name: formData.name, email: formData.email, password: formData.password };
        }
      } else {
        endpoint = '/minor/login/';
        userPayload = { student_id: formData.student_id, group_name: formData.group_name };
      }
  
      // Log the payload and endpoint for debugging purposes
      console.log('User Payload:', userPayload, 'Endpoint:', endpoint);
  
      // Log the entire response object for debugging purposes
      const user = isLogin ? await loginUser(userPayload, endpoint) : await signupUser(userPayload, endpoint);
  
      // Log the response data to see the exact structure returned
      console.log('Login/Signup Full Response:', user);
  
      // Access the response properties correctly and set them to local storage
      localStorage.setItem('token', user.access_token);
      
      // Check if 'name' exists in the response and set it in localStorage
      if (user.name) {
        localStorage.setItem('username', user.name);
      } else {
        console.warn('Warning: User name is undefined in response.');
        localStorage.setItem('username', 'Unknown User'); // Default value if name is undefined
      }
  
      // Set user role in local storage based on the login/signup context
      localStorage.setItem('role', userType === 'major' ? (role === 'teacher' ? 'teacher' : 'user') : 'minor');
  
      // Show success message
      setSuccessMessage(`${isLogin ? 'Login' : 'Sign up'} successful! Welcome, ${user.name || 'Unknown User'}`);
      
      // Update state
      setIsLoggedIn(true);
      setIsGuest(false);
      sessionStorage.setItem('hasMadeSelection', 'loggedIn');
      
      // Redirect user to appropriate path after a short delay
      setTimeout(() => {
        navigate(role === 'teacher' ? '/teacher/dashboard' : '/learn/examination-path');
      }, 2000);
    } catch (error) {
      setErrorMessage(typeof error === 'string' ? error : 'An error occurred. Please try again.');
    }
  };
  
  
  
  

  // Function to handle guest access
  const handleGuestContinue = () => {
    setIsGuest(true);
    setIsLoggedIn(false);
    sessionStorage.setItem('hasMadeSelection', 'guest');
    setHasMadeSelection(true);
    navigate('/learn/examination-path');
  };

  const handleLoginSignupClick = () => {
    closeModal();
    setShowUserTypeQuestion(true); // Show the first question (Major or Minor)
  };

  // Handle User Type selection
  const handleUserTypeSelection = (type) => {
    setUserType(type);
    setShowUserTypeQuestion(false); // Hide the first question
    if (type === 'major') {
      setShowRoleQuestion(true); // Show second question if Major is selected
    } else {
      setShowLoginSignup(true); // Directly show login container for minors
    }
  };

  // Handle Role selection (Teacher or Regular User)
  const handleRoleSelection = (selectedRole) => {
    setRole(selectedRole);
    setShowRoleQuestion(false); // Hide the second question
    setShowLoginSignup(true); // Show login/signup container
  };
  
  
  return (
    <div className={Homestyles.mainContainer}>
      {/* Show header only if a selection is made */}
      {hasMadeSelection && <Header />}

      <section id="home" className={Homestyles.section}>
        <div className={Homestyles.introVideoContainer}>
          <video autoPlay loop muted className={Homestyles.introVideo}>
            <source src={IntroVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Show "Master the Art" modal initially or when in guest mode */}
        {!showLoginSignup && !showUserTypeQuestion && !showRoleQuestion && (
          <div className={Homestyles.title}>
            <h1>Master the Art of Classical Chinese Poetry</h1>
            <div className={Homestyles.progressMessage}>
              <div className={Homestyles.progressOption}>
                <FaUser className={Homestyles.icon} />
                <p><strong>If you log in:</strong> Your progress will be tracked, and you will be able to submit your work and also view your previous submissions.</p>
              </div>
              <div className={Homestyles.progressOption}>
                <FaUserAltSlash className={Homestyles.icon} />
                <p><strong>If you continue as a guest:</strong> You can explore freely, but your progress won’t be saved.</p>
              </div>
            </div>

            {/* Show login/signup options only if the user is a guest */}
            {isGuest && (
              <div className={Homestyles.buttons}>
                <button className={Homestyles.button} onClick={handleLoginSignupClick}>
                  Login/Signup
                </button>
              </div>
            )}
            {/* Show continue as guest and login/signup only if no selection has been made */}
            {!hasMadeSelection && (
              <div className={Homestyles.buttons}>
                <button className={Homestyles.button} onClick={handleLoginSignupClick}>
                  Login/Signup
                </button>
                <button className={Homestyles.button} onClick={handleGuestContinue}>
                  Continue as Guest
                </button>
              </div>
            )}
          </div>
        )}

        {/* Question modal: Major or Minor */}
        {showUserTypeQuestion && (
          <div className={Homestyles.loginSignupContainer}>
            <h2>Are you an Adult or Minor?</h2>
            <div className={Homestyles.userTypeToggle}>
              <span className={Homestyles.toggleOption} onClick={() => handleUserTypeSelection('major')}>
                <FaUserTie className={Homestyles.icon} /> Adult/College-student
              </span>
              <span className={Homestyles.toggleOption} onClick={() => handleUserTypeSelection('minor')}>
                <FaChild className={Homestyles.icon} /> Minor
              </span>
            </div>
          </div>
        )}

        {/* Question modal: Teacher or Regular */}
        {showRoleQuestion && (
          <div className={Homestyles.loginSignupContainer}>
            <h2>Are you a Teacher or Regular User?</h2>
            <div className={Homestyles.userTypeToggle}>
              <span className={Homestyles.toggleOption} onClick={() => handleRoleSelection('teacher')}>
                <FaUserTie className={Homestyles.icon} /> Teacher
              </span>
              <span className={Homestyles.toggleOption} onClick={() => handleRoleSelection('regular')}>
                <FaUser className={Homestyles.icon} /> Regular User
              </span>
            </div>
          </div>
        )}
                <div className={Homestyles.scrollIndicator}>
          <span>Scroll down</span>
          <div className={Homestyles.arrow}></div>
        </div>
      </section>
      
        {/* Show login/signup container based on state */}
        {showLoginSignup && (
          <div className={Homestyles.loginSignupContainer} ref={loginSignupRef}>
            {/* Signup and Login Forms */}
            <div className={`${Homestyles.formStructor} ${isLogin ? '' : Homestyles.slideUp}`}>
              <div className={`${Homestyles.signup} ${isLogin ? Homestyles.slideUp : ''}`}>
                {userType === 'major' && role && (
                  <>
                    <h2 className={Homestyles.formTitle} id="signup" onClick={toggleForm}>
                      <span>or</span>Sign up
                    </h2>
                    <form className={Homestyles.formHolder} onSubmit={handleSubmit}>
                      {!isLogin && (
                        <>
                          <input type="text" name="name" className={Homestyles.input} placeholder="Name" value={formData.name} onChange={handleInputChange} required />
                          {role === 'teacher' && (
                            <>
                              <input type="text" name="school_name" className={Homestyles.input} placeholder="School Name" value={formData.school_name} onChange={handleInputChange} required />
                              <input type="text" name="group_name" className={Homestyles.input} placeholder="Group Name (Optional)" value={formData.group_name} onChange={handleInputChange} />
                            </>
                          )}
                          <input type="email" name="email" className={Homestyles.input} placeholder="Email" value={formData.email} onChange={handleInputChange} required />
                          <input type="password" name="password" className={Homestyles.input} placeholder="Password" value={formData.password} onChange={handleInputChange} required />
                        </>
                      )}
                      <button className={Homestyles.submitBtn} type="submit">{isLogin ? 'Log in' : 'Sign up'}</button>
                    </form>
                  </>
                )}
                {userType === 'minor' && (
                  <>
                    <h2>Login as Minor</h2>
                    <form className={Homestyles.formHolder} onSubmit={handleSubmit}>
                      <input type="text" name="student_id" className={Homestyles.input} placeholder="Student ID" value={formData.student_id} onChange={handleInputChange} required />
                      <input type="text" name="group_name" className={Homestyles.input} placeholder="Group Name" value={formData.group_name} onChange={handleInputChange} required />
                      <button className={Homestyles.submitBtn} type="submit">Log in</button>
                    </form>
                  </>
                )}
                {errorMessage && <p className={Homestyles.error}>{errorMessage}</p>}
                {successMessage && <p className={Homestyles.success}>{successMessage}</p>}
              </div>
              <div className={`${Homestyles.login} ${isLogin ? '' : Homestyles.slideUp}`}>
                <div className={Homestyles.center}>
                  <h2 className={Homestyles.formTitle} id="login" onClick={toggleForm}>
                    <span>or</span>Log in
                  </h2>
                  <form className={Homestyles.formHolder} onSubmit={handleSubmit}>
                    <input type="email" name="email" className={Homestyles.input} placeholder="Email" value={formData.email} onChange={handleInputChange} required />
                    <input type="password" name="password" className={Homestyles.input} placeholder="Password" value={formData.password} onChange={handleInputChange} required />
                    <button className={Homestyles.submitBtn} type="submit">Log in</button>
                  </form>
                {errorMessage && <p className={Homestyles.error}>{errorMessage}</p>}
                {successMessage && <p className={Homestyles.success}>{successMessage}</p>}
              </div>
            </div>
          </div>
        </div>
      )}

        
      <section id="introduction" className={Homestyles.introductionSection}>
        <h2>Introduction</h2>
        <p>
          Welcome to our Jueju poetry website. Whether you’re a student learning the art of Classical Chinese Poetry
          or a teacher guiding your students, this platform will help you master the nuances of Jueju.
        </p>
      </section>

      <div className={Homestyles.cardsContainer}>
        <section id="about" className={Homestyles.sectionCard}>
          <div className={Homestyles.card}>
            <h2>About Jueju Poetry</h2>
            <p>Dive into the history and significance of Jueju poetry.</p>
            <button onClick={(event) => handleCardClick(event, '/about/jueju')} className={Homestyles.button}>Read More</button>
          </div>
        </section>

        <section id="learn" className={Homestyles.sectionCard}>
          <div className={Homestyles.card}>
            <h2>Learning Path</h2>
            <p>Follow our step-by-step guide to understand the rules and nuances of Jueju poetry.</p>
            <button onClick={(event) => handleCardClick(event, '/learn/examination-path')} className={Homestyles.button}>Begin Learning</button>
          </div>
        </section>

        <section id="assess" className={Homestyles.sectionCard}>
          <div className={Homestyles.card}>
            <h2>Assessment Tool</h2>
            <p>Use our interactive tool to assess your Jueju poems and get instant feedback.</p>
            <button onClick={(event) => handleCardClick(event, '/assess')} className={Homestyles.button}>Start Now</button>
          </div>
        </section>

        <section id="newman-prize" className={Homestyles.sectionCard}>
          <div className={Homestyles.card}>
            <h2>Newman Prize for Jueju</h2>
            <p>Awarded annually, the Newman Prize recognizes excellence in Jueju poetry.</p>
            <button 
              onClick={() => window.location.href = 'https://www.ou.edu/cis/research/institute-for-us-china-issues/us-china-cultural-issues/newman-prize-for-english-jueju'} 
              className={Homestyles.button}
            >
              Learn More
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;

