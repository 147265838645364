import axios from 'axios';

const API_URL = 'http://3.17.190.79:8000'; // Your FastAPI backend URL

export const signupUser = async (userData, endpoint = '/signup/') => {
  try {
    const response = await axios.post(`${API_URL}${endpoint}`, userData); // Use the API_URL for the base URL
    const token = response.data.access_token;
    return { access_token: token, name: userData.name };
  } catch (error) {
    throw error.response.data.detail;
  }
};

// Admin specific API calls
export const loginAdmin = async (adminData) => {
  try {
    const response = await axios.post(`${API_URL}/admin/login/`, adminData);
    const token = response.data.access_token; // Extract the token from the response
    localStorage.setItem('adminToken', token); // Store the token in localStorage
    return response.data;
  } catch (error) {
    throw error.response.data.detail;
  }
};

const getAuthHeaders = () => {
  const token = localStorage.getItem('adminToken');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
// Login User Function
export const loginUser = async (userData, endpoint = '/login/') => {
  try {
    const response = await axios.post(`${API_URL}${endpoint}`, userData); // Use the API_URL for the base URL
    const token = response.data.access_token;
    return { access_token: token, name: userData.email ? userData.email.split('@')[0] : userData.student_id };
  } catch (error) {
    throw error.response.data.detail || 'Login failed';
  }
};
export const fetchUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/admin/users/`, getAuthHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};


/* export const addQuiz = async (quizData) => {
  try {
    const response = await axios.post(`${API_URL}/admin/quizzes/`, quizData, getAuthHeaders());
    return response.data;
  } catch (error) {
    throw error.response.data.detail;
  }
};

export const deleteQuiz = async (quizId) => {
  try {
    const response = await axios.delete(`${API_URL}/admin/quizzes/${quizId}`, getAuthHeaders());
    return response.data;
  } catch (error) {
    throw error.response.data.detail;
  }
};

export const modifyQuiz = async (quizId, quizData) => {
  try {
    const response = await axios.put(`${API_URL}/admin/quizzes/${quizId}`, quizData, getAuthHeaders());
    return response.data;
  } catch (error) {
    throw error.response.data.detail;
  }
};

export const fetchQuizzes = async (moduleId, quizSetId) => {
  try {
    const response = await axios.get(`${API_URL}/quizzes?module_id=${moduleId}&quiz_set_id=${quizSetId}`, getAuthHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitQuiz = async (quizResponse) => {
  try {
    const response = await axios.post(`${API_URL}/quizzes/verify/`, {
      quiz_id: quizResponse.quiz_id,
      question_id: quizResponse.question_id,  // Add question_id to the payload
      selected_options: quizResponse.selected_options,  // Match the selected options field
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchModules = async () => {
  try {
    const response = await axios.get(`${API_URL}/admin/modules/`, getAuthHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchQuizSets = async () => {
  try {
    const response = await axios.get(`${API_URL}/admin/quiz-sets/`, getAuthHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchQuizzesByQuizSet = async (quizSetId) => {
  try {
    const response = await axios.get(`${API_URL}/admin/quizzes/${quizSetId}`, getAuthHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
};

*/

