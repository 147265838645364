import React, { useState, useEffect } from 'react';
import styles from './Couplet2.module.css';
import timg1 from './timg1.webp'; // Import the image

const quizzes = [
  {
    question: "Identify the best possible third lines (\"zhuan\") of jueju that turn poem into human ideas/emotions:",
    options: [
      "clear sky white geese late sun falls",
      "Who’ll care for us when they’re gone",
      "large waves tide rolls in",
      "who will wait for us",
    ],
    correct: [
      "Who’ll care for us when they’re gone",
      "who will wait for us",
    ],
    type: 'multipleChoice'
  },
  {
    question: "Identify the best possible third lines (\"zhuan\") of jueju from the given set of lines:",
    options: [
      "soft wind dry seeds june sun glows",
      "why do friends come just to leave",
      "bright moon clear cool stream",
      "who will pour her dolls their tea",
    ],
    correct: [
      "why do friends come just to leave",
      "who will pour her dolls their tea",
    ],
    type: 'multipleChoice'
  },
  // Replaced quiz
  {
    question: "Turn the poem to human world, ideas and feelings:",
    firstCouplet: [
      "moon light     falls near bed",
      "as if      frost there spread",
    ],
    options: [
      "lift gaze find bright moon",
      "faint stars glow through night",
      "bird songs fill each night",
    ],
    validThirdLines: [
      "lift gaze find bright moon",
    ],
    feedback: {
      "lift gaze find bright moon": "Correct! You've chosen a valid turning line.",
      "faint stars glow through night": "Try again, the selected line does not introduce human world ideas or feelings.",
      "bird songs fill each night": "Try again, the selected line does not introduce human world ideas or feelings.",
    },
    type: 'dragAndDrop'
  },
  // Replaced quiz
  {
    question: "Turn the poem to human world, ideas and feelings:",
    firstCouplet: [
      "faint stars      sweet fields      soft wind blows",
      "cool stream      gold hills      pale moon glows",
    ],
    options: [
      "hot stones laid bare by June sun",
      "bright sun blue skies cool stream flows",
      "how can such nights slip so quick",
    ],
    validThirdLines: [
      "how can such nights slip so quick",
    ],
    feedback: {
      "how can such nights slip so quick": "Correct! You've chosen a valid turning line.",
      "hot stones laid bare by June sun": "Try again, it's an imagery line and doesn't belong to the second couplet.",
      "bright sun blue skies cool stream flows": "Try again, it's an imagery line and doesn't belong to the second couplet.",
    },
    type: 'dragAndDrop'
  }
];

const fourthLineQuizzes = [
  {
    question: "Pick the 4th line or the concluding line for the following poem from the given list of lines",
    poems: [
      {
        firstLines: [
          "wide field     scarce grass      dark streams flow",
          "white rocks      bare hills      dirt paths show",
          "look up      close eyes      soft clouds pass",
        ],
        correct: "just rest when life moves this slow"
      }
    ],
    options: [
      "faint stars sweet winds cold mists rise",
      "just rest when life moves this slow",
      "look down and miss home",
    ]
  },
  {
    question: "Pick the 4th line or the concluding line for the following poem from the given list of lines",
    poems: [
      {
        firstLines: [
          "long boats     cut through     sea salt lake",
          "wood craft     speed past      their own wake",
          "each isle      has its      own long tale",
        ],
        correct: "waves of time have yet to break"
      }
    ],
    options: [
      "cold farms white smoke hangs like mist",
      "reach feel with cut stretched hands",
      "waves of time have yet to break"
    ]
  }
];

const themeQuizzes = [
  {
    question: "Organize the given lines according to thematic progression as to which couplet of jueju they belong: jing-境 or qing-情",
    lines: [
      "eyes shine heart smiles glints of joy",
      "thick ice crisp snow cold wind bites",
      "friends fill warm homes days and nights",
      "fresh frost chilled world white cloaked sights"
    ],
    couplet1: [
      "thick ice crisp snow cold wind bites",
      "fresh frost chilled world white cloaked sights"
    ],
    couplet2: [
      "eyes shine heart smiles glints of joy",
      "friends fill warm homes days and nights"
    ],
    type: 'dragAndDrop'
  },
  {
    question: "Based on the image and the given pool of lines, try to form a jueju",
    image: timg1,
    poolOfLines: [
      "cold dew damp grass low clouds play",
      "plump drops frail bulbs bright light ray",
      "sit still close eyes sip poured tea",
      "sweet burst clear wind moon weeps still",
      "days start late when skies are grey",
    ],
    validJueju: [
      "cold dew damp grass low clouds play",
      "plump drops frail bulbs bright light ray",
      "sit still close eyes sip poured tea",
      "days start late when skies are grey"
    ],
    type: 'imageDragAndDrop'
  },
  {
    question: "Is the poem following the jueju's expected thematic progression?",
    poem: [
      "Pine trees       cling to        lime stone peaks",
      "White gulls      dart for        what each seeks",
      "If you       could be        here with me",
      "We would       stay for        weeks and weeks"
    ],
    options: ["True", "False"],
    correct: "True",
    type: 'trueFalse'
  }
];

const Couplet2 = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [draggedWord, setDraggedWord] = useState(null);
  const [constructedLine, setConstructedLine] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [currentFourthLineQuizIndex, setCurrentFourthLineQuizIndex] = useState(0);
  const [draggedLine, setDraggedLine] = useState(null);
  const [selectedFourthLines, setSelectedFourthLines] = useState({ 0: null, 1: null });
  const [fourthLineFeedback, setFourthLineFeedback] = useState('');
  const [currentThemeQuizIndex, setCurrentThemeQuizIndex] = useState(0);
  const [draggedThemeLine, setDraggedThemeLine] = useState(null);
  const [selectedThemeLines, setSelectedThemeLines] = useState({ couplet1: [], couplet2: [] });
  const [selectedJuejuLines, setSelectedJuejuLines] = useState(Array(4).fill(null));
  const [themeFeedback, setThemeFeedback] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [usedLines, setUsedLines] = useState(new Set());


  const currentQuiz = quizzes[currentQuizIndex];
  const currentFourthLineQuiz = fourthLineQuizzes[currentFourthLineQuizIndex];
  const currentThemeQuiz = themeQuizzes[currentThemeQuizIndex];

  useEffect(() => {
    if (currentQuiz.type === 'dragAndDrop' && currentQuiz.question.includes("Turn the poem to human world")) {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 2000); // Show popup for 2 seconds
    }
  }, [currentQuizIndex]);

  const handleOptionSelect = (option) => {
    const newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter(opt => opt !== option)
      : [...selectedOptions, option];
    setSelectedOptions(newSelectedOptions);
  };

  const handleDragStart = (item, type) => {
    if (type === 'word') {
      setDraggedWord(item);
    } else if (type === 'line') {
      setDraggedLine(item);
    } else if (type === 'themeLine') {
      setDraggedThemeLine(item);
    }
  };

  const handleDrop = (index, type) => {
    if (type === 'word' && draggedWord) {
      setConstructedLine(draggedWord);
      setDraggedWord(null);
    } else if (type === 'line' && draggedLine) {
      setSelectedFourthLines({ ...selectedFourthLines, [index]: draggedLine });
      setDraggedLine(null);
    } else if (type === 'themeLine' && draggedThemeLine) {
      if (usedLines.has(draggedThemeLine)) return; // Prevent duplicate use
      const newSelectedThemeLines = { ...selectedThemeLines };
      if (index === 'couplet1') {
        newSelectedThemeLines.couplet1.push(draggedThemeLine);
      } else if (index === 'couplet2') {
        newSelectedThemeLines.couplet2.push(draggedThemeLine);
      }
      setSelectedThemeLines(newSelectedThemeLines);
      setUsedLines(new Set([...usedLines, draggedThemeLine])); // Mark line as used
      setDraggedThemeLine(null);
    } else if (type === 'juejuLine' && draggedThemeLine) {
      if (usedLines.has(draggedThemeLine)) return; // Prevent duplicate use
      const newSelectedJuejuLines = [...selectedJuejuLines];
      newSelectedJuejuLines[index] = draggedThemeLine;
      setSelectedJuejuLines(newSelectedJuejuLines);
      setUsedLines(new Set([...usedLines, draggedThemeLine])); // Mark line as used
      setDraggedThemeLine(null);
    }
  };

  const clearThemeSelection = () => {
    setSelectedThemeLines({ couplet1: [], couplet2: [] });
    setUsedLines(new Set()); // Clear used lines
  };

  const clearJuejuSelection = () => {
    setSelectedJuejuLines(Array(4).fill(null)); // Clear selected lines for the second theme quiz
    setUsedLines(new Set()); // Reset the used lines for this quiz
  };

  const handleClear = () => {
    setConstructedLine('');
    setFeedback('');
  };

  const handleCheck = () => {
    if (currentQuiz.type === 'multipleChoice') {
      const correctAnswers = currentQuiz.correct;
      const hasIncorrect = selectedOptions.some(option => !correctAnswers.includes(option)); // check if there are any wrong answers
      const hasCorrect = selectedOptions.some(option => correctAnswers.includes(option)); // check if at least one correct answer is selected
      const isCorrect = !hasIncorrect && hasCorrect; // true if there are no wrong answers and at least one correct is selected
  
      setFeedback(isCorrect ? 'Correct! You have selected valid answer(s).' : 'Incorrect. Try again.');
  
      if (isCorrect) {
        setIsCorrect(true);
        setTimeout(() => {
          const cardElement = document.querySelector(`.${styles.quizCard}`);
          if (cardElement) {
            cardElement.classList.add(styles.correct);
          }
          setTimeout(() => {
            if (currentQuizIndex < quizzes.length - 1) {
              setCurrentQuizIndex(currentQuizIndex + 1);
              setSelectedOptions([]);
              setFeedback('');
              setIsCorrect(false);
              if (cardElement) {
                cardElement.classList.remove(styles.correct);
              }
            } else {
              setFeedback('Congratulations! You have completed all the quizzes.');
            }
          }, 500);
        }, 1000);
      } else {
        const cardElement = document.querySelector(`.${styles.quizCard}`);
        if (cardElement) {
          cardElement.classList.add(styles.incorrect);
        }
        setTimeout(() => {
          if (cardElement) {
            cardElement.classList.remove(styles.incorrect);
          }
        }, 500);
      }
    } else if (currentQuiz.type === 'dragAndDrop' && currentQuiz.firstCouplet) {
      const line = constructedLine;
      const isValid = currentQuiz.validThirdLines.includes(line);
      setFeedback(currentQuiz.feedback[line]);
      
      if (isValid) {
        setIsCorrect(true);
        setTimeout(() => {
          const cardElement = document.querySelector(`.${styles.quizCard}`);
          if (cardElement) {
            cardElement.classList.add(styles.correct);
          }
          setTimeout(() => {
            if (currentQuizIndex < quizzes.length - 1) {
              setCurrentQuizIndex(currentQuizIndex + 1);
              setConstructedLine('');
              setFeedback('');
              setIsCorrect(false);
              if (cardElement) {
                cardElement.classList.remove(styles.correct);
              }
            } else {
              setFeedback('Congratulations! You have completed all the quizzes.');
            }
          }, 500);
        }, 1000);
      } else {
        const cardElement = document.querySelector(`.${styles.quizCard}`);
        if (cardElement) {
          cardElement.classList.add(styles.incorrect);
        }
        setTimeout(() => {
          if (cardElement) {
            cardElement.classList.remove(styles.incorrect);
          }
        }, 500);
      }
    }
  };

  const handleFourthLineCheck = () => {
    const { poems } = currentFourthLineQuiz;
    const isCorrect = poems.every((poem, index) => selectedFourthLines[index] === poem.correct);

    if (isCorrect) {
      setFourthLineFeedback('Correct!.');
      setTimeout(() => {
        const cardElement = document.querySelector(`.${styles.quizCard}`);
        if (cardElement) {
          cardElement.classList.add(styles.correct);
        }
        setTimeout(() => {
          if (currentFourthLineQuizIndex < fourthLineQuizzes.length - 1) {
            setCurrentFourthLineQuizIndex(currentFourthLineQuizIndex + 1);
            setSelectedFourthLines({ 0: null, 1: null });
            setFourthLineFeedback('');
            if (cardElement) {
              cardElement.classList.remove(styles.correct);
            }
          } else {
            setFourthLineFeedback('Congratulations! You have completed all the fourth line quizzes.');
          }
        }, 500);
      }, 1000);
    } else {
      setFourthLineFeedback('One or both lines are incorrect. Try again.');
      const cardElement = document.querySelector(`.${styles.quizCard}`);
      if (cardElement) {
        cardElement.classList.add(styles.incorrect);
      }
      setTimeout(() => {
        if (cardElement) {
          cardElement.classList.remove(styles.incorrect);
        }
      }, 500);
    }
  };

  const handleThemeCheck = () => {
    if (currentThemeQuiz.type === 'dragAndDrop') {
      const { couplet1, couplet2 } = currentThemeQuiz;
      const isCouplet1Correct = couplet1.every(line => selectedThemeLines.couplet1.includes(line));
      const isCouplet2Correct = couplet2.every(line => selectedThemeLines.couplet2.includes(line));

      if (isCouplet1Correct && isCouplet2Correct) {
        setThemeFeedback('Correct! You have classified the lines correctly.');
        setTimeout(() => {
          const cardElement = document.querySelector(`.${styles.quizCard}`);
          if (cardElement) {
            cardElement.classList.add(styles.correct);
          }
          setTimeout(() => {
            if (currentThemeQuizIndex < themeQuizzes.length - 1) {
              setCurrentThemeQuizIndex(currentThemeQuizIndex + 1);
              setSelectedThemeLines({ couplet1: [], couplet2: [] });
              setThemeFeedback('');
              if (cardElement) {
                cardElement.classList.remove(styles.correct);
              }
            } else {
              setThemeFeedback('Congratulations! You have completed all the theme quizzes.');
            }
          }, 500);
        }, 1000);
      } else {
        setThemeFeedback('Incorrect. Try again.');
        const cardElement = document.querySelector(`.${styles.quizCard}`);
        if (cardElement) {
          cardElement.classList.add(styles.incorrect);
        }
        setTimeout(() => {
          if (cardElement) {
            cardElement.classList.remove(styles.incorrect);
          }
        }, 500);
      }
    } else if (currentThemeQuiz.type === 'imageDragAndDrop') {
      const { validJueju } = currentThemeQuiz;
      const alternateValidJueju = [
        "plump drops frail bulbs bright light ray",
        "cold dew damp grass low clouds play",
        "sit still close eyes sip poured tea",
        "days start late when skies are grey"
      ];
      const isJuejuCorrect = selectedJuejuLines.every((line, index) => line === validJueju[index]);
      const isAlternateJuejuCorrect = selectedJuejuLines.every((line, index) => line === alternateValidJueju[index]);
      if (isJuejuCorrect || isAlternateJuejuCorrect) {
        setThemeFeedback('Correct! You have formed a valid jueju.');
        setTimeout(() => {
          const cardElement = document.querySelector(`.${styles.quizCard}`);
          if (cardElement) {
            cardElement.classList.add(styles.correct);
          }
          setTimeout(() => {
            if (currentThemeQuizIndex < themeQuizzes.length - 1) {
              setCurrentThemeQuizIndex(currentThemeQuizIndex + 1);
              setSelectedJuejuLines(Array(4).fill(null));
              setThemeFeedback('');
              if (cardElement) {
                cardElement.classList.remove(styles.correct);
              }
            } else {
              setThemeFeedback('Congratulations! You have completed all the theme quizzes.');
            }
          }, 500);
        }, 1000);
      } else {
        setThemeFeedback('Incorrect. Try again.');
        const cardElement = document.querySelector(`.${styles.quizCard}`);
        if (cardElement) {
          cardElement.classList.add(styles.incorrect);
        }
        setTimeout(() => {
          if (cardElement) {
            cardElement.classList.remove(styles.incorrect);
          }
        }, 500);
      }
    } else if (currentThemeQuiz.type === 'trueFalse') {
      const isCorrect = selectedOptions[0] === currentThemeQuiz.correct;
      setThemeFeedback(isCorrect ? 'Correct!' : 'Incorrect. Try again.');
      if (isCorrect) {
        setIsCorrect(true);
        setTimeout(() => {
          const cardElement = document.querySelector(`.${styles.quizCard}`);
          if (cardElement) {
            cardElement.classList.add(styles.correct);
          }
          setTimeout(() => {
            if (currentThemeQuizIndex < themeQuizzes.length - 1) {
              setCurrentThemeQuizIndex(currentThemeQuizIndex + 1);
              setSelectedOptions([]);
              setThemeFeedback('');
              setIsCorrect(false);
              if (cardElement) {
                cardElement.classList.remove(styles.correct);
              }
            } else {
              setThemeFeedback('Congratulations! You have completed all the theme quizzes.');
            }
          }, 500);
        }, 1000);
      } else {
        const cardElement = document.querySelector(`.${styles.quizCard}`);
        if (cardElement) {
          cardElement.classList.add(styles.incorrect);
        }
        setTimeout(() => {
          if (cardElement) {
            cardElement.classList.remove(styles.incorrect);
          }
        }, 500);
      }
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Constructing the Second Couplet</h2>

      <section className={styles.explanation}>
        <h3>Overall Rules</h3>
        <br></br>
        <p>The second couplet of a jueju poem follows these rules:</p>
        <br></br>
        <ul>
          <li>Only uses monosyllabic (single-syllable) words.</li>
          <li>Matches the first couplet's line length (5 or 7 words).</li>
        </ul>
      </section>

      <section className={styles.explanation}>
        <h3>The Third “Turning” Line 转</h3>
        <p>The third line, also known as the "zhuan-转“ or "turning line," introduces a human feeling, topic, or concept. It acts as a turning point, adding depth to the poem. This line can take many forms, such as a question, making an observation, or a soft command.</p>

        <div className={styles.interactiveSection}>
          <h4> 1.Asking a Question </h4>
            <div className={styles.example}>
              <p>The third line often poses a question, inviting reflection.</p>
              <div className={styles.exampleBox}>
                <p><strong>Who will&nbsp;&nbsp;&nbsp;&nbsp; meet me&nbsp;&nbsp;&nbsp;&nbsp; on the road?</strong></p>
              </div>
            </div>
        

          <h4> 2. Making an observation </h4>
        
            <div className={styles.example}>
              <p>The third line can introduce an observation that shifts the focus.</p>
              <div className={styles.exampleBox}>
                <p><strong>Years pass&nbsp;&nbsp;&nbsp;&nbsp; like friends&nbsp;&nbsp;&nbsp;&nbsp; lost in dreams.</strong></p>
              </div>
            </div>
          

          <h4> 3. A soft command </h4>
          
            <div className={styles.example}>
              <p>The third line can express a personal statement, often emotional or philosophical.</p>
              <div className={styles.exampleBox}>
                <p><strong>Close eyes&nbsp;&nbsp;&nbsp;&nbsp; breathe deep&nbsp;&nbsp;&nbsp;&nbsp; rest your mind.</strong></p>
              </div>
            </div>
          
        </div>
      </section>


      <section className={styles.quizSection}>
        <h3>{currentQuiz.question}</h3>
        {showPopup && (
          <div className={styles.popup}>
            <p>{currentQuiz.question}</p>
          </div>
        )}
        <div className={`${styles.quizCard} ${isCorrect ? styles.correct : ''}`}>
          {currentQuiz.type === 'multipleChoice' ? (
            <div className={styles.options}>
              {currentQuiz.options.map((option, index) => (
                <button
                  key={index}
                  className={`${styles.option} ${selectedOptions.includes(option) ? styles.selected : ''}`}
                  onClick={() => handleOptionSelect(option)}
                >
                  {option}
                </button>
              ))}
            </div>
          ) : (
            <>
              <div className={styles.couplet}>
                {currentQuiz.firstCouplet.map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
              <div className={styles.dropArea} onDrop={() => handleDrop(null, 'word')} onDragOver={(e) => e.preventDefault()}>
                {constructedLine && <span className={styles.constructedWord}>{constructedLine}</span>}
              </div>
              <div className={styles.wordPool}>
                {currentQuiz.options.map((word, index) => (
                  <div
                    key={index}
                    className={styles.word}
                    draggable
                    onDragStart={() => handleDragStart(word, 'word')}
                  >
                    {word}
                  </div>
                ))}
              </div>
              <button className={styles.clearButton} onClick={handleClear}>Clear</button>
            </>
          )}
          <button className={styles.checkButton} onClick={handleCheck}>Check</button>
          {feedback && <p className={styles.feedback}>{feedback}</p>}
        </div>
        <div className={styles.indicator}>
          {Array.from({ length: quizzes.length }).map((_, index) => (
            <span
              key={index}
              className={`${styles.dot} ${currentQuizIndex === index ? styles.active : ''}`}
            ></span>
          ))}
        </div>
      </section>

      <section className={styles.explanation}>
        <h3>The Fourth Line</h3>
        <p>
        The fourth line of a Jueju poem serves as a response to the third line, bringing closure to the emotions or questions raised. The fourth line can answer a question, return to the natural scene, or resolve a command.
        </p>
        <br></br>
        <p>Here are three common ways the fourth line responds to the third line:</p>
        
        <div className={styles.interactiveSection}>
          <h4>1. Answering a Question with Another Question</h4>
          <p>
          In this form, the fourth line echoes the third line’s question, leaving a sense of reflection or mystery. This technique can be thought-provoking and invites readers to ponder further.
          </p>
          <div className={styles.example}>
            <p><strong>Who will&nbsp;&nbsp;&nbsp;&nbsp; meet me&nbsp;&nbsp;&nbsp;&nbsp; on the road?</strong></p>
            <p><strong>Who will&nbsp;&nbsp;&nbsp;&nbsp; read these&nbsp;&nbsp;&nbsp;&nbsp; ink-stained lines?</strong></p>
          </div>
          <br></br>
          <h4>2. Answering with a Topical Response</h4>
          <p>
          This approach provides a thematic answer to the third line’s question, offering a direct resolution to the poem's narrative.
          </p>
          <div className={styles.example}>
            <p><strong>Who will &nbsp;&nbsp;&nbsp;&nbsp;meet me &nbsp;&nbsp;&nbsp;&nbsp;on the road?</strong></p>
            <p><strong>Bright moon&nbsp;&nbsp;&nbsp;&nbsp; faint stars&nbsp;&nbsp;&nbsp;&nbsp; my old friends.</strong></p>
          </div>
          <br></br>
          <h4>3. Returning to Nature for Resolution</h4>
          <p>
          Often, the fourth line returns to nature, allowing the natural world to provide a quiet, resonant conclusion. This method emphasizes the connection between human experience and the natural environment.
          </p>
          <div className={styles.example}>
            <p><strong>What does&nbsp;&nbsp;&nbsp;&nbsp;time leave &nbsp;&nbsp;&nbsp;&nbsp;in its wake?</strong></p>
            <p><strong>Late sun&nbsp;&nbsp;&nbsp;&nbsp; seeps through &nbsp;&nbsp;&nbsp;&nbsp;thin stemmed trees.</strong></p>
          </div>
          <br></br>
          <h4>4. Resolving a command</h4>
          <div className={styles.example}>
            <p><strong>Close eyes&nbsp;&nbsp;&nbsp;&nbsp;breathe deep &nbsp;&nbsp;&nbsp;&nbsp;rest your mind</strong></p>
            <p><strong>Green reeds&nbsp;&nbsp;&nbsp;&nbsp;will bend&nbsp;&nbsp;&nbsp;&nbsp; while we sleep</strong></p>
          </div>
        </div>
      </section>


      <section className={styles.quizSection}>
        <h3>{currentFourthLineQuiz.question}</h3>
        <br></br>
        <div className={`${styles.quizCard} ${fourthLineFeedback ? (fourthLineFeedback.includes('Correct') ? styles.correct : styles.incorrect) : ''}`}>
          {currentFourthLineQuiz.poems.map((poem, poemIndex) => (
            <div key={poemIndex} className={styles.poem}>
              {poem.firstLines.map((line, lineIndex) => (
                <p key={lineIndex}>{line}</p>
              ))}
              <div
                className={styles.dropArea}
                onDrop={() => handleDrop(poemIndex, 'line')}
                onDragOver={(e) => e.preventDefault()}
              >
                {selectedFourthLines[poemIndex] || "Drop your line here"}
              </div>
            </div>
          ))}
          <div className={styles.wordPool}>
            {currentFourthLineQuiz.options.map((option, index) => (
              <div
                key={index}
                className={styles.word}
                draggable
                onDragStart={() => handleDragStart(option, 'line')}
              >
                {option}
              </div>
            ))}
          </div>
          <button className={styles.checkButton} onClick={handleFourthLineCheck}>Check</button>
          {fourthLineFeedback && <p className={styles.feedback}>{fourthLineFeedback}</p>}
        </div>
        <div className={styles.indicator}>
          {Array.from({ length: fourthLineQuizzes.length }).map((_, index) => (
            <span
              key={index}
              className={`${styles.dot} ${currentFourthLineQuizIndex === index ? styles.active : ''}`}
            ></span>
          ))}
        </div>
      </section>

      <section className={styles.quizSection}>
        <h3>{currentThemeQuiz.question}</h3>
        <br></br>
        <div className={`${styles.quizCard} ${themeFeedback ? (themeFeedback.includes('Correct') ? styles.correct : styles.incorrect) : ''}`}>
          {currentThemeQuiz.type === 'dragAndDrop' ? (
            <>
              <div className={styles.linesPool}>
                {currentThemeQuiz.lines.map((line, index) => (
                  <div
                    key={index}
                    className={`${styles.line} ${usedLines.has(line) ? styles.disabled : ''}`}
                    draggable={!usedLines.has(line)}
                    onDragStart={() => handleDragStart(line, 'themeLine')}
                  >
                    {line}
                  </div>
                ))}
              </div>
              <div className={styles.dropBoxes}>
                <div
                  className={styles.dropBox}
                  onDrop={() => handleDrop('couplet1', 'themeLine')}
                  onDragOver={(e) => e.preventDefault()}
                >
                  {selectedThemeLines.couplet1.length > 0 ? selectedThemeLines.couplet1.map((line, index) => (
                    <p key={index} className={styles.droppedLine}>{line}</p>
                  )) : "Drop couplet1(jing) lines here"}
                </div>
                <div
                  className={styles.dropBox}
                  onDrop={() => handleDrop('couplet2', 'themeLine')}
                  onDragOver={(e) => e.preventDefault()}
                >
                  {selectedThemeLines.couplet2.length > 0 ? selectedThemeLines.couplet2.map((line, index) => (
                    <p key={index} className={styles.droppedLine}>{line}</p>
                  )) : "Drop couplet2(qing) lines here"}
                </div>
              </div>
              <button className={styles.clearButton} onClick={clearThemeSelection}>Clear</button> {/* Add Clear button */}
            </>
          ) : currentThemeQuiz.type === 'imageDragAndDrop' ? (
            <>
              <img src={currentThemeQuiz.image} alt="Theme" className={styles.image} />
              <div className={styles.linesPool}>
                {currentThemeQuiz.poolOfLines.map((line, index) => (
                  <div
                    key={index}
                    className={`${styles.line} ${usedLines.has(line) ? styles.disabled : ''}`} 
                    draggable={!usedLines.has(line)}
                    onDragStart={() => handleDragStart(line, 'themeLine')}
                  >
                    {line}
                  </div>
                ))}
              </div>
              
              <div className={styles.dropBoxesColumn}>
                {Array.from({ length: 4 }).map((_, index) => (
                  <div
                    key={index}
                    className={styles.dropBox}
                    onDrop={() => handleDrop(index, 'juejuLine')}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    {selectedJuejuLines[index] || "Drop line here"}
                  </div>
                ))}
              </div>
              <button className={styles.clearButton} onClick={clearJuejuSelection}>Clear</button> {/* Add Clear button */}
            </>
          ) : currentThemeQuiz.type === 'trueFalse' ? (
            <>
              <div className={styles.poem}>
                {currentThemeQuiz.poem.map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
              <div className={styles.options}>
                {currentThemeQuiz.options.map((option, index) => (
                  <button
                    key={index}
                    className={`${styles.option} ${selectedOptions.includes(option) ? styles.selected : ''}`}
                    onClick={() => handleOptionSelect(option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </>
          ) : null}
          <button className={styles.checkButton} onClick={handleThemeCheck}>Check</button>
          {themeFeedback && <p className={styles.feedback}>{themeFeedback}</p>}
        </div>
        <div className={styles.indicator}>
          {Array.from({ length: themeQuizzes.length }).map((_, index) => (
            <span
              key={index}
              className={`${styles.dot} ${currentThemeQuizIndex === index ? styles.active : ''}`}
            ></span>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Couplet2;
