import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Learnstyles from './Learn.module.css';
import Grammar from './Grammar';
import SemanticResonance from './Semantic';
import PingZe from './PingZe';
import JurenIntro from './JurenIntro';
import Juren from './Juren';
import PingZePoem from './PingZePoem'; // Import the new component for Ping/Ze for Poem
import Gongshi from './Gongshi'; // Import the new component for Gongshi
import AccountModal from './AccountModal';
import BadgesModal from './BadgesModal';
import SubmissionsModal from './SubmissionsModal';

const regSections = [
  'jurenIntro',
  'grammar',
  'semanticResonance',
  'pingze',
  'juren',
  'pingzePoem', // Add the new section for Ping/Ze for Poem
  'gongshi', // Add the new section for Gongshi
];

const RegLearn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialSection = location.hash ? location.hash.substring(1) : 'jurenIntro';
  const [activeSection, setActiveSection] = useState(initialSection);
  const [mode, setMode] = useState('regulated');
  const token = localStorage.getItem('token');
  const [badgeLevel, setBadgeLevel] = useState('guest');
  const [levelUpdated, setLevelUpdated] = useState(false);

  // State for modals
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showBadgesModal, setShowBadgesModal] = useState(false);
  const [showSubmissionsModal, setShowSubmissionsModal] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);

  // State for dropdowns
  const [aboutDropdown, setAboutDropdown] = useState(false);
  const [learnDropdown, setLearnDropdown] = useState(false);

  useEffect(() => {
    if (token) {
      fetchUserLevel(token);
    }
    // Close the dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (!event.target.closest(`.${Learnstyles.profileSection}`)) {
        setProfileDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [token, levelUpdated]);

  const fetchUserLevel = async (token) => {
    try {
      const response = await fetch('http://127.0.0.1:8000/user/current-level', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setBadgeLevel(data.level || 'guest');
      setLevelUpdated(false);
    } catch (error) {
      console.error('Error fetching user level:', error);
    }
  };

  useEffect(() => {
    if (location.hash) {
      setActiveSection(location.hash.substring(1));
    }
  }, [location.hash]);

  const handleSectionChange = (section) => {
    setActiveSection(section);
    navigate(`#${section}`);
  };

  const handleModeChange = (e) => {
    const selectedMode = e.target.value;
    setMode(selectedMode);
    if (selectedMode === 'unregulated') {
      navigate('/learn');
    }
  };

  const handleBack = () => {
    const currentIndex = regSections.indexOf(activeSection);
    if (currentIndex === 0) {
      navigate('/');
    } else if (currentIndex > 0) {
      handleSectionChange(regSections[currentIndex - 1]);
    }
    window.scrollTo(0, 0); // Scroll to the top after navigating back
  };

  const handleNext = () => {
    const currentIndex = regSections.indexOf(activeSection);
    if (currentIndex < regSections.length - 1) {
      handleSectionChange(regSections[currentIndex + 1]);
    }
    window.scrollTo(0, 0); // Scroll to the top after navigating next
  };

  const handleAboutMouseEnter = () => setAboutDropdown(true);
  const handleAboutMouseLeave = () => setAboutDropdown(false);
  const handleLearnMouseEnter = () => setLearnDropdown(true);
  const handleLearnMouseLeave = () => setLearnDropdown(false);
  const handleProfileClick = () => setProfileDropdown(!profileDropdown);

  const closeModals = () => {
    setShowAccountModal(false);
    setShowBadgesModal(false);
    setShowSubmissionsModal(false);
  };

  const handleAccountClick = () => {
    setShowAccountModal(true);
    setProfileDropdown(false);
  };

  const handleBadgesClick = () => {
    setShowBadgesModal(true);
    setProfileDropdown(false);
  };

  const handleSubmissionsClick = () => {
    setShowSubmissionsModal(true);
    setProfileDropdown(false);
  };

  const renderSection = () => {
    switch (activeSection) {
      case 'jurenIntro':
        return badgeLevel === 'Xicuai' || badgeLevel === 'guest' ? <JurenIntro /> : null;
      case 'grammar':
        return badgeLevel === 'Xicuai' || badgeLevel === 'guest' ? <Grammar /> : null;
      case 'semanticResonance':
        return badgeLevel === 'Xicuai' || badgeLevel === 'guest' ? <SemanticResonance /> : null;
      case 'pingze':
        return badgeLevel === 'Xicuai' || badgeLevel === 'guest' ? <PingZe /> : null;
      case 'juren':
        return badgeLevel === 'Xicuai' || badgeLevel === 'guest' ? <Juren onLevelChange={() => setLevelUpdated(true)} /> : null;
      case 'pingzePoem': // Render the Ping/Ze for Poem section
        return badgeLevel === 'Gongshi' || badgeLevel === 'guest' ? <PingZePoem /> : null;
      case 'gongshi': // Render the Gongshi section
        return badgeLevel === 'Gongshi' || badgeLevel === 'guest' ? <Gongshi /> : null;
      default:
        return <p>Select a section from the menu</p>;
    }
  };

  return (
    <div className={Learnstyles.learnContainer}>
      <header className={Learnstyles.header}>
        <nav className={Learnstyles.navCenter}>
          <a href="/">Home</a>
          <div
            className={Learnstyles.dropdown}
            onMouseEnter={handleAboutMouseEnter}
            onMouseLeave={handleAboutMouseLeave}
          >
            <a href="#about">About</a>
            {aboutDropdown && (
              <div className={Learnstyles.dropdownContent}>
                <Link to="/about/jueju">About Jueju</Link>
                <Link to="/about/english-jueju">About English Jueju</Link>
              </div>
            )}
          </div>
          <div
            className={Learnstyles.dropdown}
            onMouseEnter={handleLearnMouseEnter}
            onMouseLeave={handleLearnMouseLeave}
          >
            <a href="#learn">Learn</a>
            {learnDropdown && (
              <div className={Learnstyles.dropdownContent}>
                <Link to="/learn/examination-path" className={Learnstyles.active}>The Examination Path</Link>
                <Link to="/learn/forest-path">The Forest Path (Coming Soon)</Link>
              </div>
            )}
          </div>
          <a href="/assess">Assess</a>
          <a href="/resources">Resources</a>
        </nav>
        <div className={Learnstyles.profileSection} onClick={handleProfileClick}>
          <span className={Learnstyles.profileIcon}>👤</span>
          <span className={Learnstyles.dropdownArrow}>▼</span>
          {profileDropdown && (
            <div className={Learnstyles.profileDropdown}>
              <button onClick={handleAccountClick}>Account</button>
              <button onClick={handleBadgesClick}>Badges</button>
              <button onClick={handleSubmissionsClick}>Submissions</button>
            </div>
          )}
        </div>
      </header>

      <div className={Learnstyles.content}>
        <aside className={Learnstyles.sidebar}>
          <select className={Learnstyles.dropdown} value={mode} onChange={handleModeChange}>
            <option value="unregulated">Unregulated</option>
            <option value="regulated">Regulated</option>
          </select>
          <div className={Learnstyles.levelSection}>
            <h3>Juren</h3>
            <button
              className={`${badgeLevel === 'Xicuai' ? Learnstyles.unlockedButton : Learnstyles.lockedButton} ${activeSection === 'jurenIntro' ? Learnstyles.activeButton : ''}`}
              disabled={badgeLevel !== 'Xicuai' && badgeLevel !== 'guest'}
              onClick={() => handleSectionChange('jurenIntro')}
            >
              Juren Intro
            </button>
            <button
              className={`${badgeLevel === 'Xicuai' ? Learnstyles.unlockedButton : Learnstyles.lockedButton} ${activeSection === 'grammar' ? Learnstyles.activeButton : ''}`}
              disabled={badgeLevel !== 'Xicuai' && badgeLevel !== 'guest'}
              onClick={() => handleSectionChange('grammar')}
            >
              Grammar
            </button>
            <button
              className={`${badgeLevel === 'Xicuai' ? Learnstyles.unlockedButton : Learnstyles.lockedButton} ${activeSection === 'semanticResonance' ? Learnstyles.activeButton : ''}`}
              disabled={badgeLevel !== 'Xicuai' && badgeLevel !== 'guest'}
              onClick={() => handleSectionChange('semanticResonance')}
            >
              Semantic Resonance
            </button>
            <button
              className={`${badgeLevel === 'Xicuai' ? Learnstyles.unlockedButton : Learnstyles.lockedButton} ${activeSection === 'pingze' ? Learnstyles.activeButton : ''}`}
              disabled={badgeLevel !== 'Xicuai' && badgeLevel !== 'guest'}
              onClick={() => handleSectionChange('pingze')}
            >
              Ping/Ze
            </button>
            <button
              className={`${badgeLevel === 'Xicuai' ? Learnstyles.unlockedButton : Learnstyles.lockedButton} ${activeSection === 'juren' ? Learnstyles.activeButton : ''}`}
              disabled={badgeLevel !== 'Xicuai' && badgeLevel !== 'guest'}
              onClick={() => handleSectionChange('juren')}
            >
              Juren
            </button>

            <h3>Gongshi</h3>
            <button
              className={`${badgeLevel === 'Gongshi' ? Learnstyles.unlockedButton : Learnstyles.lockedButton} ${activeSection === 'pingzePoem' ? Learnstyles.activeButton : ''}`}
              disabled={badgeLevel !== 'Gongshi' && badgeLevel !== 'guest'}
              onClick={() => handleSectionChange('pingzePoem')}
            >
              Ping/Ze for Poem
            </button>
            <button
              className={`${badgeLevel === 'Gongshi' ? Learnstyles.unlockedButton : Learnstyles.lockedButton} ${activeSection === 'gongshi' ? Learnstyles.activeButton : ''}`}
              disabled={badgeLevel !== 'Gongshi' && badgeLevel !== 'guest'}
              onClick={() => handleSectionChange('gongshi')}
            >
              Gongshi
            </button>
          </div>
        </aside>

        <main className={Learnstyles.mainContent}>
          {renderSection()}
        </main>
      </div>

      <div className={Learnstyles.navigationBar}>
        <button onClick={handleBack}>Back</button>
        <button onClick={handleNext}>Next</button>
      </div>

      {showAccountModal && <AccountModal closeModal={closeModals} />}
      {showBadgesModal && <BadgesModal closeModal={closeModals} />}
      {showSubmissionsModal && <SubmissionsModal closeModal={closeModals} />}
    </div>
  );
};

export default RegLearn;
