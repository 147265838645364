import React, { useState } from 'react';
import AboutStyles from './AboutEnglishJueju.module.css';
import Header from './Header';

const AboutEnglishJueju = () => {
  const [expandedSections, setExpandedSections] = useState({
    chineseVsEnglish: false,
    whatRhymes: false,
  });

  const toggleSection = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div>
      <Header />
      <div className={AboutStyles.container}>
        <section className={AboutStyles.section}>
          <div className={AboutStyles.speechBubble}>
            <h2>About English Jueju</h2>
            <p className={AboutStyles.linkWrapper}>
              To learn about English jueju through a video: 
              <a
                href="https://www.youtube.com/watch?v=yzFSrwoG-p8&t=100s"
                target="_blank"
                rel="noopener noreferrer"
                className={AboutStyles.linkButton}
              >
                Watch Video
              </a>
            </p>
            
            <p>
              Jueju can be literally translated as "cut-verse," a form of classical Chinese poetry consisting of quatrains with five or seven single-syllable words per line.
            </p>
            <p>
              Jonathan Stalling created the English Jueju system to adapt this ancient art form to English while preserving its unique rhythmic and tonal structure.
            </p>

            <h3 className={AboutStyles.subheading}>The Structure:</h3>
            <ul>
              <li>Four lines of poetry</li>
              <li>Five or seven single-syllable words per line</li>
              <li>AABA rhyme scheme</li>
            </ul>
            <p>
              Explore the nuances of unregulated and regulated Jueju, and learn how to compose your own poem by following the Examination Path or the Forest Path.
            </p>

            <div className={AboutStyles.expandableSection}>
              <h3 onClick={() => toggleSection('chineseVsEnglish')} className={AboutStyles.sectionTitle}>
                Chinese vs English Jueju {expandedSections.chineseVsEnglish ? '▲' : '▼'}
              </h3>
              {expandedSections.chineseVsEnglish && (
                <div className={AboutStyles.sectionContent}>
                  <p>
                    When you see a Chinese jueju published today, the first thing you will likely notice is that poems are set as a regular number of characters per line (five or seven) and a set number of lines (four) which create a grid-like poem. Here is the most famous Tang Dynasty jueju by the poet Li Bai entitled “Quiet Night Thoughts.”
                  </p>
                  <p className={AboutStyles.poem}>
                    床前明月光 <br />
                    疑是地上霜 <br />
                    舉頭望明月 <br />
                    低頭思故鄉
                  </p>
                  <p>
                    This poem, like all Chinese poetry today, is arranged horizontally, but in premodern China, poetry was written vertically (right to left) and did not employ line breaks or punctuation.
                  </p>
                  <p>
                    Here's a breakdown of this poem with spaces inserted to represent pauses between the poetry units:
                  </p>
                  <p className={AboutStyles.poemTranslation}>
                    Chuáng qián    míng yuè guāng    — Moonlight falls near bed <br />
                    Yí shì  dì shang shuāng  — As if frost there spread <br />
                    Jǔtóu wàng míng yuè  — Lift gaze find bright moon <br />
                    Dītóu sī gù xiāng  — Miss home, hang my head
                  </p>
                  <p>
                    When listening to a jueju recited today in either Chinese or English, you can both hear the single-syllable cadence of the five-word line, and you will find a definite cesura (pause) between the first two-word unit and the second three-word unit.
                  </p>
                </div>
              )}
            </div>

            <div className={AboutStyles.expandableSection}>
              <h3 onClick={() => toggleSection('whatRhymes')} className={AboutStyles.sectionTitle}>
                What Rhymes with What? A Primer on Jueju Poetics {expandedSections.whatRhymes ? '▲' : '▼'}
              </h3>
              {expandedSections.whatRhymes && (
                <div className={AboutStyles.sectionContent}>
                  <p>
                    “Rhyming” in an expanded sense lies at the heart of regulated jueju, but in this tradition “rhyming” means a great deal more than it does in English.
                  </p>
                  <p>
                    In Classical Chinese philosophy, 感应 “ganying,” or “inter-resonance,” is a central cosmological concept. It describes how things in the cosmos resonate with other things within their shared categories of resonance (tonglei, 同类).
                  </p>
                  <p>There are five levels of inter-resonance (rhyming) in regulated jueju:</p>
                  <ol className={AboutStyles.rhymingLevels}>
                    <li><strong>End Rhyming:</strong> Rhyme the last words in the first, second, and fourth lines (AABA rhyme scheme).</li>
                    <li><strong>Cosmological Rhyming:</strong> Discover resonance between nature and human consciousness, and express this in the relationship between couplets.</li>
                    <li><strong>Semantic Rhyming:</strong> Balance or counterbalance meanings between units in the first and second lines.</li>
                    <li><strong>Grammatical Rhyming:</strong> Match the sequence of parts of speech between corresponding units in the couplets.</li>
                    <li><strong>Vowel Quality Rhyming:</strong> Balance yin (ze) and yang (ping) vowel qualities, both horizontally in lines and vertically in couplets.</li>
                  </ol>
                  <p>
                    Regulated jueju is unique in that it provides poets with a structured way to create harmony across various dimensions of language, from meaning and beauty to the healthful balance of sounds and patterns.
                  </p>
                </div>
              )}
            </div>

            <p className={AboutStyles.closing}>
              Let's get started! Join the Examination Path and begin your journey to mastering Jueju poetics.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutEnglishJueju;

