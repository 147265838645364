import React, { useState, useEffect } from 'react';
import Buildunitstyles from './BuildUnit.module.css';
import darkImage from './dark.jpg';
import treesImage from './trees.jpeg';
import darkTreesImage from './dark_trees.jpg';

const twoWordQuizzes = [
  {
    options: ["calm", "through", "cool", "runs", "wind"],
    validUnits: [["calm", "wind"], ["cool", "wind"]],
    type: "Adjective + Noun"
  },
  {
    options: ["moon", "near", "shines", "bright", "glows"],
    validUnits: [["moon", "glows"], ["moon", "shines"]],
    type: "Noun + Verb"
  },
  {
    options: ["peak", "rises", "well", "tall", "Or"],
    validUnits: [["tall", "peak"]],
    type: "Adjective + Noun"
  },
  {
    options: ["thick", "fade", "on", "rise", "Stars"],
    validUnits: [["Stars", "fade"], ["Stars", "rise"]],
    type: "Noun + Verb"
  }
];

const threeWordQuizzes = [
  // Quiz 1: Adjective + Adjective + Noun
  {
    options: ["blue", "flows", "walk", "under", "sky", "pale"],
    validUnits: [["pale", "blue", "sky"], ["blue", "pale", "sky"]],
    type: "Adjective + Adjective + Noun"
  },
  // Quiz 2: Adjective + Noun + Verb / Verb + Adjective + Noun
  {
    options: ["bright", "by", "sun", "past", "shines", "and"],
    validUnits: {
      "Adjective + Noun + Verb": [
        ["bright", "sun", "shines"]
      ],
      "Verb + Adjective + Noun": [
        ["shines", "bright", "sun"]
      ]
    },
    type: ["Adjective + Noun + Verb", "Verb + Adjective + Noun"]
  },
  // Quiz 3: Verb + Preposition + Noun
  {
    options: ["flows", "wild", "shore", "past", "far", "by"],
    validUnits: [["flows", "by", "shore"], ["flows", "past", "shore"]],
    type: "Verb + Preposition + Noun"
  },
  // Quiz 4: Preposition + Adjective + Noun
  {
    options: ["in", "stills", "but", "blue", "through", "sky"],
    validUnits: [["in", "blue", "sky"], ["through", "blue", "sky"]],
    type: "Preposition + Adjective + Noun"
  }
];

const BuildUnit = () => {
  const [selectedTwoWords, setSelectedTwoWords] = useState(Array(2).fill(null));
  const [twoWordFeedback, setTwoWordFeedback] = useState('');
  const [currentTwoWordQuiz, setCurrentTwoWordQuiz] = useState(0);
  const [isTwoWordCorrect, setIsTwoWordCorrect] = useState(false);

  const [selectedThreeWords, setSelectedThreeWords] = useState(Array(3).fill(null));
  const [threeWordFeedback, setThreeWordFeedback] = useState('');
  const [currentThreeWordQuiz, setCurrentThreeWordQuiz] = useState(0);
  const [isThreeWordCorrect, setIsThreeWordCorrect] = useState(false);
  const [availableTwoWordOptions, setAvailableTwoWordOptions] = useState(twoWordQuizzes[currentTwoWordQuiz].options);
  const [availableThreeWordOptions, setAvailableThreeWordOptions] = useState(threeWordQuizzes[currentThreeWordQuiz].options);

  const [draggedWord, setDraggedWord] = useState(null);
  useEffect(() => {
    setAvailableTwoWordOptions(twoWordQuizzes[currentTwoWordQuiz].options);
  }, [currentTwoWordQuiz]);

  useEffect(() => {
    setAvailableThreeWordOptions(threeWordQuizzes[currentThreeWordQuiz].options);
  }, [currentThreeWordQuiz]);
  useEffect(() => {
    const piece1 = document.querySelector(`.${Buildunitstyles.piece1}`);
    const piece2 = document.querySelector(`.${Buildunitstyles.piece2}`);
    const finalImage = document.querySelector(`.${Buildunitstyles.finalImage}`);

    let isCombined = false;

    const animatePuzzle = () => {
      if (piece1 && piece2 && finalImage) {
        if (isCombined) {
          piece1.style.transform = 'translateX(-50px)';
          piece2.style.transform = 'translateX(50px)';
          finalImage.style.opacity = 0;
        } else {
          piece1.style.transform = 'translateX(50px)'; /* Move to the center */
          piece2.style.transform = 'translateX(-50px)'; /* Move to the center */
          finalImage.style.opacity = 1;
        }

        isCombined = !isCombined;
      }
    };

    // Loop the animation every 3 seconds
    const interval = setInterval(animatePuzzle, 3000);

    // Initial animation after a short delay
    setTimeout(animatePuzzle, 500);

    return () => clearInterval(interval);
  }, []);

  const handleDragStart = (word) => {
    setDraggedWord(word);
  };

  const handleDrop = (index, isThreeWord) => {
    const newWords = isThreeWord ? [...selectedThreeWords] : [...selectedTwoWords];
    const wordToReplace = newWords[index]; // Word being replaced
    newWords[index] = draggedWord;

    if (isThreeWord) {
        setSelectedThreeWords(newWords);
        setAvailableThreeWordOptions(prevOptions => {
            let updatedOptions = prevOptions.filter(word => word !== draggedWord);
            // Return replaced word back to options if it exists
            if (wordToReplace) updatedOptions = [...updatedOptions, wordToReplace];
            return updatedOptions;
        });
    } else {
        setSelectedTwoWords(newWords);
        setAvailableTwoWordOptions(prevOptions => {
            let updatedOptions = prevOptions.filter(word => word !== draggedWord);
            // Return replaced word back to options if it exists
            if (wordToReplace) updatedOptions = [...updatedOptions, wordToReplace];
            return updatedOptions;
        });
    }

    setDraggedWord(null);
};


const handleWordRemove = (index, isThreeWord) => {
  const newWords = isThreeWord ? [...selectedThreeWords] : [...selectedTwoWords];
  const wordToRemove = newWords[index];
  newWords[index] = null;

  if (isThreeWord) {
      setSelectedThreeWords(newWords);
      if (wordToRemove) {
          setAvailableThreeWordOptions(prevOptions => [...prevOptions, wordToRemove]);
      }
  } else {
      setSelectedTwoWords(newWords);
      if (wordToRemove) {
          setAvailableTwoWordOptions(prevOptions => [...prevOptions, wordToRemove]);
      }
  }
};

const getPlaceholderText = (index, isThreeWord) => {
  const currentQuiz = isThreeWord ? threeWordQuizzes[currentThreeWordQuiz] : twoWordQuizzes[currentTwoWordQuiz];
  const type = Array.isArray(currentQuiz.type) ? currentQuiz.type.join(' / ') : currentQuiz.type;
  const types = type.split(' + ');
  return types[index] || '';
};

const handleClear = (isThreeWord) => {
  const wordsToReturn = isThreeWord ? selectedThreeWords : selectedTwoWords;
  
  if (isThreeWord) {
      setSelectedThreeWords(Array(3).fill(null));
      setThreeWordFeedback('');
      // Add the cleared words back to the available options
      setAvailableThreeWordOptions(prevOptions => [...prevOptions, ...wordsToReturn.filter(word => word)]);
  } else {
      setSelectedTwoWords(Array(2).fill(null));
      setTwoWordFeedback('');
      // Add the cleared words back to the available options
      setAvailableTwoWordOptions(prevOptions => [...prevOptions, ...wordsToReturn.filter(word => word)]);
  }
};


  const handleCheck = (isThreeWord) => {
    const currentQuizzes = isThreeWord ? threeWordQuizzes : twoWordQuizzes;
    const quizData = currentQuizzes[isThreeWord ? currentThreeWordQuiz : currentTwoWordQuiz];
    const selectedWords = isThreeWord ? selectedThreeWords : selectedTwoWords;
    const feedbackSetter = isThreeWord ? setThreeWordFeedback : setTwoWordFeedback;
    const correctSetter = isThreeWord ? setIsThreeWordCorrect : setIsTwoWordCorrect;
    const quizSetter = isThreeWord ? setCurrentThreeWordQuiz : setCurrentTwoWordQuiz;
  
    let isValid = false;
    let unitType = '';
  
    if (Array.isArray(quizData.validUnits)) {
      isValid = quizData.validUnits.some(unit => unit.every((word, index) => word === selectedWords[index]));
      unitType = quizData.type;
    } else {
      for (const type in quizData.validUnits) {
        if (quizData.validUnits[type].some(unit => unit.every((word, index) => word === selectedWords[index]))) {
          isValid = true;
          unitType = type;
          break;
        }
      }
    }
  
    if (isValid) {
      feedbackSetter(`Correct! This is a valid ${unitType} unit.`);
      correctSetter(true);
      setTimeout(() => {
        const cardElement = document.querySelector(`.${Buildunitstyles[isThreeWord ? 'threeWordCard' : 'twoWordCard']}`);
        if (cardElement) {
          cardElement.classList.add(Buildunitstyles.slideOut);
        }
        setTimeout(() => {
          if ((isThreeWord ? currentThreeWordQuiz : currentTwoWordQuiz) < currentQuizzes.length - 1) {
            quizSetter((isThreeWord ? currentThreeWordQuiz : currentTwoWordQuiz) + 1);
            handleClear(isThreeWord);
            correctSetter(false);
            if (cardElement) {
              cardElement.classList.remove(Buildunitstyles.slideOut);
            }
          }
        }, 500);
      }, 1000);
    } else {
      feedbackSetter('Try again! The selected words do not form a valid unit.');
      const cardElement = document.querySelector(`.${Buildunitstyles[isThreeWord ? 'threeWordCard' : 'twoWordCard']}`);
      if (cardElement) {
        cardElement.classList.add(Buildunitstyles.shake);
      }
      setTimeout(() => {
        if (cardElement) {
          cardElement.classList.remove(Buildunitstyles.shake);
        }
      }, 500);
    }
  };

  return (
    <div className={Buildunitstyles.unitContainer}>
      <h2 className={Buildunitstyles.title}>Understanding Jueju Poetry Units</h2>

      <section className={Buildunitstyles.introduction}>
        <h3>What is a Poetry Unit in Jueju?</h3>
        <br></br>
        <p>In Jueju poetry units consist of a pair or trio of words that fit together into a single module. Later you will learn to balance and counter-balance these modules both horizontally and vertically, just like puzzle pieces!</p>
        <br></br>
        <p>In Classical Chinese poetry, single-syllable words are typically paired into interlocking “poetry units” of two or three single-syllable words. For instance, we can combine “white” and “stones” to create the poetry unit “white stones.”  This two-word poetry unit can then be combined with a three-word unit like  “cold+stream+flows.” In Chinese, this would look like: “白石 冷溪流” (:white stones cold stream flows”). This structure creates the proper rhythmic pattern for Jueju, known as semantic rhythm, where there should be a short pause between each unit, roughly as long as it takes to read the preceding unit.</p>
        <div className={Buildunitstyles.visuals}>
          <div className={Buildunitstyles.puzzleContainer}>
            <div className={`${Buildunitstyles.puzzlePiece} ${Buildunitstyles.piece1}`} style={{ backgroundImage: `url(${darkImage})` }}></div>
            <div className={`${Buildunitstyles.puzzlePiece} ${Buildunitstyles.piece2}`} style={{ backgroundImage: `url(${treesImage})` }}></div>
            <div className={Buildunitstyles.finalImage} style={{ backgroundImage: `url(${darkTreesImage})` }}></div>
          </div>
        </div>
        <p>For example: <strong>Dark Trees</strong> (where "Dark" describes "Trees")</p>
      </section>

      <section className={Buildunitstyles.explanation}>
        <h3>Valid 2-Word Units</h3>
        <br />
        <p>In Jueju poetry, 2-word units typically (but not always) follow the patterns below:</p>
        <br />
        <ul>
          <li><strong>Adjective + Noun:</strong> A descriptive word followed by the thing it describes. For example, "red sky" (“red” describes “sky”).</li>
          <br />
          <li><strong>Noun + Verb:</strong> A thing followed the action it performs. For example, "bird sings" (“bird” is doing the action sings).</li>
        </ul>
        <br />
        <p>Let's practice building some 2-word units!</p>
      </section>

      <section className={Buildunitstyles.quizzes}>
        <div className={Buildunitstyles.quizContainer}>
          <div className={`${Buildunitstyles.card} ${Buildunitstyles.twoWordCard} ${isTwoWordCorrect ? Buildunitstyles.correct : ''}`}>
            <div className="card-content">
              <h3>{currentTwoWordQuiz % 2 === 0 ? 'Build an Adjective + Noun Unit' : 'Build a Noun + Verb Unit'}</h3>
              <br></br>
              <p>Drag and drop the words to create a valid two-word unit:</p>
              <br></br>
              <div className={Buildunitstyles.wordsContainer}>
              {availableTwoWordOptions.map((word, index) => (
                  <div
                    key={index}
                    className={Buildunitstyles.word}
                    draggable
                    onDragStart={() => handleDragStart(word)}
                  >
                    {word}
                  </div>
                ))}
              </div>
              <div className={Buildunitstyles.dropContainer}>
                <div className={Buildunitstyles.dropSection}>
                  <div className={Buildunitstyles.dropArea}>
                    {[0, 1].map((index) => (
                      <div
                        key={index}
                        className={Buildunitstyles.dropBox}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={() => handleDrop(index, false)}
                        onClick={() => handleWordRemove(index, false)}
                      >
                        {selectedTwoWords[index] ? (
                        <div className={Buildunitstyles.word}>{selectedTwoWords[index]}</div>
                      ) : (
                        <span className={Buildunitstyles.placeholder}>{getPlaceholderText(index, false)}</span>
                      )}
                      </div>
                    ))}
                  </div>
                  <button onClick={() => handleCheck(false)} className={Buildunitstyles.submitButton}>Check</button>
                </div>
              </div>
              <button onClick={() => handleClear(false)} className={Buildunitstyles.clearButton}>Clear</button>
              {twoWordFeedback && <p className={Buildunitstyles.feedback}>{twoWordFeedback}</p>}
            </div>
          </div>
          <div className={Buildunitstyles.indicator}>
            {Array.from({ length: twoWordQuizzes.length }).map((_, index) => (
              <span
                key={index}
                className={`${Buildunitstyles.dot} ${currentTwoWordQuiz === index ? Buildunitstyles.active : ''}`}
              ></span>
            ))}
          </div>
        </div>
      </section>

      <section className={Buildunitstyles.explanation}>
        <h3>Valid 3-Word Units</h3>
        <br />
        <p>In Jueju poetry, 3-word units follow these patterns:</p>
        <br />
        <ul>
          <li><strong>Adjective + Noun + Verb:</strong> A descriptive word, followed the thing it describes, and the action it performs. For example, "bright sun shines" (“bright” describes “sun,” and “sun” does the action, “shines”).</li>
          <br />
          <li><strong>Adjective + Adjective + Noun:</strong> Two descriptive words followed by the thing they both describe. For example, "full bright moon" (“full” and “bright” both describe “moon).</li>
          <br />
          <li><strong>Verb + Adjective + Noun:</strong> An action often performed by a preceding unit is followed by a descriptive word and the thing it describes.  For example, something "finds lost dreams" (Finds is the action, “lost” describes “dreams”).</li>
          <br />
          <li><strong>Preposition + Adjective + Noun:</strong> A preposition, a descriptive word, and the thing it describes. For example, "through faint rain" (“through” is the preposition, “faint” describes “rain”).</li>
          <br />
          <li><strong>Verb + Preposition + Noun:</strong> An action, a preposition that orients the action, and then the thing the action is oriented to.  For example, "rests on trees" (“rests” is the action, and “on” is the preposition, while “trees” is the thing).</li>
        </ul>
        <br />
        <p>Let's practice building some 3-word units!</p>
      </section>

      <section className={Buildunitstyles.quizzes}>
        <div className={Buildunitstyles.quizContainer}>
          <div className={`${Buildunitstyles.card} ${Buildunitstyles.threeWordCard} ${isThreeWordCorrect ? Buildunitstyles.correct : ''}`}>
            <div className="card-content">
              <h3>{currentThreeWordQuiz === 0 ? 'Build an Adjective + Adjective + Noun Unit' : currentThreeWordQuiz === 1 ? 'Build an Adjective + Noun + Verb Unit (OR) Verb + Adjective + Noun Unit' : currentThreeWordQuiz === 2 ? 'Build a Verb + Preposition + Noun Unit' : 'Build a Preposition + Adjective + Noun Unit'}</h3>
              <br></br>
              <p>Drag and drop the words to create a valid three-word unit:</p>
              <br></br>
              <div className={Buildunitstyles.wordsContainer}>
              {availableThreeWordOptions.map((word, index) => (
                  <div
                    key={index}
                    className={Buildunitstyles.word}
                    draggable
                    onDragStart={() => handleDragStart(word)}
                  >
                    {word}
                  </div>
                ))}
              </div>
              <div className={Buildunitstyles.dropContainer}>
                <div className={Buildunitstyles.dropSection}>
                  <div className={Buildunitstyles.dropArea}>
                    {[0, 1, 2].map((index) => (
                      <div
                        key={index}
                        className={Buildunitstyles.dropBox}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={() => handleDrop(index, true)}
                        onClick={() => handleWordRemove(index, true)}
                      >
                        {selectedThreeWords[index] ? (
                          <div className={Buildunitstyles.word}>{selectedThreeWords[index]}</div>
                        ) : (
                          <div className={Buildunitstyles.placeholder}>{getPlaceholderText(index, true)}</div>
                        )}
                      </div>
                    ))}
                  </div>
                  <button onClick={() => handleCheck(true)} className={Buildunitstyles.submitButton}>Check</button>
                </div>
              </div>
              <button onClick={() => handleClear(true)} className={Buildunitstyles.clearButton}>Clear</button>
              {threeWordFeedback && <p className={Buildunitstyles.feedback}>{threeWordFeedback}</p>}
            </div>
          </div>
          <div className={Buildunitstyles.indicator}>
            {Array.from({ length: threeWordQuizzes.length }).map((_, index) => (
              <span
                key={index}
                className={`${Buildunitstyles.dot} ${currentThreeWordQuiz === index ? Buildunitstyles.active : ''}`}
              ></span>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BuildUnit;
