import React, { useState } from 'react';
import Rhymestyles from './Rhyme.module.css';

const quizzes = [
  {
    words: ['flows', 'twirl', 'stones', 'shell', 'streams'],
    options: ['blows', 'bell', 'cones', 'beams', 'whirl'],
    answers: {
      flows: ['blows'],
      twirl: ['whirl'],
      stones: ['cones'],
      shell: ['bell'],
      streams: ['beams']
    }
  },
];

const additionalQuizzes = [
  {
    type: 'fillInTheBlank',
    question: 'shade falls past soft ferns\ncreek twists through new ___',
    options: ['turns', 'hills', 'warns'],
    answer: 'turns'
  },
  {
    type: 'multipleChoice',
    question: 'Find the couplet that violates the end-rhyme scheme of jueju',
    options: [
      'grey mist calm breeze flows/\ndim glow soft cold cloud',
      'thick ice crisp snow cold wind bites/\nfresh start new world white cloaked sights',
    ],
    answer: 'grey mist calm breeze flows/\ndim glow soft cold cloud'
  },
  {
    type: 'multipleChoice',
    question: 'Find the couplet that violates the end-rhyme scheme of jueju',
    options: [
      'clear stream faint mist late sun wanes/\nfresh creek thin fog gray sky rains',
      'raw night cold mists flow/\npale moon sweet winds spin'
    ],
    answer: 'raw night cold mists flow/\npale moon sweet winds spin'
  }
];

const Rhyme = () => {
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [selectedWords, setSelectedWords] = useState({});
  const [feedback, setFeedback] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);

  const [currentAdditionalQuizIndex, setCurrentAdditionalQuizIndex] = useState(0);
  const [additionalFeedback, setAdditionalFeedback] = useState('');
  const [additionalSelectedWord, setAdditionalSelectedWord] = useState('');
  const [isAdditionalCorrect, setIsAdditionalCorrect] = useState(false);

  const currentQuiz = quizzes[currentQuizIndex];
  const currentAdditionalQuiz = additionalQuizzes[currentAdditionalQuizIndex];

  const handleDrop = (word, option) => {
    const newSelectedWords = { ...selectedWords, [word]: option };
    setSelectedWords(newSelectedWords);
  };

  const handleSubmit = () => {
    const isCorrect = currentQuiz.words.every(
      (word) => currentQuiz.answers[word].includes(selectedWords[word])
    );

    if (isCorrect) {
      setFeedback('Correct! Moving to the next quiz.');
      setIsCorrect(true);
      setTimeout(() => {
        const cardElement = document.querySelector(`.${Rhymestyles.quizCard}`);
        if (cardElement) {
          cardElement.classList.add(Rhymestyles.slideOut);
        }
        setTimeout(() => {
          if (currentQuizIndex < quizzes.length - 1) {
            setCurrentQuizIndex(currentQuizIndex + 1);
            setSelectedWords({});
            setFeedback('');
            setIsCorrect(false);
            if (cardElement) {
              cardElement.classList.remove(Rhymestyles.slideOut);
            }
          } else {
            setFeedback('Congratulations! You have completed all the quizzes.');
          }
        }, 500);
      }, 1000);
    } else {
      setFeedback('One or more words do not rhyme. Try again.');
      const cardElement = document.querySelector(`.${Rhymestyles.quizCard}`);
      if (cardElement) {
        cardElement.classList.add(Rhymestyles.shake);
      }
      setTimeout(() => {
        if (cardElement) {
          cardElement.classList.remove(Rhymestyles.shake);
        }
      }, 500);
    }
  };

  const handleAdditionalSubmit = () => {
    const isCorrect = currentAdditionalQuiz.type === 'fillInTheBlank'
      ? additionalSelectedWord === currentAdditionalQuiz.answer
      : additionalSelectedWord === currentAdditionalQuiz.answer;

    if (isCorrect) {
      setAdditionalFeedback('Correct! Moving to the next quiz.');
      setIsAdditionalCorrect(true);
      setTimeout(() => {
        const cardElement = document.querySelector(`.${Rhymestyles.additionalQuizCard}`);
        if (cardElement) {
          cardElement.classList.add(Rhymestyles.slideOut);
        }
        setTimeout(() => {
          if (currentAdditionalQuizIndex < additionalQuizzes.length - 1) {
            setCurrentAdditionalQuizIndex(currentAdditionalQuizIndex + 1);
            setAdditionalSelectedWord('');
            setAdditionalFeedback('');
            setIsAdditionalCorrect(false);
            if (cardElement) {
              cardElement.classList.remove(Rhymestyles.slideOut);
            }
          } else {
            setAdditionalFeedback('Congratulations! You have completed all the quizzes.');
          }
        }, 500);
      }, 1000);
    } else {
      setAdditionalFeedback('Incorrect answer. Try again.');
      const cardElement = document.querySelector(`.${Rhymestyles.additionalQuizCard}`);
      if (cardElement) {
        cardElement.classList.add(Rhymestyles.shake);
      }
      setTimeout(() => {
        if (cardElement) {
          cardElement.classList.remove(Rhymestyles.shake);
        }
      }, 500);
    }
  };

  const renderQuizContent = () => {
    return (
      <div className={Rhymestyles.quizContainer}>
        <div className={Rhymestyles.wordsContainer}>
          {currentQuiz.words.map((word, index) => (
            <div key={index} className={Rhymestyles.quizItem}>
              <div className={Rhymestyles.questionBox}>
                {word}
              </div>
              <div
                className={Rhymestyles.answerBox}
                onDrop={(e) => handleDrop(word, e.dataTransfer.getData('text'))}
                onDragOver={(e) => e.preventDefault()}
              >
                {selectedWords[word] && <div className={Rhymestyles.word}>{selectedWords[word]}</div>}
              </div>
            </div>
          ))}
        </div>
        <div className={Rhymestyles.optionsContainer}>
          {currentQuiz.options.map((option, index) => (
            <div
              key={index}
              className={Rhymestyles.option}
              draggable
              onDragStart={(e) => e.dataTransfer.setData('text', option)}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderAdditionalQuizContent = () => {
    if (currentAdditionalQuiz.type === 'fillInTheBlank') {
      return (
        <div className={Rhymestyles.additionalQuizContainer}>
          <div className={Rhymestyles.coupletContainer}>
            {currentAdditionalQuiz.question.split('\n').map((line, index) => (
              <div key={index}>
                {line}
                {index < currentAdditionalQuiz.question.split('\n').length - 1 && <br />}
              </div>
            ))}
            <div
              className={Rhymestyles.answerBox}
              onDrop={(e) => setAdditionalSelectedWord(e.dataTransfer.getData('text'))}
              onDragOver={(e) => e.preventDefault()}
            >
              {additionalSelectedWord && <div className={Rhymestyles.word}>{additionalSelectedWord}</div>}
            </div>
          </div>
          <div className={Rhymestyles.optionsContainer}>
            {currentAdditionalQuiz.options.map((option, index) => (
              <div
                key={index}
                className={Rhymestyles.option}
                draggable
                onDragStart={(e) => e.dataTransfer.setData('text', option)}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      );
    } else if (currentAdditionalQuiz.type === 'multipleChoice') {
      return (
        <div className={Rhymestyles.multipleChoiceCard}>
          <h3>{currentAdditionalQuiz.question}</h3>
          <div className={Rhymestyles.options}>
            {currentAdditionalQuiz.options.map((option, index) => (
              <button
                key={index}
                onClick={() => setAdditionalSelectedWord(option)}
                className={additionalSelectedWord === option ? Rhymestyles.selectedOption : ''}
              >
                {option.split('/').map((line, i) => (
                  <div key={i}>{line}</div>
                ))}
              </button>
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <div className={Rhymestyles.rhymeContainer}>
      <h2 className={Rhymestyles.title}>Learn End-Rhyming in Jueju</h2>

      {/* Explanation Section */}
      <section className={Rhymestyles.explanationSection}>
        <h3>Explanation: What are Rhyming Words?</h3>
        <br></br>
        <p>
        While the Art of jueju will require you to master five levels of rhyming, one of these levels includes rhyming in the conventional sense. Rhyming words are commonly understood as words that have the same ending sound. End-Rhymes are always used in Jueju poetry at the end of the first and second lines (as well as fourth lines covered in a later stage).  For example, two lines could end in the three word units: 
        </p>
        <p>
         … <strong>“thick grass grows”</strong> <br></br>
         … <strong>“brisk wind blows”</strong>
        </p>
        <br></br>
        <p>
        (“grows” and “blows” rhyme)
        </p>
   
        <ul>
          <li>When we learn how to properly recite and chant jueju the full effect of end-rhyming in jueju will become apparent </li>
        </ul>
      </section>

      {/* Quiz Section */}
      <section className={Rhymestyles.quizSection}>
        <h3>Rhyming Words</h3>
        <div className={`${Rhymestyles.quizCard} ${isCorrect ? Rhymestyles.correct : ''}`}>
          <div className="card-content">
            {renderQuizContent()}
            <button onClick={handleSubmit} className={Rhymestyles.submitButton}>Submit</button>
            {feedback && <p className={Rhymestyles.feedback}>{feedback}</p>}
          </div>
        </div>
        <div className={Rhymestyles.indicator}>
          {Array.from({ length: quizzes.length }).map((_, index) => (
            <span
              key={index}
              className={`${Rhymestyles.dot} ${currentQuizIndex === index ? Rhymestyles.active : ''}`}
            ></span>
          ))}
        </div>
      </section>

      {/* Additional Explanation Section */}
      <section className={Rhymestyles.explanationSection}>
        <h3>Explanation: End-Rhyming Rule in Jueju</h3>
        <br></br>
        <p>
        In Jueju poetry, the poem follows an AABA rhyme scheme, meaning that lines 1, 2, and 4 must rhyme, while line 3 should not.
        </p>
        <br></br>
        <p>
        For now, we focus on making the first couplet (lines 1 and 2) follow the end-rhyming scheme.
        </p>
        <br></br>
        <p>
          <strong>Important:</strong> There should not be any internal rhyming in Jueju. Only the end words of lines 1, 2, and 4 should rhyme. Internal rhymes (rhyming words within the lines) must be avoided.
        </p>
        <br></br>
        <p>For example:</p>
        <br></br>
        <ul>
          <li><strong>Sea gulls         float on         cool wet air</strong></li>
          <li><strong>Small boats        pass by         crowds that stare</strong></li>
        </ul>
        <br></br>
        <p><strong>Note:</strong> There shouldn't be slant rhymes; the rhyming should be true rhymes only.</p>
      </section>

      {/* Additional Quiz Section */}
      <section className={Rhymestyles.quizSection}>
        <h3>End-Rhyming in Couplet {currentAdditionalQuizIndex + 1} of {additionalQuizzes.length}</h3>
        <div className={`${Rhymestyles.additionalQuizCard} ${isAdditionalCorrect ? Rhymestyles.correct : ''}`}>
          <div className="card-content">
            {renderAdditionalQuizContent()}
            <button onClick={handleAdditionalSubmit} className={Rhymestyles.submitButton}>Submit</button>
            {additionalFeedback && <p className={Rhymestyles.feedback}>{additionalFeedback}</p>}
          </div>
        </div>
        <div className={Rhymestyles.indicator}>
          {Array.from({ length: additionalQuizzes.length }).map((_, index) => (
            <span
              key={index}
              className={`${Rhymestyles.dot} ${currentAdditionalQuizIndex === index ? Rhymestyles.active : ''}`}
            ></span>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Rhyme;
