import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus, FaTrash, FaEye, FaUserPlus } from 'react-icons/fa';

const TeacherDashboard = () => {
  const [students, setStudents] = useState([]);
  const [newStudentName, setNewStudentName] = useState('');
  const [newStudentID, setNewStudentID] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  const API_URL = 'http://3.17.190.79:8000';

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    try {
      const response = await axios.get(`${API_URL}/teacher/students/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setStudents(response.data.students);
    } catch (error) {
      console.error('Error fetching students:', error);
      setErrorMessage('Failed to fetch students.');
    }
  };

  const handleAddStudent = async () => {
    if (!newStudentName || !newStudentID) {
      setErrorMessage('Please enter both name and ID for the student.');
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}/teacher/add-student/`,
        { name: newStudentName, student_id: newStudentID },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setSuccessMessage(`Student ${response.data.name} added successfully!`);
      setErrorMessage('');
      setNewStudentName('');
      setNewStudentID('');
      fetchStudents();
    } catch (error) {
      console.error('Error adding student:', error);
      setErrorMessage(
        error.response?.data?.detail || 'Failed to add student. Please try again.'
      );
    }
  };

  const handleDeleteStudent = async (studentId) => {
    try {
      await axios.delete(`${API_URL}/teacher/delete-student/${studentId}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setSuccessMessage('Student deleted successfully.');
      fetchStudents();
    } catch (error) {
      console.error('Error deleting student:', error);
      setErrorMessage('Failed to delete student. Please try again.');
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.title}>Teacher Dashboard</h1>
        <p style={styles.studentCount}>Total Students: {students.length}</p>
      </div>

      <div style={styles.mainContent}>
        <div style={styles.sidebar}>
          <h2 style={styles.sidebarTitle}>Manage Students</h2>
          <div style={styles.addStudentContainer}>
            <input
              type="text"
              placeholder="Student Name"
              value={newStudentName}
              onChange={(e) => setNewStudentName(e.target.value)}
              style={styles.input}
            />
            <input
              type="text"
              placeholder="Student ID"
              value={newStudentID}
              onChange={(e) => setNewStudentID(e.target.value)}
              style={styles.input}
            />
            <button onClick={handleAddStudent} style={styles.addButton}>
              <FaUserPlus /> Add Student
            </button>
          </div>
          {errorMessage && <p style={{ ...styles.message, ...styles.error }}>{errorMessage}</p>}
          {successMessage && <p style={{ ...styles.message, ...styles.success }}>{successMessage}</p>}
        </div>

        <div style={styles.studentListContainer}>
          {students.map((student) => (
            <div key={student.student_id} style={styles.studentCard}>
              <div style={styles.studentInfo}>
                <h3 style={styles.studentName}>{student.name}</h3>
                <p style={styles.studentDetails}>ID: {student.student_id}</p>
                <p style={styles.studentDetails}>Group: {student.group_name}</p>
              </div>
              <div style={styles.progressSection}>
                <h4>Progress</h4>
                <div style={styles.progressRoute}>
                  {['Tongsheng', 'Xiucai', 'Juren', 'Gongshi'].map((level, index) => (
                    <React.Fragment key={index}>
                      <div
                        style={{
                          ...styles.dot,
                          backgroundColor: student.progress?.[level] ? '#4caf50' : '#e0e0e0',
                        }}
                      />
                      {index < 3 && <div style={styles.line} />} {/* Adds line between dots */}
                    </React.Fragment>
                  ))}
                </div>
                <div style={styles.progressLabels}>
                  {['Tongsheng', 'Xiucai', 'Juren', 'Gongshi'].map((level, index) => (
                    <p key={index} style={styles.levelLabel}>{level}</p>
                  ))}
                </div>
              </div>
              <div style={styles.studentActions}>
                <button style={styles.viewButton}>
                  <FaEye /> View Submissions
                </button>
                <button onClick={() => handleDeleteStudent(student.student_id)} style={styles.deleteButton}>
                  <FaTrash /> Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Roboto, sans-serif',
    backgroundColor: '#f5f6fa',
    padding: '40px',
    maxWidth: '1400px',
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#4a4e69',
    color: '#ffffff',
    borderRadius: '8px',
    marginBottom: '30px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
  },
  title: {
    fontSize: '2rem',
  },
  studentCount: {
    fontSize: '1.2rem',
  },
  mainContent: {
    display: 'flex',
  },
  sidebar: {
    width: '300px',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    marginRight: '20px',
  },
  sidebarTitle: {
    fontSize: '1.5rem',
    color: '#333',
    marginBottom: '20px',
  },
  addStudentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '10px',
    fontSize: '1rem',
    marginBottom: '10px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    outline: 'none',
  },
  addButton: {
    backgroundColor: '#4caf50',
    color: '#ffffff',
    border: 'none',
    padding: '10px',
    borderRadius: '5px',
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    transition: 'background 0.3s ease',
  },
  message: {
    fontSize: '1rem',
    padding: '10px',
    textAlign: 'center',
    borderRadius: '6px',
    marginTop: '10px',
  },
  error: {
    backgroundColor: '#ffcccc',
    color: '#cc0000',
  },
  success: {
    backgroundColor: '#ccffcc',
    color: '#009900',
  },
  studentListContainer: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '20px',
  },
  studentCard: {
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  studentInfo: {
    marginBottom: '15px',
  },
  studentName: {
    fontSize: '1.5rem',
    color: '#333',
  },
  studentDetails: {
    fontSize: '1rem',
    color: '#777',
  },
  progressSection: {
    marginTop: '10px',
    width: '100%',
    textAlign: 'center',
  },
  progressRoute: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    position: 'relative',
    width: '100%',
  },
  dot: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: '#e0e0e0',
    transition: 'background-color 0.3s ease',
  },
  line: {
    height: '4px',
    backgroundColor: '#ccc',
    flex: 1,
    margin: '0 8px',
  },
  progressLabels: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '5px',
  },
  levelLabel: {
    fontSize: '0.8rem',
    color: '#333',
  },
  studentActions: {
    display: 'flex',
    gap: '10px',
    marginTop: '20px',
  },
  viewButton: {
    backgroundColor: '#007bff',
    color: '#ffffff',
    border: 'none',
    padding: '8px 12px',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  deleteButton: {
    backgroundColor: '#dc3545',
    color: '#ffffff',
    border: 'none',
    padding: '8px 12px',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
};

export default TeacherDashboard;

