import React, { useState, useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';
import modalStyles from './Modal.module.css';
import PasswordModal from './PasswordModal';
const API_URL = 'https://juejupath.com/api';

const AccountModal = ({ closeModal }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState(''); // Feedback message

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${API_URL}/user/profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        setName(data.name);
        setEmail(data.email);
      } catch (error) {
        setFeedbackMessage('Error fetching user profile');
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleSave = async () => {
    try {
      const response = await fetch(`${API_URL}/user/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ name, email, password: '' }),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      setFeedbackMessage('Profile updated successfully');
      setIsEditingName(false);
      setIsEditingEmail(false);
      setShowSaveButton(false);
    } catch (error) {
      setFeedbackMessage('Error saving user profile');
      console.error('Error saving user profile:', error);
    }
  };

  const handlePasswordChange = () => {
    setShowPasswordModal(true);
  };

  const closePasswordModal = () => {
    setShowPasswordModal(false);
  };

  const handleEdit = (field) => {
    if (field === 'name') {
      setIsEditingName(true);
    } else if (field === 'email') {
      setIsEditingEmail(true);
    }
    setShowSaveButton(true);
  };

  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modalContent}>
        <span className={modalStyles.closeButton} onClick={closeModal}>&times;</span>
        <h2 className={modalStyles.title}>Edit Account</h2>

        <div className={modalStyles.fieldContainer}>
          <label>Name: </label>
          {isEditingName ? (
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={modalStyles.inputField}
            />
          ) : (
            <span className={modalStyles.fieldValue}>{name}</span>
          )}
          <FaEdit
            className={modalStyles.editIcon}
            onClick={() => handleEdit('name')}
          />
        </div>

        <div className={modalStyles.fieldContainer}>
          <label>Email: </label>
          {isEditingEmail ? (
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={modalStyles.inputField}
            />
          ) : (
            <span className={modalStyles.fieldValue}>{email}</span>
          )}
          <FaEdit
            className={modalStyles.editIcon}
            onClick={() => handleEdit('email')}
          />
        </div>

        <div className={modalStyles.fieldContainer}>
          <label>Password: </label>
          <span className={modalStyles.fieldValue}>********</span>
          <FaEdit
            className={modalStyles.editIcon}
            onClick={handlePasswordChange}
          />
        </div>

        {showSaveButton && (
          <button className={modalStyles.saveButton} onClick={handleSave}>
            Save Changes
          </button>
        )}
        {feedbackMessage && (
          <p className={modalStyles.feedbackMessage}>{feedbackMessage}</p>
        )}
      </div>

      {showPasswordModal && <PasswordModal closeModal={closePasswordModal} />}
    </div>
  );
};

export default AccountModal;

