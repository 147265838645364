import React, { useState } from 'react';
import modalStyles from './Modal.module.css';

// Make sure you're correctly using the environment variable
const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

const PasswordModal = ({ closeModal }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState(''); // Feedback message
  const [isError, setIsError] = useState(false); // Track if the feedback is an error

  const handleSavePassword = async () => {
    try {
      const url = new URL(`${API_URL}/user/change-password`); // Use the API_URL from .env
      url.searchParams.append('current_password', currentPassword);
      url.searchParams.append('new_password', newPassword);
  
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json(); // Get the error message from the response
        if (response.status === 400 && errorData.detail === "Incorrect current password") {
          setIsError(true);
          setFeedbackMessage('Incorrect current password');
        } else {
          setIsError(true);
          setFeedbackMessage('Failed to change password');
        }
        return;
      }

      setIsError(false);
      setFeedbackMessage('Password updated successfully');
      setCurrentPassword('');
      setNewPassword('');

      // Optionally, close the modal after a delay to show the success message briefly
      setTimeout(() => {
        closeModal();
      }, 2000);
    } catch (error) {
      setIsError(true);
      setFeedbackMessage('Error changing password');
      console.error('Error changing password:', error);
    }
  };

  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modalContent}>
        <span className={modalStyles.closeButton} onClick={closeModal}>&times;</span>
        <h2>Change Password</h2>
        <label>Current Password</label>
        <input 
          type="password" 
          value={currentPassword} 
          onChange={(e) => setCurrentPassword(e.target.value)} 
        />
        <label>New Password</label>
        <input 
          type="password" 
          value={newPassword} 
          onChange={(e) => setNewPassword(e.target.value)} 
        />
        <button onClick={handleSavePassword}>Save</button>
        {feedbackMessage && (
          <p className={`${modalStyles.feedbackMessage} ${isError ? modalStyles.errorMessage : ''}`}>
            {feedbackMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default PasswordModal;

