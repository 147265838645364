import React, { useState } from 'react';
import PingZestyles from './PingZe.module.css';
import PingZeExplanation from './PingZeExplanation';


const newWords = {
  ze: ['beach', 'frost', 'oak', 'grass', 'peach'],
  ping: ['fog', 'rain', 'snow', 'clouds', 'hail'],
};

const newWordList = {
  ze: ['sky', 'beach', 'sand', 'frost', 'oak', 'pine', 'grass', 'peach'],
  ping: ['fog', 'mist', 'rain', 'sleet', 'snow', 'hail', 'clouds'],
};

// New Quiz Component
const NewQuiz = () => {
  const [draggedWord, setDraggedWord] = useState(null);
  const [pingWords, setPingWords] = useState([]);
  const [zeWords, setZeWords] = useState([]);
  const [results, setResults] = useState(null);

  const handleDragStart = (word) => {
    setDraggedWord(word);
  };

  const handleDrop = (type) => {
    if (draggedWord) {
      if (type === 'ping') {
        if (!pingWords.includes(draggedWord) && !zeWords.includes(draggedWord)) {
          setPingWords([...pingWords, draggedWord]);
        }
      } else {
        if (!zeWords.includes(draggedWord) && !pingWords.includes(draggedWord)) {
          setZeWords([...zeWords, draggedWord]);
        }
      }
    }
    setDraggedWord(null);
  };

  const handleCheck = () => {
    let correctPing = 0;
    let correctZe = 0;

    pingWords.forEach((word) => {
      if (newWords.ping.includes(word)) {
        correctPing++;
      }
    });

    zeWords.forEach((word) => {
      if (newWords.ze.includes(word)) {
        correctZe++;
      }
    });

    setResults({
      correctPing,
      correctZe,
      totalPing: pingWords.length,
      totalZe: zeWords.length,
      totalWords: newWords.ping.length + newWords.ze.length,
    });
  };

  const handleRetry = () => {
    setPingWords([]);
    setZeWords([]);
    setResults(null);
  };

  const speakWord = (word) => {
    const utterance = new SpeechSynthesisUtterance(word);
    window.speechSynthesis.speak(utterance);
  };

  return (
    <section className={PingZestyles.quizSection}>
      <h3>New Quiz: Classify the Words as Ping(○) or Ze(●)</h3>
      <div className={PingZestyles.newQuizContainer}>
        <div className={PingZestyles.quizInnerContainer}>
          <div className={PingZestyles.quizBox}>
            <h4>Select the Ze(●) Words:</h4>
            <div className={PingZestyles.wordList}>
              {newWordList.ze.map((word, index) => (
                <div
                  key={index}
                  className={PingZestyles.draggableWord}
                  draggable
                  onDragStart={() => handleDragStart(word)}
                >
                  {word}
                  <span onClick={() => speakWord(word)} role="button" className={PingZestyles.speakerIcon}>🔊</span>
                </div>
              ))}
            </div>
            <div className={PingZestyles.dropbox} onDrop={() => handleDrop('ze')} onDragOver={(e) => e.preventDefault()}>
              <h4>Drop Ze Words Here(●):</h4>
              <div className={PingZestyles.droppedWords}>
                {zeWords.map((word, index) => (
                  <div key={index} className={PingZestyles.droppedWord}>
                    {word}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className={PingZestyles.quizInnerContainer}>
          <div className={PingZestyles.quizBox}>
            <h4>Select the Ping(○) Words:</h4>
            <div className={PingZestyles.wordList}>
              {newWordList.ping.map((word, index) => (
                <div
                  key={index}
                  className={PingZestyles.draggableWord}
                  draggable
                  onDragStart={() => handleDragStart(word)}
                >
                  {word}
                  <span onClick={() => speakWord(word)} role="button" className={PingZestyles.speakerIcon}>🔊</span>
                </div>
              ))}
            </div>
            <div className={PingZestyles.dropbox} onDrop={() => handleDrop('ping')} onDragOver={(e) => e.preventDefault()}>
              <h4>Drop Ping Words Here(○):</h4>
              <div className={PingZestyles.droppedWords}>
                {pingWords.map((word, index) => (
                  <div key={index} className={PingZestyles.droppedWord}>
                    {word}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={PingZestyles.buttonContainer}>
        <button className={PingZestyles.checkButton} onClick={handleCheck}>
          Check
        </button>
        {results && (
          <button className={PingZestyles.retryButton} onClick={handleRetry}>
            Retry
          </button>
        )}
      </div>

      {results && (
        <div className={PingZestyles.results}>
          <h4>Results</h4>
          <p>Correct Ping Words: {results.correctPing} / {results.totalPing}</p>
          <p>Correct Ze Words: {results.correctZe} / {results.totalZe}</p>
          <p>Total Correct: {results.correctPing + results.correctZe} / {results.totalWords}</p>
        </div>
      )}
    </section>
  );
};
const AdditionalQuiz = () => {
  const zeWordList = ['flows', 'sips', 'floats', 'waits', 'birds', 'trees', 'ways'];
  const pingWordList = ['limbs', 'sticks', 'twigs', 'laughs', 'leaves', 'stems'];

  const correctZeWords = ['sips', 'floats', 'waits'];
  const correctPingWords = ['limbs', 'twigs', 'leaves', 'stems'];

  const [draggedWord, setDraggedWord] = useState(null);
  const [pingWords, setPingWords] = useState([]);
  const [zeWords, setZeWords] = useState([]);
  const [results, setResults] = useState(null);

  const handleDragStart = (word) => {
    setDraggedWord(word);
  };

  const handleDrop = (type) => {
    if (draggedWord) {
      if (type === 'ping') {
        if (!pingWords.includes(draggedWord) && !zeWords.includes(draggedWord)) {
          setPingWords([...pingWords, draggedWord]);
        }
      } else {
        if (!zeWords.includes(draggedWord) && !pingWords.includes(draggedWord)) {
          setZeWords([...zeWords, draggedWord]);
        }
      }
    }
    setDraggedWord(null);
  };

  const handleCheck = () => {
    let correctPing = 0;
    let correctZe = 0;

    pingWords.forEach((word) => {
      if (correctPingWords.includes(word)) {
        correctPing++;
      }
    });

    zeWords.forEach((word) => {
      if (correctZeWords.includes(word)) {
        correctZe++;
      }
    });

    setResults({
      correctPing,
      correctZe,
      totalPing: pingWords.length,
      totalZe: zeWords.length,
      totalWords: correctPingWords.length + correctZeWords.length,
    });
  };

  const handleRetry = () => {
    setPingWords([]);
    setZeWords([]);
    setResults(null);
  };

  const speakWord = (word) => {
    const utterance = new SpeechSynthesisUtterance(word);
    window.speechSynthesis.speak(utterance);
  };

  return (
    <section className={PingZestyles.quizSection}>
      <h3>Additional Quiz: Classify the Words as Ping(○) or Ze(●)</h3>
      <div className={PingZestyles.newQuizContainer}>
        <div className={PingZestyles.quizInnerContainer}>
          <div className={PingZestyles.quizBox}>
            <h4>Select the Ze Words(●):</h4>
            <div className={PingZestyles.wordList}>
              {zeWordList.map((word, index) => (
                <div
                  key={index}
                  className={PingZestyles.draggableWord}
                  draggable
                  onDragStart={() => handleDragStart(word)}
                >
                  {word}
                  <span onClick={() => speakWord(word)} role="button" className={PingZestyles.speakerIcon}>🔊</span>
                </div>
              ))}
            </div>
            <div className={PingZestyles.dropbox} onDrop={() => handleDrop('ze')} onDragOver={(e) => e.preventDefault()}>
              <h4>Drop Ze Words Here(●):</h4>
              <div className={PingZestyles.droppedWords}>
                {zeWords.map((word, index) => (
                  <div key={index} className={PingZestyles.droppedWord}>
                    {word}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className={PingZestyles.quizInnerContainer}>
          <div className={PingZestyles.quizBox}>
            <h4>Select the Ping Words(○):</h4>
            <div className={PingZestyles.wordList}>
              {pingWordList.map((word, index) => (
                <div
                  key={index}
                  className={PingZestyles.draggableWord}
                  draggable
                  onDragStart={() => handleDragStart(word)}
                >
                  {word}
                  <span onClick={() => speakWord(word)} role="button" className={PingZestyles.speakerIcon}>🔊</span>
                </div>
              ))}
            </div>
            <div className={PingZestyles.dropbox} onDrop={() => handleDrop('ping')} onDragOver={(e) => e.preventDefault()}>
              <h4>Drop Ping Words Here(○):</h4>
              <div className={PingZestyles.droppedWords}>
                {pingWords.map((word, index) => (
                  <div key={index} className={PingZestyles.droppedWord}>
                    {word}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={PingZestyles.buttonContainer}>
        <button className={PingZestyles.checkButton} onClick={handleCheck}>
          Check
        </button>
        {results && (
          <button className={PingZestyles.retryButton} onClick={handleRetry}>
            Retry
          </button>
        )}
      </div>

      {results && (
        <div className={PingZestyles.results}>
          <h4>Results</h4>
          <p>Correct Ping Words: {results.correctPing} / {results.totalPing}</p>
          <p>Correct Ze Words: {results.correctZe} / {results.totalZe}</p>
          <p>Total Correct: {results.correctPing + results.correctZe} / {results.totalWords}</p>
        </div>
      )}
    </section>
  );
};

// Unit Selection Quiz Component for Ping-Ping and Ze-Ze Units
const UnitSelectionQuiz = () => {
  const questions = [
    {
      question: 'Select the Ping-Ping Two-Word Units:',
      options: ['wet grass', 'tall reeds', 'swift brook', 'light mist', 'gray fog'],
      correctAnswers: ['tall reeds', 'gray fog'],
    },
    {
      question: 'Select the Ze-Ze Two-Word Units:',
      options: ['green fields', 'mossed path', 'last leaf', 'gold leaves', 'streams flow'],
      correctAnswers: ['mossed path', 'last leaf'],
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [results, setResults] = useState(null);

  const toggleOption = (option) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(option)
        ? prevSelected.filter((opt) => opt !== option)
        : [...prevSelected, option]
    );
  };

  const handleCheck = () => {
    const correctAnswers = questions.flatMap(q => q.correctAnswers);
    const correctCount = selectedOptions.filter((opt) => correctAnswers.includes(opt)).length;
    const totalCorrect = correctAnswers.length;
    const score = `${correctCount} / ${totalCorrect}`;
    const isCorrect = correctCount === totalCorrect && selectedOptions.length === totalCorrect;

    setResults({ score, isCorrect });
  };

  const handleRetry = () => {
    setSelectedOptions([]);
    setResults(null);
  };

  return (
    <section className={PingZestyles.quizSection}>
      <h3>Unit Selection Quiz</h3>
      <div className={PingZestyles.unitSelectionContainer}>
        {questions.map((q, index) => (
          <div key={index} className={PingZestyles.unitSelectionBox}>
            <h4>{q.question}</h4>
            <div className={PingZestyles.multipleChoiceOptions}>
              {q.options.map((option, idx) => (
                <div
                  key={idx}
                  className={`${PingZestyles.option} ${selectedOptions.includes(option) ? PingZestyles.selected : ''}`}
                  onClick={() => toggleOption(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className={PingZestyles.buttonContainer}>
        <button className={PingZestyles.checkButton} onClick={handleCheck}>
          Check
        </button>
        {results && (
          <button className={PingZestyles.retryButton} onClick={handleRetry}>
            Retry
          </button>
        )}
      </div>
      {results && (
        <div className={PingZestyles.results}>
          <h4>Results: {results.score}</h4>
          {results.isCorrect ? (
            <p style={{ color: 'green' }}>All correct!</p>
          ) : (
            <p style={{ color: 'red' }}>Some selections are incorrect. Try again!</p>
          )}
        </div>
      )}
    </section>
  );
};
// Drag and Drop Quiz Component

// Updated Drag and Drop Quiz Component with wider dropbox for the three-word unit
const DragAndDropQuiz = () => {
  const units = ['wet path', 'sea brine salt', 'smooth stones', 'dark salt brine'];

  // The correct answers in the required order
  const correctAnswers = [
    'wet path',          // Second unit in the first line
    'smooth stones',     // Second unit in the second line
    'dark salt brine'    // Third unit in the second line
  ];

  const [draggedUnit, setDraggedUnit] = useState(null);
  const [selectedUnits, setSelectedUnits] = useState(Array(3).fill(''));
  const [results, setResults] = useState(null);

  const handleDragStart = (unit) => {
    setDraggedUnit(unit);
  };

  const handleDrop = (index) => {
    if (draggedUnit) {
      const newSelectedUnits = [...selectedUnits];
      newSelectedUnits[index] = draggedUnit;
      setSelectedUnits(newSelectedUnits);
      setDraggedUnit(null);
    }
  };

  const handleCheck = () => {
    const isCorrect = selectedUnits.every((unit, index) => unit === correctAnswers[index]);
    setResults(isCorrect);
  };

  const handleRetry = () => {
    setSelectedUnits(Array(3).fill(''));
    setResults(null);
  };

  return (
    <section className={PingZestyles.quizSection}>
      <h3>Now drag and drop the parallel units into the ping-start regulated couplet below:</h3>

      {/* Tonal pattern with circles and labels */}
      <div className={PingZestyles.tonalPatternsContainer}>
        <div className={PingZestyles.unitGroup}>
          {/* First row: circles */}
          <div className={PingZestyles.circleRow}>
            <span className={`${PingZestyles.circle} ${PingZestyles.emptyCircle}`}></span>
            <span className={`${PingZestyles.circle} ${PingZestyles.emptyCircle}`}></span>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
            <span className={`${PingZestyles.circle} ${PingZestyles.filledCircle}`}></span>
            <span className={`${PingZestyles.circle} ${PingZestyles.filledCircle}`}></span>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
            <span className={`${PingZestyles.circle} ${PingZestyles.filledCircle}`}></span>
            <span className={`${PingZestyles.circle} ${PingZestyles.emptyCircle}`}></span>
            <span className={`${PingZestyles.circle} ${PingZestyles.emptyCircle}`}></span>
          </div>
          {/* Second row: Ping/Ze labels */}
          <div className={PingZestyles.wordRow}>
            <span className={PingZestyles.ping}>Ping</span>
            <span className={PingZestyles.ping}>Ping</span>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
            <span className={PingZestyles.ze}>Ze</span>
            <span className={PingZestyles.ze}>Ze</span>
            &nbsp;&nbsp;&nbsp;
            <span className={PingZestyles.ze}>Ze</span>
            <span className={PingZestyles.ping}>Ping</span>
            <span className={PingZestyles.ping}>Ping</span>
          </div>
        </div>

        <div className={PingZestyles.unitGroup}>
          {/* Second row: circles */}
          <div className={PingZestyles.circleRow}>
            <span className={`${PingZestyles.circle} ${PingZestyles.filledCircle}`}></span>
            <span className={`${PingZestyles.circle} ${PingZestyles.filledCircle}`}></span>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
            <span className={`${PingZestyles.circle} ${PingZestyles.emptyCircle}`}></span>
            <span className={`${PingZestyles.circle} ${PingZestyles.emptyCircle}`}></span>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
            <span className={`${PingZestyles.circle} ${PingZestyles.filledCircle}`}></span>
            <span className={`${PingZestyles.circle} ${PingZestyles.filledCircle}`}></span>
            <span className={`${PingZestyles.circle} ${PingZestyles.emptyCircle}`}></span>
          </div>
          {/* Second row: Ping/Ze labels */}
          <div className={PingZestyles.wordRow}>
            <span className={PingZestyles.ze}>Ze</span>
            <span className={PingZestyles.ze}>Ze</span>
            &nbsp;&nbsp;&nbsp;
            <span className={PingZestyles.ping}>Ping</span>
            <span className={PingZestyles.ping}>Ping</span>
            &nbsp;&nbsp;&nbsp;
            <span className={PingZestyles.ze}>Ze</span>
            <span className={PingZestyles.ze}>Ze</span>
            <span className={PingZestyles.ping}>Ping</span>
          </div>
        </div>
      </div>

      {/* Drag-and-drop blanks */}
      <div className={PingZestyles.dragPatternContainer}>
        {/* First line - Pre-filled first and last units */}
        <div className={PingZestyles.dragPatternRow}>
          <div className={PingZestyles.prefilledWord}>Cool breeze</div>
          <div className={`${PingZestyles.dropbox} ${PingZestyles.twoWordDropBox}`} onDrop={() => handleDrop(0)} onDragOver={(e) => e.preventDefault()}>
            <div className={PingZestyles.droppedWord}>{selectedUnits[0]}</div>
          </div>
          <div className={PingZestyles.prefilledWord}>faint stars shine</div>
        </div>

        {/* Second line - Pre-filled first unit, blanks for second and third units */}
        <div className={PingZestyles.dragPatternRow}>
          <div className={PingZestyles.prefilledWord}>Quick gust</div>
          <div className={`${PingZestyles.dropbox} ${PingZestyles.twoWordDropBox}`} onDrop={() => handleDrop(1)} onDragOver={(e) => e.preventDefault()}>
            <div className={PingZestyles.droppedWord}>{selectedUnits[1]}</div>
          </div>
          <div className={`${PingZestyles.dropbox} ${PingZestyles.threeWordDropBox}`} onDrop={() => handleDrop(2)} onDragOver={(e) => e.preventDefault()}>
            <div className={PingZestyles.droppedWord}>{selectedUnits[2]}</div>
          </div>
        </div>
      </div>

      {/* Draggable options */}
      <div className={PingZestyles.wordList}>
        {units.map((unit, index) => (
          <div
            key={index}
            className={PingZestyles.draggableWord}
            draggable
            onDragStart={() => handleDragStart(unit)}
          >
            {unit}
          </div>
        ))}
      </div>

      {/* Check and Retry Buttons */}
      <div className={PingZestyles.buttonContainer}>
        <button className={PingZestyles.checkButton} onClick={handleCheck}>
          Check
        </button>
        {results !== null && (
          <button className={PingZestyles.retryButton} onClick={handleRetry}>
            Retry
          </button>
        )}
      </div>

      {/* Results */}
      {results !== null && (
        <div className={PingZestyles.results}>
          <h4>Results</h4>
          {results ? (
            <p style={{ color: 'green' }}>All correct!</p>
          ) : (
            <p style={{ color: 'red' }}>Some selections are incorrect. Try again!</p>
          )}
        </div>
      )}
    </section>
  );
};

export default function PingZe() {
  const patterns = {
    "five_ze_start": {
      "line1": ["ze", "ze", "ze", "ping", "ping"],
      "line2": ["ping", "ping", "ze", "ze", "ping"],
    },
    "five_ping_start": {
      "line1": ["ping", "ping", "ze", "ze", "ping"],
      "line2": ["ze", "ze", "ze", "ping", "ping"],
    },
    "seven_ze_start": {
      "line1": ["ze", "ze", "ping", "ping", "ze", "ze", "ping"],
      "line2": ["ping", "ping", "ze", "ze", "ze", "ping", "ping"],
    },
    "seven_ping_start": {
      "line1": ["ping", "ping", "ze", "ze", "ze", "ping", "ping"],
      "line2": ["ze", "ze", "ping", "ping", "ze", "ze", "ping"],
    },
  };

  const renderPattern = (pattern) => {
    return (
      <div className={PingZestyles.tonalPatternsContainer}>
        {Object.values(pattern).map((line, index) => (
          <div key={index} className={PingZestyles.tonalPatternUnitContainer}>
            {/* Group for circles and words in the 5-word or 7-word pattern */}
            <div className={PingZestyles.unitGroup}>
              {/* Row of circles */}
              <div className={PingZestyles.circleRow}>
                {line.slice(0, 2).map((type, idx) => (
                  <span
                    key={idx}
                    className={`${PingZestyles.circle} ${
                      type === "ze"
                        ? PingZestyles.filledCircle
                        : PingZestyles.emptyCircle
                    }`}
                  ></span>
                ))}
                &nbsp;&nbsp;&nbsp;
              </div>
              {/* Row of Ping/Ze words with a space between them */}
              <div className={PingZestyles.wordRow}>
                {line.slice(0, 2).map((type, idx) => (
                  <span key={idx} className={PingZestyles[type]}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </span>
                ))}
                &nbsp;&nbsp;
              </div>
            </div>
  
            {/* If it's a 7-word line, render the middle 2-word block */}
            {line.length === 7 && (
              <div className={PingZestyles.unitGroup}>
                <div className={PingZestyles.circleRow}>
                  {line.slice(2, 4).map((type, idx) => (
                    <span
                      key={idx}
                      className={`${PingZestyles.circle} ${
                        type === "ze"
                          ? PingZestyles.filledCircle
                          : PingZestyles.emptyCircle
                      }`}
                    ></span>
                  ))}
                  &nbsp;&nbsp;&nbsp;
                </div>
                <div className={PingZestyles.wordRow}>
                  {line.slice(2, 4).map((type, idx) => (
                    <span key={idx} className={PingZestyles[type]}>
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </span>
                  ))}
                  &nbsp;&nbsp;
                </div>
              </div>
            )}
  
            {/* Last group for the remaining words */}
            <div className={PingZestyles.unitGroup}>
              <div className={PingZestyles.circleRow}>
                {line.slice(line.length === 5 ? 2 : 4).map((type, idx) => (
                  <span
                    key={idx}
                    className={`${PingZestyles.circle} ${
                      type === "ze"
                        ? PingZestyles.filledCircle
                        : PingZestyles.emptyCircle
                    }`}
                  ></span>
                ))}
              </div>
              <div className={PingZestyles.wordRow}>
                {line.slice(line.length === 5 ? 2 : 4).map((type, idx) => (
                  <span key={idx} className={PingZestyles[type]}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </span>
                ))}
                &nbsp;&nbsp;
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  
   return (
    <div className={PingZestyles.pingZeContainer}>
      {/* Original Explanation Section: What is Ping and Ze */}
      <section className={PingZestyles.explanationSection}>
        <h3>Vowel Duration Rhyming/Parallelism</h3>
        <p>
          In classical Chinese poetry, <strong>Ping (平)</strong> refers to level tones with a longer duration and a steady pitch, while <strong>Ze (仄)</strong> shorter, falling and entering tones.  The balance between these tones is crucial for achieving the rhythmic flow and musicality that define forms like <em>jueju</em>.
        </p>
      </section>

      {/* Understanding Ping and Ze Section */}
      <section className={PingZestyles.explanationSection}>
        <h3>Understanding Ping(○) and Ze(●)</h3>
        <p>
          In classical Chinese, a single syllable word that ends in the consonants “p, t, k” is called a “ze”” (●)  word because these consonants at the end of a word shorten the duration of its vowel by roughly half. Ze words are marked with the symbol (●) because it represents a “yin” sound. 
        </p>
        <div className={PingZestyles.highlightedContainer}>
          <p>
            Try saying the word “cap,” then say the word “cab.” Can you hear that the first word is pronounced more quickly than the second? Now try “lit” vs. “lid.” Can you hear that the first is pronounced more quicker than the second?
          </p>
        </div>
        <p>
        This phenomenon is called “allophonic clipping,” and it occurs in both Chinewse and English single syllable words. However, English has more “ze” word endings than Chnese and includes: “p, t, k, s, ch, f, sh,” and unvoiced “th.” All other word endings result in a “ping” (○) word.
        </p>
        <p>
        In the tonal patterns, a dark circle (●) represents Ze, and light circle (○) represents Ping.
        </p>
      </section>
      <p>
        For better understanding of ping/ze through a video: 
        <a
          href="https://www.youtube.com/watch?v=swf1re-PNPc"
          target="_blank"
          rel="noopener noreferrer"
          >
          Watch Video
          </a>
      </p>

      {/* New Quiz Section */}
      <NewQuiz />

      {/* Mastering an Additional Ping/Ze Rule Section */}
      <section className={PingZestyles.explanationSection}>
        <h3>Mastering an Additional Ping/Ze Rule</h3>
        <p>
          There is one more Ping/Ze rule to master, which is as follows: A Ping(○) word stays Ping while a Ze(●) word stays Ze even if you add an additional “s” or “d”.</p>
          <p>
          For instance, if you add a “d” to a “Ze” word like “pass,” you get “passed,” but the “d” sound is actually a /t/ sound. So even though it ends with the letter “d,” (which is normally a “ping” sound) it is really a /t/ sound (which is a ze word ending). The same is true of the letter “s.” 
          </p>
          <p>
          For instance, the Ping sound “pig” stays a ping word even if you add an “s” because in this case, the “s” stands for a /z/ sound (which is ping). 
          </p>
      </section>

      {/* Additional Quiz Section */}
      <AdditionalQuiz />

      {/* Unit Selection Quiz Section */}

      {/* Tonal Patterns in English Jueju Section */}
      <section className={PingZestyles.explanationSection}>
        <h3>Tonal Patterns in English Jueju</h3>
        <p>
          English <em>jueju</em> follows specific patterns based on whether the poem starts with a Ping or Ze tone, and whether it is a five-word or seven-word poem. The patterns are as follows:
        </p>
        <div className={PingZestyles.patternContainer}>
          {/* Five-Word Ze Start */}
          <div className={PingZestyles.patternBox}>
            <h4>Five-Word Ze Start:</h4>
            {renderPattern(patterns["five_ze_start"])}
          </div>

          {/* Five-Word Ping Start */}
          <div className={PingZestyles.patternBox}>
            <h4>Five-Word Ping Start:</h4>
            {renderPattern(patterns["five_ping_start"])}
          </div>

          {/* Seven-Word Ze Start */}
          <div className={PingZestyles.patternBox}>
            <h4>Seven-Word Ze Start:</h4>
            {renderPattern(patterns["seven_ze_start"])}
          </div>

          {/* Seven-Word Ping Start */}
          <div className={PingZestyles.patternBox}>
            <h4>Seven-Word Ping Start:</h4>
            {renderPattern(patterns["seven_ping_start"])}
          </div>
        </div>
      </section>
      <UnitSelectionQuiz />
      {/* New Drag and Drop Quiz Section */}
      <PingZeExplanation />
      <DragAndDropQuiz />
    </div>
  );
}

