import React from 'react';
import Jinshistyles from './JinshiIntro.module.css';

const JinshiIntro = () => {
  return (
    <div className={Jinshistyles.jinshiIntroContainer}>
      <h1 className={Jinshistyles.heading}>Level 5: Jinshi</h1>
      <p className={Jinshistyles.text}>
        Congratulations, you have passed the Gongshi level, and should be ready to submit your poem to be judged in the international Newman Prize for English Jueju, where you can be awarded the highest honor of becoming a jinshi!
      </p>
      <p className={Jinshistyles.text}>
        To submit your final jueju (regulated or unregulated), upload it here:
      </p>
      <button className={Jinshistyles.submitButton}>SUBMIT TO NEWMAN PRIZE JUDGING/JINSHI ASSESSMENT</button>
      <p className={Jinshistyles.text}>
        However, if you wish to continue to refine your jueju skills further and reach for your highest potential as a jueju poet, continue to listen for the subtlest resonances between the meaning and sounds of words, between natural imagery and your personal emotional and spiritual journey.
      </p>
      <p className={Jinshistyles.text}>
        You have mastered five levels of rhyming, however, there are many more subtle forms of rhyming you can still unlock in the days, weeks, and years that follow.
      </p>
      <h2 className={Jinshistyles.subheading}>Exploring the Next Level of韻</h2>
      <p className={Jinshistyles.text}>
        So, what exactly is this next level of rhyming/resonance/韻? The xx dynasty poet, Fan Wen's answer is: 韻 is "extra meaning" ("有余意之谓韻").
      </p>
      <p className={Jinshistyles.text}>
        Fan Wen believed there are two ways to achieve this highest form of韻:
      </p>
      <blockquote className={Jinshistyles.quote}>
        <p>
          "Possessing all virtues but remaining concealed, existing in simplicity and freedom, with deep and endless meaning" (备众善而自韬晦，行于简易闲淡之中，而有深远无穷之味).
        </p>
        <p>
          "Excelling in one aspect with surplus" (一长有余), meaning "the ingenious shines through the plain, the majestic manifests itself within simplicity" ("巧丽者发之于平淡，奇伟有余者行之于简易").
        </p>
      </blockquote>
      <p className={Jinshistyles.text}>
        Another poet, Wang Dingguan, used the metaphor of striking a bell, explaining:
      </p>
      <blockquote className={Jinshistyles.quote}>
        <p>
          "When striking a bell, the main sound fades, but the echo lingers, reverberating and winding, creating a sound beyond the sound, which is ‘韻’ rhyme" ("尝闻之撞钟，大声已去，余音复来，悠扬宛转，声外之音，其是之谓矣").
        </p>
      </blockquote>
      <p className={Jinshistyles.text}>
        Still other poets over the centuries combined the word “rhyme” with other terms to get compound categories such as "氣韻" (qi-energy resonance), "格韻" (patterned resonance), "韻味" (flavored resonance), and "情韻" (emotional resonance). The most significant modern manifestation of韻, however, was the redefinition and widespread use of the term "Shenyun" (神韻) or “spiritual rhyme/resonance.
      </p>
      <p className={Jinshistyles.text}>
        Perhaps the modernist Qian Zhongshu, in his *Guan Zhui Bian*, summarized various theories and provided a succinct overview of韻:
      </p>
      <blockquote className={Jinshistyles.quote}>
        <p>
          "In painting, it is necessary to leave space for the viewer's imagination; in poetry, the portrayal of feelings and events should not be too detailed. 韻 requires a sense of subtlety and lingering quality, allowing the reader to imagine what is not depicted and to grasp the unsaid emotions" (画之写景物，不尚工细，诗之道情事，不贵详尽，皆须留有余地，耐人玩味，俾由其所写之景物而冥观未写之景物，据信所道之情事而默识未道之情事。韻之谓也).
        </p>
      </blockquote>
      <p className={Jinshistyles.text}>
        This suggests that韻, in essence, is the pursuit of "言有尽而意无穷" ("words have an end, but the meaning is inexhaustible") in literary works. So as you continue to refine your English jueju, reach for the sound beyond sound, the meaning beyond words, and the ineffable and inexhaustible which emanate from the resonances that undergird our world.
      </p>
    </div>
  );
};

export default JinshiIntro;

