import React, { useState } from 'react';
import styles from './Introduction.module.css';

const Introduction = () => {
  const [expandedSections, setExpandedSections] = useState({
    section1: false,
    section2: false,
    section3: false,
    section4: false,
    coreConcept1: false,
  });

  const toggleSection = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div className={styles.introductionContainer}>
      <h2 className={styles.heading}>Introduction to the Rules of Jueju</h2>
      <p className={styles.introText}>
        Before we start with your first level exam (Tongsheng), it's important to learn a few of the basic rules of unregulated English Jueju as well as a few key concepts that lie at the heart of Jueju poetry.
      </p>

      <div className={styles.ruleSection}>
        <h3 className={styles.subHeading}>Basic Rules of Unregulated English Jueju:</h3>

        <div className={styles.expandableSection}>
          <h4 onClick={() => toggleSection('section1')} className={styles.sectionTitle}>
            📝 Monosyllabic Words {expandedSections.section1 ? '▲' : '▼'}
          </h4>
          {expandedSections.section1 && (
            <div className={styles.sectionContent}>
              <p>Only use single-syllable (monosyllabic) words in Jueju poetry.</p>
            </div>
          )}
        </div>

        <div className={styles.expandableSection}>
          <h4 onClick={() => toggleSection('section2')} className={styles.sectionTitle}>
            📏 What is the structure of jueju? {expandedSections.section2 ? '▲' : '▼'}
          </h4>
          {expandedSections.section2 && (
            <div className={styles.sectionContent}>
              <p>A Jueju is made up of four lines of either all five-word lines or seven-word lines.</p>
            </div>
          )}
        </div>

        <div className={styles.expandableSection}>
          <h4 onClick={() => toggleSection('section3')} className={styles.sectionTitle}>
            🎵 Which Rhyme scheme is followed? {expandedSections.section3 ? '▲' : '▼'}
          </h4>
          {expandedSections.section3 && (
            <div className={styles.sectionContent}>
              <p>An English Jueju has an AABA rhyme scheme (the third line does not end in a shared rhyme but the other three lines end with words that rhyme with one another).</p>
            </div>
          )}
        </div>

        <div className={styles.expandableSection}>
          <h4 onClick={() => toggleSection('section4')} className={styles.sectionTitle}>
            🌳 What is Thematic Progression in jueju {expandedSections.section4 ? '▲' : '▼'}
          </h4>
          {expandedSections.section4 && (
            <div className={styles.sectionContent}>
              <p>
                The first two couplets introduce a natural scene while the second couplet “turns” to introduce human feelings, ideas, and concepts, and then “unifies” these elements to conclude the poem.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Core Concepts Section */}
      <div className={styles.ruleSection}>
        <h3 className={styles.subHeading}>What Rhymes with what?: Core Concepts of Rhyming</h3>
        <p className={styles.detailText}>
          Rhyming lies at the heart of Jueju, but in this tradition “rhyming” often means a great deal more than it does in English. In fact, "rhyming" is the central spiritual and cosmological concept that lies at the heart of Classical Chinese philosophy known as 感应 “ganying,” or “inter-resonance.” According to this concept, all things in the cosmos “stimulate and respond” (resonate) with other things within their shared category of resonance, 同类, tonglei.
        </p>
        <p className={styles.detailText}>
        In the first level(Tongsheng), you will start with mastering the first level of rhyming-- "end-rhyming".
        </p>
        <div className={styles.expandableSection}>
          <h4 onClick={() => toggleSection('coreConcept1')} className={styles.sectionTitle}>
            End Rhyming {expandedSections.coreConcept1 ? '▲' : '▼'}
          </h4>
          {expandedSections.coreConcept1 && (
            <div className={styles.sectionContent}>
              <p>Poets must rhyme the last words in the first, second, and fourth lines (an AABA end rhyme scheme).</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Introduction;

