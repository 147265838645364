import React, { useState } from 'react';
import styles from './AboutWebsite.module.css';
import Header from './Header'; // Assuming you have a Header component

const AboutWebsite = () => {
  const [expandedSections, setExpandedSections] = useState({
    intro: false,
    learningPaths: false,
    forestPath: false,
    examPath: false,
    examProgress: false,
  });

  const toggleSection = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div>
      <Header />
      <div className={styles.aboutContainer}>
        <h2 className={styles.heading}>Welcome to the Jueju Path</h2>

        {/* General Introduction - First paragraph static, second one clickable */}
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>🏞 General Introduction</h3>
          <div className={styles.sectionContent}>
            <p>
              Welcome to the Jueju Path! This website was created by the creator of the English Jueju form and learning system, Jonathan Stalling, to provide a single-stop platform for anyone hoping to learn how to compose one of the world’s most beloved, and certainly its most challenging poetic form, unregulated and regulated jueju “近体绝句/古体绝句”  Stalling created the English Jueju system in the 1990’s at UC Berkeley and has spent two and a half decades refining it and teaching the form online and in-person workshops across the globe ever since. Over the last decade, the Newman Prize for English Jueju, based at the University of Oklahoma, has awarded dozens of $500 prizes to English jueju poets from across five continents in a wide range of age categories ranging from 6 years old to retired adults.
            </p>
            {/* Second paragraph is clickable */}
            <h4 className={styles.sectionTitle} onClick={() => toggleSection('intro')}>
              More about the website {expandedSections.intro ? '▲' : '▼'}
            </h4>
            {expandedSections.intro && (
              <div className={styles.sectionContent}>
                <p>
                  As you navigate through the Jueju Path website, you will find a wide range of features to help you learn, review, and hopefully master the art of English jueju. It will also provide you with an opportunity to submit your work to the Newman Prize for English Jueju where you will be eligible to win a variety of prizes. The Jueju path website is designed with a single starting point in mind which begins by creating an account or logging in. If you are over 18, you can create your own account to create a unique Jueju identity which will be used to track your progress and start and stop as you wish without losing your badges. If you are under 18, however, you will need to have your parents or teacher create a login with their email, but you can still create a unique identity of your own! Teachers can request as many unique log in identities for their students as they need which means all Final Newman Prize entries will be sent from a teacher’s email and any responses from Professor Jonathan Stalling or the University of Oklahoma team will be addressed to the official email provided.
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Learning Paths */}
        <div className={styles.section}>
          <h3 className={styles.sectionTitle} onClick={() => toggleSection('learningPaths')}>
            🛤 Learning Paths {expandedSections.learningPaths ? '▲' : '▼'}
          </h3>
          {expandedSections.learningPaths && (
            <div className={styles.sectionContent}>
              <p>
                Once you have logged in, you will find two distinct learning paths under the “Learn” tab in the navigation bar: 1. The Forest Path, and 2. The Examination Path.
              </p>
            </div>
          )}
        </div>

        {/* Forest Path */}
        <div className={styles.subSection}>
          <h4 className={styles.subTitle} onClick={() => toggleSection('forestPath')}>
            🌲 The Forest Path {expandedSections.forestPath ? '▲' : '▼'}
          </h4>
          {expandedSections.forestPath && (
            <div className={styles.sectionContent}>
              <p>
                The Forest Path introduces the art of Jueju writing as a self-paced ten-day writing retreat that emphasizes Buddhist and Daoist elements of the Jueju path. On this path, we take our time to create handmade books, collect our words from nature, sprout these natural words into poetry Jueju units, and learn how to organize these into cosmologically balanced, inter-resonant forms that emphasize a non-dual approach to harmonizing human intelligence and creativity with the natural world.
              </p>
              <p><strong>This path will be available in late 2025.</strong></p>
            </div>
          )}
        </div>

        {/* Examination Path */}
        <div className={styles.subSection}>
          <h4 className={styles.subTitle} onClick={() => toggleSection('examPath')}>
            📚 The Examination Path {expandedSections.examPath ? '▲' : '▼'}
          </h4>
          {expandedSections.examPath && (
            <div className={styles.sectionContent}>
              <p>
                The Examination Path, introduces the Confucian elements of the jueju form by taking poetry adepts through the five levels of the Chinese Imperial Examination System, the Tongsheng (童生), Xiucai (秀才) Juren (舉人), Gongshi (貢士), and Jinshi (進士) exams, which historically required the mastery of regulated verse including jueju (“近体绝句/格律诗”). In this “exam path,” you will earn badges as you progress through the five exams by mastering the five levels of “cosmological rhyming” which organize human languages according to the ancient concept of “inter-resonance” or “ganying” (感应). These special forms of “rhyming” in regulated jueju (体绝句) include:
              </p>
              <ul className={styles.list}>
                <li>end rhyming</li>
                <li>semantic (meaning) rhyming</li>
                <li>grammatical rhyming</li>
                <li>vowel length (ping/ze) rhyming</li>
                <li>cosmological (nature-human feeling) rhyming</li>
              </ul>
              <p>
              The four primary badges that will be awarded to those who successfully pass the interactive learning materials and a jueju poetry assessment at the end of each Exam stage will enable Jueju students and poets to enter the final level (Jinshi) which will be automatically uploaded to the Newman Prize for English Jueju and will be assessed (and judged) by Jonathan Stalling. 
              </p>
            </div>
          )}
        </div>

        {/* Examination Progress */}
        <div className={styles.section}>
          <h3 className={styles.sectionTitle} onClick={() => toggleSection('examProgress')}>
            🚀 Examination Progress {expandedSections.examProgress ? '▲' : '▼'}
          </h3>
          {expandedSections.examProgress && (
            <div className={styles.sectionContent}>
              <p>
              Assessing your progress along the Examination Path: In the English version of the website, you will be guided by an AI chatbot created by and based on the work of Jonathan Stalling, the creator of the English Jueju form and learning system. At the start of each exam, you will gain the skills needed to compose an original work of Jueju poetry which will be assessed by the Exam Path Chatbot, which will not only assess your poem’s worthiness to grant you access to the next Exam level, but will provide you with detailed feedback on your original work. While the Chatbot is not perfect, it has been fine-tuned to provide you with useful feedback to keep you on track to mastering this legendary artform. Along the way, you will also have the opportunity to learn more about the Imperial Examination System in history, and earn additional culture badges as well.  If you are learning on the Chinese version, the assessment will be provided by Jonathan Stalling or his graduate students since the AI functionality is not available in China. 
              </p>
              <br></br>
              <ul className={styles.list}>
                <li><strong>A.</strong> <strong>Your journey begins by preparing for and taking the Tongsheng (童生, "Child Student") Exam!</strong> As a Tongsheng candidate, you will move through a variety of exercises where you will learn and be tested on the skills related to the first level of rhyming “end rhyming” among other key jueju skills (using single-syllable words, building and arranging poetry units into lines and couplets) leading up to creating your own first unregulated couplet!</li>
                <li><strong>B.</strong> <strong>Once you pass the Tongsheng exam, you will prepare for and take the Xiucai or “Budding Talent” (秀才) exam</strong> where you will master the second level of rhyming “cosmological rhyming” where you will discover shared categories of resonance between the natural world and human feeling as you learn how to compose full unregulated jueju and finally compose a full jueju of your own for assessment.</li>
                <li><strong>C.</strong> <strong>If you pass the Xiucai assessment, you will prepare and sit the Juren (舉人) exam</strong> where you will learn the next three levels of rhyming (semantic, grammatical, and ping/ze) leading to the creation and assessment of your first regulated couplet!</li>
                <li><strong>D.</strong> <strong>If you pass the Juren Exam, you will be ready to prepare for and sit the Gongshi (貢士) exam </strong> where you will learn to put everything you learned together and compose your first original regulated jueju!</li>
                <li><strong>E.</strong> <strong>If your Regulated Jueju passes the assessment, you will be ready to sit the Jinshi (進士) exams, </strong>where you will move beyond the basic jueju writing skills to explore the deeper possibilities of the form to be beautiful, powerful, and moving poetry. However, to pass this level of the exam path, you will have to submit your poems to be assessed by Jonathan Stalling and other Jinshi who have passed this level of the exam. Your submitted work will be included in the biannual International Newman Prize for English Jueju where you can win $500 prizes and the title of Newman Jinshi!</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutWebsite;

