import React, { useState } from 'react';
import AboutStyles from './AboutPage.module.css';
import Header from './Header';

const AboutJueju = () => {
  const [expandedSections, setExpandedSections] = useState({
    briefIntro: false,
    history: false,
    Morehistory: false,
  });

  const toggleSection = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div>
      <Header />
      <div className={AboutStyles.container}>
        <section className={AboutStyles.section}>
          <div className={AboutStyles.text}>
            <h2 className={AboutStyles.heading}>Welcome to the Jueju Path!</h2>

            <p className={AboutStyles.introParagraph}>
            Jueju can be literally translated as "cut-verse," (but I prefer “condensed verse) and is the most popular form of classical Chinese poetry characterized by its brevity and, in its regulated form, its famously strict poetic constraints. Emerging during the Tang Dynasty, Jueju poetry consists of a quatrain, which is to say a poem of four lines of either five or seven single syllable words each. The form that uses five-word lines is called the "five-single-syllable word Jueju" (五言绝句) and its slightly longer cousin is called a "seven-(single-syllable word Jueju" (七言绝句) respectively.  
            </p>

            <div className={AboutStyles.featureBox}>
              <h3 className={AboutStyles.subheading}>Key Features of the website:</h3>
              <ul className={AboutStyles.featuresList}>
                <li>Create your own unique Jueju identity</li>
                <li>Track your progress through two learning paths: The Forest Path and The Examination Path</li>
                <li>Earn badges, submit poems for prizes, and more!</li>
              </ul>
            </div>

            {/* Collapsible sections for in-depth details */}
            <div className={AboutStyles.expandableSection}>
              <h3 className={AboutStyles.sectionTitle} onClick={() => toggleSection('briefIntro')}>
                📜 More about Jueju {expandedSections.briefIntro ? '▲' : '▼'}
              </h3>
              {expandedSections.briefIntro && (
                <div className={AboutStyles.sectionContent}>
                  <p className={AboutStyles.paragraph}>
                    In addition to the two different line lengths, there are two basic forms of Jueju, one is known as the “old style” 古体绝句or “unregulated jueju” and the other is known as the “new style” or 近体觉句 (regulated jueju). Unregulated jueju follows a smaller number of constraints including how many single-syllable words per line, an AABA end-rhyme scheme, and a four-part thematic progression, where each line performs a relatively set thematic function to create a balance between the first half which introduces natural imagery and the second half which blends these with human feelings, ideas and concepts. Regulated jueju, on the other hand, follows all of those rules but must also follow a strict regulated vowel pattern which oscillating between full and clipped vowel lengths (“ping-ze” discussed at a later stage). It also must employ a set form of parallel grammar and parallel (or antithetical) meanings. The themes of both unregulated and regulated Jueju poems are diverse, encompassing nature, personal reflection, and social commentary. The form's concise nature demands precision and evocative imagery, often leaving much to the reader's interpretation.
                  </p>
                  <p className={AboutStyles.paragraph}>
                    Prominent Tang Dynasty poets like Li Bai (李白) and Wang Wei (王维) excelled in this form, crafting masterpieces that have endured through the centuries and remain popular today in Chinese and translation around the world.
                  </p>
                </div>
              )}
            </div>

            <div className={AboutStyles.expandableSection}>
              <h3 className={AboutStyles.sectionTitle} onClick={() => toggleSection('history')}>
                🏛 History of Jueju {expandedSections.history ? '▲' : '▼'}
              </h3>
              {expandedSections.history && (
                <div className={AboutStyles.sectionContent}>
                  <p className={AboutStyles.paragraph}>
                    The History of Jueju is still somewhat mysterious even today, however. One can argue that classical Chinese poetry has long composed works of four lines and that jueju (which as I mentioned are four lines long) can be seen in precursors from four-line folk songs and poems, prevalent during the Han Dynasty (206 BC – 220 AD), and that the structure and style of these folk songs may have been refined and formalized into the Jueju form over time by incorporating more sophisticated literary techniques within the enduring fundamental four-line format.
                  </p>
                  <p className={AboutStyles.paragraph}>
                    It is also possible to say that jueju bare more than a little resemblance to poetry in the Yuefu (乐府) tradition. Yuefu refer to lyrical poetry collected by the Music Bureau during the Han Dynasty and these poems, which often dealt with themes of daily life became more condensed over time and include impactful quatrains. The most popular explanation likely remains that Jueju emerged as a condensed form of Lüshi (律诗), or regulated verse, which became prominent during the Tang Dynasty. Lüshi typically consists of eight lines with strict tonal patterns and parallelism. Poets seeking greater brevity and impact began can be seen distilling the Lüshi form into shorter forms while maintaining the tonal and structural thematic progression. In creating the English Jueju system, Jonathan Stalling solidified the format of English jueju based on this idea by adopting rules for regulated jueju that follow the same rules as those for Lüshi (just 50% shorter). By the early Tang Dynasty Jueju as a form was on the rise and took on a life of its own as the most beloved form of regulated verse culture which was itself adopted into the new Imperial Examination and Examination and was embraced by lay and formal practitioners of Buddhism and Daoism as well. Not only did these worldviews embrace of simplicity, naturalness, and the expression of profound truths through minimal means often rooted in natural imagery become common themes of jueju poetry and poetics, but the form of jueju itself became deeply imbedded within the formal monastic life and social practices leading to a strong overlap between the form’s ability to convey deep spiritual and philosophical meanings associated with Buddhism and Daoism with the moral and virtuous pursuit of self-cultivation and statecraft championed by the Confucian worldview and enshrined in the Imperial Examination system.
                  </p>
                </div>
              )}
            </div>
            <div className={AboutStyles.expandableSection}>
              <h3 className={AboutStyles.sectionTitle} onClick={() => toggleSection('Morehistory')}>
                🏛 More History {expandedSections.Morehistory ? '▲' : '▼'}
              </h3>
              {expandedSections.Morehistory && (
                <div className={AboutStyles.sectionContent}>
                  <p className={AboutStyles.paragraph}>
                  So the “three-teachings” (Daoism, Confucianism, and Buddhism) can be found in the works of poets like Li Bai (李白), Du Fu (杜甫), and Wang Wei (王维), but all jueju masters utilize the brevity and precision of the jueju form to capture fleeting moments, evoke powerful emotions, and reflect on nature and human experience all within a dizzying array of formal constraints. The form's strict structure and brevity required all jueju poets to distill their thoughts and emotions into just four lines of five or seven single syllables each, balanced both vertically and horizontally to make every word and vowel significant to the final outcome. 
                  </p>
                  </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutJueju;

