// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const hasMadeSelection = sessionStorage.getItem('hasMadeSelection');

  // If the user has not made a selection, redirect to the home page
  if (!hasMadeSelection) {
    return <Navigate to="/" replace />;
  }

  // If the user has made a selection, render the children components
  return children;
};

export default ProtectedRoute;


